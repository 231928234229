import { Box, Button, Modal } from "@mui/material";
import { DELETE_DATA } from "./Constant";

function CustomModal({ open, onClose, children, disableClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: "300px",
          height: "200px",
          padding: "16px",
          maxWidth: "80vw",
          maxHeight: "80vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <p className="text-lg"> {children} </p>
          <div className="mt-8 ml-20">
            {disableClose === true ? "" : <Button onClick={onClose}> Close </Button>}
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default CustomModal;
