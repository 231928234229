import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  FEMALE,
  JSON_NAME,
  MALE,
  MR,
  MRS,
  MS,
  TOKEN_NAME,
} from "../../../components/clinic/Constant";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import globalVariables from "../../../globalVariables";
import { Dropdown } from "../../../components";
import { CLINIC_LOGO } from "../../../components/clinic/ImageSource";
import { useMediaQuery } from "react-responsive";

function Certificates() {
  const [prefix, setPrefix] = useState("empty");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("empty");
  const [date, setDate] = useState(null);
  const [result, setResult] = useState("");
  const [treatment, setTreatment] = useState("");
  const [recomendation, setRecomendation] = useState("");
  const [dentist, setDentist] = useState("empty");
  const [prc, setPrc] = useState("");
  const [dentistInfo, setDentistInfo] = useState([]);
  const [error, setError] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [newPatient, setNewPatient] = useState(false);
  const [id, setId] = useState(0)
  const recievedToken = localStorage.getItem(TOKEN_NAME);

  const handlePrefixChange = (event) => {
    setPrefix(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event);
  };

  const handleResultChange = (event) => {
    setResult(event.target.value);
  };

  const handleTreatmentChange = (event) => {
    setTreatment(event.target.value);
  };

  const handleRecomendationChange = (event) => {
    setRecomendation(event.target.value);
  };

  function medicalInformation() {
    const storedData = localStorage.getItem("currentUser");
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=getdentistprc&id=${storedData}`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setDentistInfo(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  function patientInformation() {
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&method=documents`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPatientData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  const handleCheck = () => {
    setNewPatient(!newPatient)
    setName("");
    setAge("");
    setGender("empty");
    setId(0);
    setPrefix("empty");
  }

  useEffect(() => {
    medicalInformation();
    patientInformation();
  }, []);

  const generatePDF = () => {
    if (name === "" || treatment === "" || dentist === "" || prc === "") {
      setError(true);
    } else {
      const documentFormat1 = `To whom it may concern:`;
      const documentFormat2 = `\n\nThis is to inform you that ${prefix} ${name}, ${age} year/s old, ${gender}, has undergone a through oral examination at Bright Smiles Oral Care Center on ${dayjs(
        date
      ).format("MM/DD/YYYY")}`;
      const documentFormat3 = `\n\n\n\n\n\nResults:${result}\n\nTreatment:${treatment}\n\nRecommendation:${recomendation}\n\n\nThis certificate is issued upon the request of the patient for whatever legal purpose it may serve him or her.\n\nThank you very much.`;
      const documentFormat4 = `Dentist: ${dentist}\nPRC Number: ${prc}`;
      const doc = new jsPDF();
      const img = new Image();
      img.src = CLINIC_LOGO;
      doc.addImage(img, "png", 10, 0, 90, 40);

      doc.text(documentFormat1, 10, 50);
      doc.text(documentFormat2, 10, 50, { maxWidth: 185, align: "justify" });
      doc.text(documentFormat3, 10, 50, { maxWidth: 185, align: "left" });

      const text1Height = (doc.getStringUnitWidth(documentFormat3) * 12) / 185;
      const y2 = 87 + text1Height * 12;

      setError(false);
      doc.text(documentFormat4, 185, y2, { maxWidth: 0, align: "right" });

      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();
      doc.save(`${year}_${month}_${day}_${name}_Certificate.pdf"`);
    }
  };

  return (
    <Box style={{ position: "absolute", zIndex: "2", width: "90%" }}>
      <div className="mb-5">
        New Patient?{" "}
        <Checkbox onChange={(event) => handleCheck()} />
      </div>
      <div> To whom it may concern: </div>
      <div>
        This is to certify that{" "}
        {newPatient ? (
          <Select
            id="dropdown-prefix"
            value={prefix}
            label={"Prefix"}
            onChange={handlePrefixChange}
          >
            <MenuItem value={"empty"}>Affix..</MenuItem>
            <MenuItem value={MR}>Mr.</MenuItem>
            <MenuItem value={MS}>Ms.</MenuItem>
            <MenuItem value={MRS}>Mrs.</MenuItem>
          </Select>
        ) : (
          <div> </div>
        )}{" "}
        {newPatient ? (
          <TextField
            error={error}
            value={name}
            onChange={handleNameChange}
            label={"Name*"}
          />
        ) : (
          <div> </div>
        )}{" "}
        {newPatient ? (
          <TextField
            value={age}
            onChange={handleAgeChange}
            className="w-14"
            label="Age"
          />
        ) : (
          ""
        )}{" "}
        {newPatient ? ", year/s old," : ""}
        {newPatient ? (
          <Select
            id="dropdown-gender"
            value={gender}
            onChange={handleGenderChange}
          >
            <MenuItem value={"empty"}>Gender..</MenuItem>
            <MenuItem value={MALE}>Male</MenuItem>
            <MenuItem value={FEMALE}>Female</MenuItem>
          </Select>
        ) : (
          ""
        )}
        {newPatient ? (
          ""
        ) : (
          <Select
            value={`${name}+${age}+${gender}+${id}+${prefix}`}
            label={"Prefix"}
            defaultValue="empty"
            style={{ width: "50%" }}
            onChange={(event) => {
              const [selectedName, selectedAge, selectedGender, selectId, selectPrefix] =
                event.target.value.split("+");
              setName(selectedName);
              setAge(selectedAge);
              setGender(selectedGender);
              setId(selectId);
              setPrefix(selectPrefix);
            }}
          >
            <MenuItem value={"++empty+0+empty"}>Select Patient</MenuItem>
            {patientData.map((dentist) => {
              const fullName = `${dentist.namefirst} ${dentist.namemiddle} ${dentist.namelast}`;
              const birthYear = new Date(dentist.dateofbirth).getFullYear();
              const currentYear = new Date().getFullYear();
              const age = currentYear - birthYear;
              const prefix = dentist.patientgender === "Male" ? MR : dentist.patientgender === "Female" && dentist.civilstatus === "Married" ? MRS : MS
              return (
                <MenuItem
                  key={`${fullName}+${age}+${dentist.gender}+${dentist.id}+${prefix}`}
                  value={`${fullName}+${age}+${dentist.patientgender}+${dentist.id}+${prefix}`}
                >
                  {fullName}
                </MenuItem>
              );
            })}
          </Select>
        )}
        , has undergone a through oral examination at Bright Smiles Oral Care
        Center on{" "}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker value={date} onChange={handleDateChange} label="Date" disablePast={true}/>
        </LocalizationProvider>
      </div>
      <div className="mt-5">
        <TextField
          id="text-results"
          label="Results/Findings:"
          multiline
          rows={10}
          style={{ width: "100%" }}
          value={result}
          onChange={handleResultChange}
        />
      </div>
      <div className="mt-5">
        <TextField
          id="text-treatment"
          label="Treatments Done: *"
          multiline
          rows={10}
          style={{ width: "100%" }}
          value={treatment}
          error={error}
          onChange={handleTreatmentChange}
        />
      </div>
      <div className="mt-5">
        <TextField
          id="text-recomendation"
          label="Recommendation:"
          multiline
          style={{ width: "100%" }}
          rows={10}
          value={recomendation}
          onChange={handleRecomendationChange}
        />
        <p className="mt-5">
          {" "}
          This certificate is issued upon the request of the patient for
          whatever legal purpose it may serve him or her.{" "}
        </p>
        <p>Thank you very much.</p>
      </div>

      <div className="flex flex-col items-end mt-5 space-y-5">
        <Select
          value={`${dentist}+${prc}`}
          label={"Prefix"}
          defaultValue="empty"
          style={{ width: "50%" }}
          onChange={(event) => {
            const [selectedDentist, selectedPrc] =
              event.target.value.split("+");
            setDentist(selectedDentist);
            setPrc(selectedPrc);
          }}
        >
          <MenuItem value={"empty+"}>Select Doctor</MenuItem>
          {dentistInfo.map((name) => (
            <MenuItem
              key={`${name.name}+${name.numberprc}`}
              value={`${name.name}+${name.numberprc}`}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={generatePDF}>Export PDF</Button>
      </div>
    </Box>
  );
}

export default Certificates;
