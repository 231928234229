import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { CLINIC_LOGO, RX_LOGO } from "../../../components/clinic/ImageSource";
import { FEMALE, MALE, TOKEN_NAME } from "../../../components/clinic/Constant";
import globalVariables from "../../../globalVariables";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

function RTXPage() {
  const [name, setName] = useState("");
  const [patientAddress, setPatientAddress] = useState("");
  const [clinicAddress, setClinicAddress] = useState();
  const [clinicHours, setClinicHours] = useState("8:00AM - 6:00PM");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [prescription, setPrescription] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("empty");
  const [date, setDate] = useState(null);
  const [birthDate, setbirthDate] = useState(null);
  const [dentist, setDentist] = useState("empty");
  const [prc, setPrc] = useState("");
  const [ptr, setPtr] = useState("");
  const [error, setError] = useState(false);
  const [dentistInfo, setDentistInfo] = useState([]);
  const recievedToken = localStorage.getItem(TOKEN_NAME);
  const [loading, setLoading] = useState(true);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const [patientData, setPatientData] = useState([]);
  const [newPatient, setNewPatient] = useState(false);

  const quadBoxStyle = classNames(
    isPhone
      ? "grid grid-cols-2 gap-4"
      : "flex justify-between grid-cols-2 gap-4 w-[800px]"
  );

  function getClinicInformation() {
    const storedData = localStorage.getItem("currentUser");
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=clinicinformation&id=${storedData}&method=forms`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setClinicAddress(data[0].clinicaddress);
          setEmailAddress(data[0].clinicemail);
          setMobileNumber(data[0].clinicnumbermobile);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  function patientInformation() {
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&method=documents`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPatientData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  useEffect(() => {
    getClinicInformation();
  }, []);

  const handleMobileNumber = (event) => {
    setMobileNumber(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleEmailAddress = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleTelephoneNumber = (event) => {
    setTelephoneNumber(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleClinicHours = (event) => {
    setClinicHours(event.target.value);
  };

  const handleClinicAddress = (event) => {
    setClinicAddress(event.target.value);
  };

  const handlePatientAddress = (event) => {
    setPatientAddress(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePrescription = (event) => {
    setPrescription(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event);
  };

  const handleBirthDateChange = (event) => {
    setbirthDate(event);
  };
  const handleDentistChange = (event) => {
    setDentist(event.target.value);
  };
  const handlePtrChange = (event) => {
    setPtr(event.target.value.replace(/[^0-9]/g, ""));
  };
  const handlePrcChange = (event) => {
    setPrc(event.target.value.replace(/[^0-9]/g, ""));
  };
  const generatePDF = () => {
    if (
      name === "" ||
      dentist === "" ||
      prc === "" ||
      date == null ||
      birthDate == null ||
      patientAddress === ""
    ) {
      setError(true);
      setDate({});
      setbirthDate({});
    } else {
      const printClinicAddress = `Address: ${clinicAddress}`;
      const printEmailAddress = `Email: ${emailAddress}`;
      const printMobileNumber = `Mobile: ${mobileNumber}`;
      const printPatientName = `Name: ${name} `;
      const printGender = `Gender: ${gender}`;
      const printPrescription = `${prescription}`;
      const printAge = `Age: ${age}`;
      const printPatientAddress = `Address: ${patientAddress}`;
      const printDate = `Date: ${dayjs(date).format("MM/DD/YYYY")}`;
      const printDisclaimer = `Take Medication as prescribed only. Should there be any adverse or allergic reaction, proceed to the hospital immediately and inform the doctor with the medication you are taking. This is to facilitate proper medical treatment and to prevent any life-threatening situations.`;
      const printDentist = `Dentist: ${dentist}`;
      const printPrc = `PRC Number: ${prc}`;
      const printPtr = `PTR Number: ${ptr}`;
      const printBirthday = `Birthdate: ${dayjs(birthDate).format(
        "MM/DD/YYYY"
      )}`;

      const doc = new jsPDF();
      const img = new Image();
      img.src = CLINIC_LOGO;
      doc.addImage(img, "png", 10, 0, 90, 40);
      img.src = RX_LOGO;
      doc.text(printMobileNumber, 105, 10);
      doc.text(printEmailAddress, 105, 20);
      doc.text(printClinicAddress, 105, 30);
      doc.setLineWidth(0.5);
      doc.line(0, 50, 250, 50);
      doc.text(printPatientName, 10, 60, { maxWidth: 185 });
      const textWidth = doc.getTextWidth(printPatientName);
      doc.line(10, 61, 10 + textWidth, 61);

      const text1Height = (doc.getStringUnitWidth(printPatientName) * 12) / 185;
      const y2 = 64 + text1Height * 6;

      doc.text(printPatientAddress, 10, y2, {
        maxWidth: 185,
        align: "justify",
      });

      const textWidth2 = doc.getTextWidth(printPatientAddress);
      doc.line(10, y2 + 1, 10 + textWidth2, y2 + 1);

      doc.text(printGender, 10, y2 + 7);
      doc.text(printAge, 66, y2 + 7, { maxWidth: 80, align: "center" });
      doc.text(printBirthday, 110, y2 + 7, { maxWidth: 80, align: "center" });
      doc.text(printDate, 195, y2 + 7, { align: "right", display: "flex" });

      doc.addImage(img, "png", 10, y2 + 30, 24, 30);
      doc.text(printPrescription, 40, y2 + 30, { maxWidth: 185 });

      doc.text(printDisclaimer, 10, 200, { maxWidth: 185, align: "justify" });
      doc.text(printDentist, 185, 240, { align: "right", display: "flex" });
      doc.text(printPrc, 185, 250, { align: "right" });

      setError(false);

      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();

      doc.save(`${year}_${month}_${day}_${name}_rx.pdf`);
    }
  };

  const handleCheck = () => {
    setNewPatient(!newPatient);
    setName("");
    setAge("");
    setGender("empty");
    setPatientAddress("");
    setbirthDate(null);
  };

  function medicalInformation() {
    const storedData = localStorage.getItem("currentUser");
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=getdentistprc&id=${storedData}`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setDentistInfo(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  useEffect(() => {
    medicalInformation();
    patientInformation();
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <Box>
      <div className="mb-5">
        New Patient?
        <Checkbox onChange={(event) => handleCheck()} />
      </div>

      <div>
        <TextField
          error={error}
          value={clinicAddress}
          className={"w-full"}
          onChange={handleClinicAddress}
          label={"Clinic Address*"}
        />
      </div>

      <div className="grid grid-cols-2 gap-4 place-content-center h-24">
        <TextField
          value={emailAddress}
          onChange={handleEmailAddress}
          label={"Email Address"}
        />
        <TextField
          value={mobileNumber}
          onChange={handleMobileNumber}
          label={"Mobile Number"}
        />
      </div>

      {newPatient ? (
        <div className="mt-10">
          <TextField
            error={error}
            value={name}
            onChange={handleNameChange}
            label={"Patient's Name*"}
            className={"w-full"}
          />
        </div>
      ) : (
        ""
      )}

      {newPatient ? (
        <div className="mt-5 mb-10">
          <TextField
            error={error}
            value={patientAddress}
            onChange={handlePatientAddress}
            label={"Patient Address*"}
            className={"w-full"}
          />
        </div>
      ) : (
        ""
      )}

      {newPatient ? (
        <div className={quadBoxStyle}>
          <TextField value={age} onChange={handleAgeChange} label={"Age"} />

          <Select
            id="dropdown-gender"
            value={gender}
            label="Male/Female"
            onChange={handleGenderChange}
          >
            <MenuItem value={"empty"}>Gender</MenuItem>
            <MenuItem value={MALE}>Male</MenuItem>
            <MenuItem value={FEMALE}>Female</MenuItem>
          </Select>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={handleDateChange}
              label={"Date*"}
              disablePast={true}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={birthDate}
              onChange={handleBirthDateChange}
              label={"Birth Date*"}
              disableFuture={true}
            />
          </LocalizationProvider>
        </div>
      ) : (
        ""
      )}

      {newPatient ? (
        ""
      ) : (
        <div>
          <div className={"w-[490px] mb-5"}>
            <Select
              value={`${name}+${age}+${gender}+${birthDate}+${patientAddress}`}
              label={"Prefix"}
              defaultValue="empty"
              style={{ width: "50%" }}
              onChange={(event) => {
                const [
                  selectedName,
                  selectedAge,
                  selectedGender,
                  selectedDateOfBirth,
                  selectPatientAddress,
                ] = event.target.value.split("+");
                setName(selectedName);
                setAge(selectedAge);
                setGender(selectedGender);
                setPatientAddress(selectPatientAddress);
                setbirthDate(selectedDateOfBirth);
              }}
            >
              <MenuItem value={"++empty+null+"}>Select Patient</MenuItem>
              {patientData.map((dentist) => {
                const fullName = `${dentist.namefirst} ${dentist.namemiddle} ${dentist.namelast}`;
                const birthYear = new Date(dentist.dateofbirth).getFullYear();
                const currentYear = new Date().getFullYear();
                const age = currentYear - birthYear;
                return (
                  <MenuItem
                    key={`${fullName}+${age}+${dentist.patientgender}+${dentist.dateofbirth}+${dentist.patientaddress}`}
                    value={`${fullName}+${age}+${dentist.patientgender}+${dentist.dateofbirth}+${dentist.patientaddress}`}
                  >
                    {fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={handleDateChange}
              label={"Date*"}
            />
          </LocalizationProvider>
        </div>
      )}

      <TextField
        id="text-results"
        label="Results/Findings:"
        multiline
        rows={10}
        style={{ width: "100%", marginTop: "20px" }}
        value={prescription}
        onChange={handlePrescription}
      />

      <p className="mt-5">
        Take Medication as prescribed only. Should there be any adverse or
        allergic reaction, proceed to the hospital immediately and inform the
        doctor with the medication you are taking. This is to facilitate proper
        medical treatment and to prevent any life-threatening situations.
      </p>

      <div className="mt-10 mb-5 flex justify-between w-[700px]">
        <Select
          value={`${dentist}+${prc}`}
          label={"Prefix"}
          defaultValue="empty"
          style={{ width: "50%" }}
          onChange={(event) => {
            const [selectedDentist, selectedPrc] =
              event.target.value.split("+");
            setDentist(selectedDentist);
            setPrc(selectedPrc);
          }}
        >
          <MenuItem value={"empty+"}>Select Doctor</MenuItem>
          {dentistInfo.map((name) => (
            <MenuItem
              key={`${name.name}+${name.numberprc}`}
              value={`${name.name}+${name.numberprc}`}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div>
        <Button onClick={generatePDF}>Export PDF</Button>
      </div>
    </Box>
  );
}

export default RTXPage;
