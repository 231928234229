import React, { useEffect, useState, useCallback } from "react";
import './SideNavigation.css'

import { LuLayoutGrid, LuWalletCards, LuLogOut, LuInbox, LuUser } from "react-icons/lu";
import { NavLink } from "react-router-dom";

import GlobalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";

const SideNavigation = ({ isActive, isLoggedOut, getActiveState, setHasNewInbox }) => {
    const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

    const [enableNotificationRedCircle, setEnableNotificationRedCircle] = useState(false);
    const [sideNavClassName, setSideNavClassName] = useState(() => {
        return `sidenavigation ${isActive ? "" : "hidden-nav"}`;
    });

    const [patientInformation, setPatientInformation] = useState({});

    const getPatientInformation = useCallback(async () => {
        // get user
        let patient;
        try {
            patient = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
        patient = await patient.json();
        const patientID = patient.user;

        // get patient information
        let patientInformation;
        try {
            patientInformation = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=information&id=${patientID}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        patientInformation = await patientInformation.json();
        patientInformation = patientInformation[0];
        setPatientInformation(patientInformation);
    }, []);

    const checkNotificationStatus = useCallback(async () => {
        // get patient id
        let patientid;
        try {
            patientid = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            )
        } catch (error) {
            console.log("...");
        }
        patientid = await patientid.json();
        patientid = patientid.user;

        // get notifications
        let requestNotification;
        try {
            requestNotification = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=notification&patientid=${patientid}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        requestNotification = await requestNotification.json();

        // check if any notification is unread
        let notifications;
        notifications = requestNotification && Array.isArray(requestNotification) && requestNotification.map((notification) => {
            if(notification.status === "unread") return true;
            else return false;
        });

        // set enable notification red circle
        let i = 0;
        for(i; i < notifications.length; i++) {
            if(notifications[i]) {
                setEnableNotificationRedCircle(true);
                setHasNewInbox(true);
                break;
            }
            setEnableNotificationRedCircle(false);
            setHasNewInbox(false);
        }

    }, []);

    const onClickTabHandler = () => {
        if(isPhone) {
            getActiveState(false);
            setSideNavClassName("sidenavigation hidden-nav");
        }
    }
    
    useEffect(() => {
        getPatientInformation();
        sessionStorage.setItem("sideNavIsActive", isActive);
        setSideNavClassName(`sidenavigation ${isActive ? "" : "hidden-nav"}`);
        const interval = setInterval(() => {
            checkNotificationStatus();
        }, 10000);
        return () => {
            clearInterval(interval);
        }
    }, [checkNotificationStatus, isActive]);

    return (
        <div className={sideNavClassName}>
            <div className="name">
                {isPhone ? `Hello, ${patientInformation.patientgender === "Male" ? "Mr." : "Ms."} ${patientInformation.namelast}` : ""}
            </div>
            <NavLink to="dashboard" style={{textDecoration: 'none', color: '#000000'}} onClick={onClickTabHandler}>
                <div className="navigation-selection" tabIndex={-1}>
                    <LuLayoutGrid size={20}/>
                    <div className="navigation-selection-name">Dashboard</div>
                </div>
            </NavLink>
            <NavLink to="billing" style={{textDecoration: 'none', color: '#000000'}} onClick={onClickTabHandler}>
                <div className="navigation-selection" tabIndex={-1}>
                    <LuWalletCards size={20}/>
                    <div className="navigation-selection-name">Billing</div>
                </div>
            </NavLink>
            <NavLink to="inbox" style={{textDecoration: 'none', color: '#000000'}} onClick={onClickTabHandler}>
                <div className="navigation-selection" tabIndex={-1}>
                    <LuInbox size={20}/>
                    <div className="navigation-selection-name">Inbox</div>
                    {enableNotificationRedCircle ? 
                        <div className="navigation-notification-red-circle"/>
                        : ''
                    }
                </div>
            </NavLink>
            <NavLink to="account" style={{textDecoration: 'none', color: '#000000'}} onClick={onClickTabHandler}>
                <div className="navigation-selection" tabIndex={-1}>
                    <LuUser size={20}/>
                    <div className="navigation-selection-name">Account</div>
                </div>
            </NavLink>
            <div className="navigation-selection" tabIndex={-1} onClick={() => {isLoggedOut(true); localStorage.removeItem("token"); localStorage.removeItem("userType");}}>
                <LuLogOut size={20}/>
                <div className="navigation-selection-name">Logout</div>
            </div>
        </div>
    );
};

export default SideNavigation;