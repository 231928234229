import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import globalVariables from "../../../../../globalVariables";

const MonthView = ({ monthViewDate, toDate, calendarView }) => {
    const navigate = useNavigate();

    const [rows, setRows] = useState(6);

    const currentDate = useMemo(() => {
        let date = dayjs(monthViewDate);
        date = dayjs(date).month(date.month()).date(1);
        if(date.day() > 4 && date.month() !== 1) setRows(7); else setRows(6);
        date = date.date(date.date() - parseInt(date.day()));
        return date;
    }, [monthViewDate]);

    const [chairs, setChairs] = useState(3);

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const [daysToShow, setDaysToShow] = useState([]);

    const getDaysToShow = () => {
        let index;
        const tempDaysToShow = [];
        for (index = 0; index < 42; index++) {
            const tempDate = dayjs(currentDate).date(currentDate.date() + index);
            tempDaysToShow.push(tempDate);
        }
        return setDaysToShow(tempDaysToShow);
    }

    const [appointmentList, setAppointmentList] = useState([]);

    const getAppointmentList = useCallback(async () => {
        let appointments;
        // get all appointment
        try {
            appointments = await fetch(
                `${globalVariables.WEB_ADDRESS_PORT}/?type=appointment`, {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": true,
                        token: localStorage.token,
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        appointments = await appointments.json();

        appointments = appointments.map(async (appointment) => {
            const appointmentDate = dayjs(appointment.timestart);
            if (dayjs(currentDate).date(currentDate.date() - 1).toDate() <= appointmentDate.toDate() && appointmentDate.toDate() <= dayjs(currentDate).date(currentDate.date() + ((7 * (rows - 1)) - 1)).toDate()) {
                // get patient
                let patient;
                try {
                    patient = await fetch(
                        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&id=${appointment.patientid}`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": true,
                            token: localStorage.token,
                            'Content-Type': 'application/json'
                        }
                    }
                    );
                } catch (error) {
                    console.log("...");
                }
                patient = await patient.json();
                patient = patient[0];

                // get dentist
                let dentist;
                try {
                    dentist = await fetch(
                        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${appointment.dentistid}`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": true,
                            token: localStorage.token,
                            'Content-Type': 'application/json'
                        }
                    }
                    );
                } catch (error) {
                    console.log("...");
                }
                dentist = await dentist.json();
                dentist = dentist[0];

                // convert starting time
                let timestart = new Date(appointment.timestart);
                // convert starting time to row start
                let rowStart = timestart.getHours() - 7;
                rowStart = (rowStart * 2) - 1;
                rowStart = rowStart + (timestart.getMinutes() / 30);
                rowStart = rowStart + 1;

                // convert ending time
                let timeend = new Date(appointment.timeend);
                // convert ending time to row end
                let rowEnd = timeend.getHours() - 7;
                rowEnd = (rowEnd * 2) - 1;
                rowEnd = rowEnd + (timeend.getMinutes() / 30);
                rowEnd = rowEnd + 1;

                return {
                    id: appointment.id,
                    patient: patient,
                    dentist: dentist,
                    procedure: appointment.title,
                    color: appointment.color,
                    chair: appointment.chair,
                    status: appointment.status,

                    rowStart: rowStart,
                    rowEnd: rowEnd,
                    timestart: dayjs(timestart),
                    timeend: dayjs(timeend)
                }
            }
        })
        appointments = await Promise.all(appointments);
        appointments = appointments.filter(appointment => appointment !== undefined);

        setAppointmentList(appointments);

    }, [currentDate, rows]);

    useEffect(() => {
        calendarView('month');
        getDaysToShow();
        getAppointmentList();
    }, [monthViewDate])

    return (
        <div className="grid grid-rows-[7] grid-cols-[7] max-h-[74vh] overflow-y-auto pr-[10px]">
            {[...Array(rows)].map((_, i) => (
                <Fragment>
                    {days.map((day, j) => (
                        <div
                            key={j}
                            className="grid text-center h-[50px] hover:bg-gray-100 active:bg-gray-50 z-0"
                            style={{
                                gridColumnStart: j + 1,
                                height: i !== 0 ? `124.5px` : `50px`,
                                borderBottomWidth: i !== 20 ? `2px` : `0px`,
                                borderLeftWidth: i !== 20 ? `2px` : `2px`,
                                borderRightWidth: j !== 6 ? `0px` : `2px`,
                                pointerEvents: i === 0 ? `none` : ``
                            }}
                        >
                            {i === 0 ?
                                <Fragment>
                                    <div className="text-[15px] font-bold content-center">
                                        {day}
                                    </div>
                                </Fragment> :
                                <Fragment>
                                    <div 
                                        className="grid"
                                        style={{
                                            gridTemplateRows: `23% 77%`
                                        }}
                                        onClick={() => {
                                            toDate(dayjs(daysToShow[j + (i-1)*7]));
                                            return navigate('/clinic/home/day-view');
                                        }}
                                    >
                                        <div 
                                            className="row-start-1 p-[3px] text-left border-b-[1px]"
                                            style={{
                                                color:  dayjs(daysToShow[j + (i-1)*7]).month() !== dayjs(monthViewDate).month() ? `#d9d9d9` : ``,
                                                backgroundColor: dayjs(daysToShow[j + (i-1)*7]).format('MMM D, YYYY') === dayjs().format('MMM D, YYYY') ? `#ac7de8` : ``
                                            }}
                                        >
                                            {dayjs(daysToShow[j + (i-1)*7]).date()}
                                        </div>
                                        <div className="row-start-2 p-[3px]">
                                            <div 
                                                className="relative grid"
                                                style={{
                                                    gridTemplateRows: `repeat(20, 4.4px)`,
                                                    gridTemplateColumns: `repeat(${chairs}, minmax(0, 1fr))`
                                                }}
                                            >
                                                {appointmentList.map((appointment, k) => (
                                                    dayjs(appointment.timestart).format('MMM D, YYYY') === dayjs(daysToShow[j + (i - 1) * 7]).format('MMM D, YYYY') ?
                                                        <div
                                                            key={k}
                                                            className={`absolute flex flex-row rounded-md p-[5px] z-10 translate-x-[2px]`}
                                                            style={{
                                                                height: `100%`,
                                                                width: `calc(100% - 3px)`,
                                                                backgroundColor: appointment.color,
                                                                filter: appointment.status === "requesting cancellation" ? "brightness(30%)" : "",
                                                                gridColumnStart: `${appointment.chair}`,
                                                                gridColumnEnd: `${appointment.chair + 1}`,
                                                                gridRowStart: `${appointment.rowStart}`,
                                                                gridRowEnd: `${appointment.rowEnd}`
                                                            }}
                                                        >

                                                        </div> :
                                                        ''
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    ))}
                </Fragment>
            ))}
        </div>
    );
};

export default MonthView;