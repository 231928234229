import classNames from "classnames";
import { Button, Popover, ThemeProvider, createTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});
theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    GRAY: theme.palette.augmentColor({
      color: {
        main: "#6B7280",
      },
      name: "GRAY",
    }),
    PURPLE: theme.palette.augmentColor({
      color: {
        main: "#ac7de8",
      },
      name: "PURPLE",
    }),
  },
});

function Link({ to, children, className, id, closeModal }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const classes = classNames(
    "rounded-lg p-3 ml-5 hover:text-[#ac7de8] hover:font-bold",
    className
  );

  const handleClick = (event) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }
    event.preventDefault();
    
    if (to === "/document" || to === "/accounting") {
      setAnchorEl(event.currentTarget);
      return;
    }
    if(isPhone){closeModal()}
    navigate(to);
  };

  const handleELClose = () => {
    setAnchorEl(null);
  };

  function handleNavigation(link) {
    navigate(link);
    setAnchorEl(null);
  }

  // currentId === to ? "PURPLE" : "GRAY"

  if (isPhone) {
    return (
      <div className="flex items-center justify-center">
        <ThemeProvider theme={theme}>
          <Button
            color={"GRAY"}
            id={id}
            className={classes}
            href={to}
            onClick={handleClick}
          >
            {children}
          </Button>
        </ThemeProvider>
        <Popover
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleELClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {to === "/document" && (
            <div className="w-[200px] grid grid-rows-5">
              <>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleNavigation("/clinic/document/certificate");
                    closeModal();
                  }}
                >
                  Certificate
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleNavigation("/clinic/document/workauthorization");
                    closeModal();
                  }}
                >
                  Work Authorization
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleNavigation(
                      "/clinic/document/pediatricdosagecalculator"
                    );
                    closeModal();
                  }}
                >
                  Pediatric Dosage Calculator
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleNavigation("/clinic/document/rx");
                    closeModal();
                  }}
                >
                  RX
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleNavigation("/clinic/document/consent");
                    closeModal();
                  }}
                >
                  Consent
                </Button>
              </>
            </div>
          )}
          {to === "/accounting" && (
            <div className="w-[200px] grid grid-rows-2">
              <>
                <Button
                  color="secondary"
                  id={"select-bills"}
                  onClick={() => {
                    handleNavigation("/clinic/accounting/billsselect");
                    closeModal();
                  }}
                >
                  Bills
                </Button>
                <Button
                  color="secondary"
                  id={"select-expense"}
                  onClick={() => {
                    handleNavigation("/clinic/accounting/expenseselect");
                    closeModal();
                  }}
                >
                  Expense
                </Button>
              </>
            </div>
          )}
        </Popover>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <ThemeProvider theme={theme}>
        <Button
          color={"GRAY"}
          id={id}
          className={classes}
          href={to}
          onClick={handleClick}
        >
          {children}
        </Button>
      </ThemeProvider>
      <Popover
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleELClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {to === "/document" && (
          <div className="w-[200px] grid grid-rows-5">
            <>
              <Button
                color="secondary"
                onClick={() => {
                  handleNavigation("/clinic/document/certificate");
                }}
              >
                Certificate
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  handleNavigation("/clinic/document/workauthorization")
                }
              >
                Work Authorization
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  handleNavigation("/clinic/document/pediatricdosagecalculator")
                }
              >
                Pediatric Dosage Calculator
              </Button>
              <Button
                color="secondary"
                onClick={() => handleNavigation("/clinic/document/rx")}
              >
                RX
              </Button>
              <Button
                color="secondary"
                onClick={() => handleNavigation("/clinic/document/consent")}
              >
                Consent
              </Button>
            </>
          </div>
        )}
        {to === "/accounting" && (
          <div className="w-[200px] grid grid-rows-2">
            <>
              <Button
                color="secondary"
                id={"select-bills"}
                onClick={() =>
                  handleNavigation("/clinic/accounting/billsselect")
                }
              >
                Bills
              </Button>
              <Button
                color="secondary"
                id={"select-expense"}
                onClick={() =>
                  handleNavigation("/clinic/accounting/expenseselect")
                }
              >
                Expense
              </Button>
            </>
          </div>
        )}
      </Popover>
    </div>
  );
}

export default Link;
