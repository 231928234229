import {
  Alert,
  Button,
  Collapse,
  MenuItem,
  Modal,
  Select,
  TextField,
  Box,
} from "@mui/material";
import { buseEffect, useState, useCallback, useEffect } from "react";
import { COLORBLIND, TOKEN_NAME } from "../../../components/clinic/Constant";
import globalVariables from "../../../globalVariables";
import { SliderPicker } from "react-color";
import jwtDecode from "jwt-decode";
import { MuiColorInput } from "mui-color-input";

import { LuCheckSquare, LuTrash2 } from "react-icons/lu";

import validator from "validator";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

function SettingsPage() {
  const [colorBlind, setColorBlind] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [color, setColor] = useState("");
  const [adminDentistID, setAdminDentistID] = useState(0);

  const [dentistInformation, setDentistInformation] = useState({});
  const [clinicInformation, setClinicInformation] = useState({});
  const [clinicUsersList, setClinicUsersList] = useState({});
  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [addDentistIsOpen, setAddDentistIsOpen] = useState(true);
  const [addAssistantIsOpen, setAddAssistantIsOpen] = useState(false);

  const [dentistNameFirst, setDentistNameFirst] = useState("");
  const [dentistNameMiddle, setDentistNameMiddle] = useState("");
  const [dentistNameLast, setDentistNameLast] = useState("");
  const [dentistEmail, setDentistEmail] = useState("");
  const [dentistNumberMobile, setDentistNumberMobile] = useState("");
  const [dentistPRC, setDentistPRC] = useState("");
  const [dentistColor, setDentistColor] = useState("");

  const [assistantNameFirst, setAssistantNameFirst] = useState("");
  const [assistantNameMiddle, setAssistantNameMiddle] = useState("");
  const [assistantNameLast, setAssistantNameLast] = useState("");
  const [assistantEmail, setAssistantEmail] = useState("");
  const [assistantNumberMobile, setAssistantNumberMobile] = useState("");

  const [currentUser, setCurrentUser] = useState(0);
  const [userToChangeTo, setUserToChangeTo] = useState({});
  const [userToDelete, setUserToDelete] = useState({});
  const [usersToDeleteID, setUsersToDeleteID] = useState([]);
  const [usersToDeleteStaffrole, setUsersToDeleteStaffrole] = useState([]);
  const [changeUserPromptModalIsOpen, setChangeUserPromptModalIsOpen] =
    useState(false);
  const [deleteUserPromptModalIsOpen, setDeleteUserPromptModalIsOpen] =
    useState(false);

  const [numberArray, setNumberArray] = useState([]);
  const [numberArrayCopy, setNumberArrayCopy] = useState([]);
  const [colorArray, setColorArray] = useState([]);
  const [colorArrayCopy, setColorArrayCopy] = useState([]);

  const [clinicUsersIsEdit, setClinicUsersIsEdit] = useState(false);
  const [modal, setModal] = useState(false);

  const [adminAccessPromptModalIsOpen, setAdminAccessPromptModalIsOpen] =
    useState(false);
  const [adminAccessCodeIsEmpty, setAdminAccessCodeIsEmpty] = useState(false);
  const [adminAccessCodePrompt, setAdminAccessCodePrompt] = useState("");
  const [adminAccessCode, setAdminAccessCode] = useState("");
  const [error, setError] = useState("");
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const colorblindClass = classNames(isPhone ? "w-[70%]" : "w-[30%]");

  function clearInputData() {
    setAssistantNameLast("");
    setAssistantNameFirst("");
    setAssistantNameMiddle("");
    setAssistantEmail("");
    setAssistantNumberMobile("");

    setDentistNameLast("");
    setDentistNameFirst("");
    setDentistNameMiddle("");
    setDentistEmail("");
    setDentistNumberMobile("");
    setDentistPRC("");
    setDentistColor("");

    setError("")
  }

  function getMode() {
    const getColorblind = localStorage.getItem(COLORBLIND);
    if (getColorblind === null) setColorBlind(0);
    else setColorBlind(getColorblind);
    const recievedToken = localStorage.getItem(TOKEN_NAME);
    const dentistId = jwtDecode(recievedToken);
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistcolor&id=${dentistId.user}`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setColor(data[0].color);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }

    if (localStorage.getItem("currentUser") !== undefined)
      setCurrentUser(localStorage.getItem("currentUser"));
    else setCurrentUser(0);
  }

  function setMode() {
    localStorage.setItem(COLORBLIND, colorBlind);
    setAlertText("Color Changed Successfully");
    setAlert(true);
  }

  function gettable() {
    const recievedToken = localStorage.getItem(TOKEN_NAME);
    try {
      fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=gettable`, {
        method: "PATCH",
        headers: {
          token: recievedToken,
          "ngrok-skip-browser-warning": true,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // setModal(false);
          setAlertText(data);
          setAlert(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  function posttable() {
    const recievedToken = localStorage.getItem(TOKEN_NAME);
    try {
      fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=posttable`, {
        method: "PATCH",
        headers: {
          token: recievedToken,
          "ngrok-skip-browser-warning": true,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // setModal(false);
          setAlertText(data);
          setAlert(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  // get dentist information
  const getDentistInformation = useCallback(async () => {
    let request;
    // get dentist id through token
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/auth/verification`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    setAdminDentistID(request.user);
    const dentistid = request.user;

    // get dentist information
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${dentistid}`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    request = request[0];

    setDentistInformation(request);
  }, []);

  // get clinic information
  const getClinicInformation = useCallback(async () => {
    let request;
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=clinicinformation&id=${dentistInformation.adminclinicid}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    request = request[0];

    setClinicInformation(request);
  }, [dentistInformation]);

  // get clinic user list
  const getClinicUsersList = useCallback(async () => {
    let list;
    let request;
    // get list of dentists associated with this clinic
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&clinicid=${dentistInformation.adminclinicid}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return console.log("...");
    }
    request = await request.json();

    request = request.map((dentist) => {
      setColorArray((currentArray) => [...currentArray, `${dentist.color}`]);
      setColorArrayCopy((currentArray) => [
        ...currentArray,
        `${dentist.color}`,
      ]);
      setNumberArray((currentArray) => [
        ...currentArray,
        `${dentist.numbermobile}`,
      ]);
      setNumberArrayCopy((currentArray) => [
        ...currentArray,
        `${dentist.numbermobile}`,
      ]);
      return Object.assign(dentist, { staffrole: "dentist" });
    });

    list = request;

    // get list of assitants associated with this clinic
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=assistantinformation&clinicid=${dentistInformation.adminclinicid}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return console.log("...");
    }
    request = await request.json();

    request = request.map((assistant) => {
      setNumberArray((currentArray) => [
        ...currentArray,
        `${assistant.numbermobile}`,
      ]);
      setNumberArrayCopy((currentArray) => [
        ...currentArray,
        `${assistant.numbermobile}`,
      ]);
      return Object.assign(assistant, { staffrole: "assistant" });
    });

    list = list.concat(request);

    setClinicUsersList(list);
  }, [dentistInformation]);

  // add user handler
  const addUserOnClickHandler = () => {
    setAddUserModalIsOpen(true);
  };

  // add user register handler
  const registerUser = async (userType) => {
    let request;

    setError("");
    if (userType === "dentist") {
      if (dentistNameFirst === "") {
        setError("firstname", "First name is required.");
        return;
      }
      if (dentistNameMiddle === "") {
        setError("middlename", "Middle name is required.");
        return;
      }
      if (dentistNameLast === "") {
        setError("lastname", "Last name is required.");
        return;
      }
      if (dentistEmail === "") {
        setError("email", "Email is required.");
        return;
      }
      if (dentistPRC.length !== 7) {
        setError(true);
        setError("prcnumber");
        return;
      }

      const emailExists = clinicUsersList.some(
        (clinic) => clinic.email === dentistEmail
      );

      if (emailExists) {
        setError("emailExists");
        return;
      }

      // create dentist information
      const tempDentist = {
        namelast: dentistNameLast,
        namefirst: dentistNameFirst,
        namemiddle: dentistNameMiddle,
        email: dentistEmail,
        numbermobile: dentistNumberMobile,
        numberprc: dentistPRC,
        password: "",
        clinicid: dentistInformation.adminclinicid,
        adminclinicid: null,
        color: dentistColor,
      };
      // request server
      try {
        request = await fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=dentist_information`,
          {
            method: "POST",
            body: JSON.stringify(tempDentist),
            headers: {
              token: localStorage.token,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log("...");
      }
      request = await request.json();
      clearInputData()
      return setAddUserModalIsOpen(false);
    } else if (userType === "assistant") {
      if (assistantNameFirst === "") {
        setError("assistantfirstname");
        return;
      }
      if (assistantNameMiddle === "") {
        setError("assistantmiddlename");
        return;
      }
      if (assistantNameLast === "") {
        setError("assistantlastname");
        return;
      }
      if (assistantEmail === "") {
        setError("assistantemail");
        return;
      }

      // create assistant information

      const tempAssistant = {
        namelast: assistantNameLast,
        namefirst: assistantNameFirst,
        namemiddle: assistantNameMiddle,
        email: assistantEmail,
        numbermobile: assistantNumberMobile,
        clinicid: dentistInformation.adminclinicid,
      };
      // request server
      try {
        request = await fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=assistantinformation`,
          {
            method: "POST",
            body: JSON.stringify(tempAssistant),
            headers: {
              token: localStorage.token,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log("...");
      }
      request = await request.json();
      clearInputData()
      return setAddUserModalIsOpen(false);
      //return setAddUserModalIsOpen(false);
    }
  };

  const changeCurrentUser = async () => {
    const userDetails = userToChangeTo;
    localStorage.setItem("currentUser", userDetails.id);
    localStorage.setItem("currentUserRole", userDetails.staffrole);
    setCurrentUser(userDetails.id);
    setChangeUserPromptModalIsOpen(false);
    return window.location.reload();
  };

  const changeColor = (colorToChangeTo, index) => {
    const updatedColorArray = colorArray.map((color, i) => {
      if (i === index) return `${colorToChangeTo}`;
      else return `${color}`;
    });
    setColorArray(updatedColorArray);
  };

  const changeNumber = (numberToChangeTo, index) => {
    const updatedNumberArray = numberArray.map((number, i) => {
      if (i === index) return `${numberToChangeTo}`;
      else return `${number}`;
    });
    setNumberArray(updatedNumberArray);
  };

  const updateClinicUsers = () => {
    clinicUsersList.map(async (user, i) => {
      if (user.staffrole === "dentist") {
        try {
          await fetch(
            `${globalVariables.WEB_ADDRESS_PORT}/?type=updatedentist&id=${user.id}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                color: colorArray[i],
                numbermobile: numberArray[i],
              }),
              headers: {
                "ngrok-skip-browser-warning": true,
                token: localStorage.token,
                "Content-Type": "application/json",
              },
            }
          );
          await fetch(
            `${globalVariables.WEB_ADDRESS_PORT}/?type=updateappointment&dentistid=${user.id}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                color: colorArray[i],
              }),
              headers: {
                "ngrok-skip-browser-warning": true,
                token: localStorage.token,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.log("...");
        }
      } else if (user.staffrole === "assistant") {
        try {
          await fetch(
            `${globalVariables.WEB_ADDRESS_PORT}/?type=assistantinformation&id=${user.id}`,
            {
              method: "PATCH",
              body: JSON.stringify({
                numbermobile: numberArray[i],
              }),
              headers: {
                "ngrok-skip-browser-warning": true,
                token: localStorage.token,
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.log("...");
        }
      }
    });

    usersToDeleteID.map((id, i) => {
      deleteUser(id, usersToDeleteStaffrole[i]);
    });

    setUsersToDeleteID([]);
    setUsersToDeleteStaffrole([]);
    setClinicUsersIsEdit(false);
  };

  const deleteUser = async (userID, userStaffrole) => {
    if (userStaffrole === "dentist") {
      try {
        await fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${userID}`,
          {
            method: "DELETE",
            headers: {
              "ngrok-skip-browser-warning": true,
              token: localStorage.token,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log("...");
      }
    } else if (userStaffrole === "assistant") {
      try {
        await fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=assistantinformation&id=${userID}`,
          {
            method: "DELETE",
            headers: {
              "ngrok-skip-browser-warning": true,
              token: localStorage.token,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log("...");
      }
    }
    setAlertText("User successfully deleted");
    setAlert(true);
    return setDeleteUserPromptModalIsOpen(false);
  };

  const adminAccessCodeHandler = async () => {
    if (adminAccessCode.length === 0) {
      setAdminAccessCodePrompt("Cannot be empty");
      return setAdminAccessCodeIsEmpty(true);
    } else {
      setAdminAccessCodePrompt("");
      setAdminAccessCodeIsEmpty(false);
    }

    let accessCodeIsValid;

    // check if admin access code is correct or not
    try {
      accessCodeIsValid = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/auth/access_code`,
        {
          method: "POST",
          body: JSON.stringify({
            accesscode: adminAccessCode,
            admindentistid: adminDentistID,
          }),
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    accessCodeIsValid = await accessCodeIsValid.json();
    accessCodeIsValid = accessCodeIsValid.accesscodeisvalid;
    if (accessCodeIsValid) return changeCurrentUser();

    setAdminAccessCodePrompt("Invalid access code");
    return setAdminAccessCodeIsEmpty(true);
  };

  // use effects

  useEffect(() => {
    getDentistInformation();
  }, [clinicUsersIsEdit, addUserModalIsOpen]);

  useEffect(() => {
    if (dentistInformation && dentistInformation.adminclinicid) {
      getClinicInformation();
    }
  }, [dentistInformation]);

  useEffect(() => {
    if (clinicInformation && clinicInformation.id) {
      getClinicUsersList();
    }
  }, [clinicInformation]);

  useEffect(() => {
    getMode();
  }, []);

  useEffect(() => {}, [currentUser, adminAccessCodeIsEmpty]);

  return (
    <div className="">
      <Modal
        open={addUserModalIsOpen}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column", // Ensures items stack vertically
          position: "absolute",
        }}
      >
        <div className="bg-white rounded-lg outline-none shadow-lg p-5 w-[80%]">
          <p className="text-2xl font-bold">Add User</p>
          <div className="my-5">
            <Button
              onClick={() => {
                setAddDentistIsOpen(true);
                setAddAssistantIsOpen(false);
              }}
            >
              <p>+ Dentist</p>
            </Button>
            <Button
              onClick={() => {
                setAddDentistIsOpen(false);
                setAddAssistantIsOpen(true);
              }}
            >
              <p>+ Assistant</p>
            </Button>
            <div className="my-5">
              {addDentistIsOpen ? (
                <div className="flex flex-row space-x-10">
                  <div className="flex flex-col gap-4 w-1/2">
                    <p className="text-gray-500">Dentist Name</p>
                    <TextField
                      required
                      id="outlined-required"
                      label="First Name"
                      fullWidth
                      onKeyDown={(event) => {
                        // Allow only letters, backspace, delete, arrow keys, tab, and "ñ"
                        if (
                          !/[a-zA-ZñÑ\s]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[a-zA-ZñÑ\s-]*$/.test(value)) {
                          setDentistNameFirst(value);
                        }
                      }}
                      error={error === "firstname" ? true : false}
                      helperText={
                        error === "firstname" ? "First Name is Required" : ""
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Middle Name"
                      fullWidth
                      onKeyDown={(event) => {
                        // Allow only letters, backspace, delete, arrow keys, tab, and "ñ"
                        if (
                          !/[a-zA-ZñÑ]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[a-zA-ZñÑ\s-]*$/.test(value)) {
                          setDentistNameMiddle(value);
                        }
                      }}
                      error={error === "middlename" ? true : false}
                      helperText={
                        error === "middlename" ? "Middle Name is Required" : ""
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Last Name"
                      fullWidth
                      onKeyDown={(event) => {
                        // Allow only letters, backspace, delete, arrow keys, and tab
                        if (
                          !/[a-zA-ZñÑ]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[a-zA-ZñÑ\s-]*$/.test(value)) {
                          setDentistNameLast(value);
                        }
                      }}
                      error={error === "lastname" ? true : false}
                      helperText={
                        error === "lastname" ? "Last Name is Required" : ""
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-4 w-1/2">
                    <p className="text-gray-500">Other Details</p>
                    <TextField
                      required
                      id="outlined-required"
                      label="Email"
                      fullWidth
                      onChange={(event) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (emailRegex.test(event.target.value)) {
                          setDentistEmail(event.target.value);
                          setError(null);
                        } else {
                          setDentistEmail("");
                          setError("email");
                        }
                      }}
                      error={
                        error === "email" || error === "emailExists"
                          ? true
                          : false
                      }
                      helperText={
                        error === "email"
                          ? "Email is Required"
                          : error === "emailExists"
                          ? "Email is already in use"
                          : ""
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Mobile Number"
                      placeholder={"09xxxxxxxxx"}
                      inputProps={{ maxLength: 11 }}
                      fullWidth
                      onChange={(event) => {
                        const input = event.target.value;
                        if (!/^\d{11}$/.test(input)) {
                          setError("mobilenumber");
                        } else {
                          setError(null);
                          setDentistNumberMobile(input);
                        }
                      }}
                      error={error === "mobilenumber" ? true : false}
                      helperText={
                        error === "mobilenumber"
                          ? "Mobile Number must be 11 digits"
                          : ""
                      }
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="PRC Number"
                      fullWidth
                      inputProps={{ maxLength: 7 }}
                      onKeyDown={(event) => {
                        // Allow only numeric keys, backspace, and delete
                        if (
                          !/[0-9]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        let input = event.target.value;
                        // Remove non-numeric characters
                        input = input.replace(/\D/g, "");
                        // Limit input to 7 characters
                        if (input.length > 7) {
                          input = input.slice(0, 7);
                        }
                        setDentistPRC(input);
                        // Check if the input is exactly 7 digits
                        if (!/^\d{7}$/.test(input)) {
                          setError("prcnumber");
                        } else {
                          setError(null);
                        }
                      }}
                      value={dentistPRC} // Bind the input field with the state
                      error={error === "prcnumber"}
                      helperText={
                        error === "prcnumber"
                          ? "PRC Number must be 7 digits"
                          : ""
                      }
                    />
                    <p>Set Dentist Color</p>
                    <SliderPicker
                      onChange={(event) => {
                        setDentistColor(event.hex);
                      }}
                      color={dentistColor}
                    />
                  </div>
                </div>
              ) : addAssistantIsOpen ? (
                <div className="flex flex-row space-x-10">
                  <div className="flex flex-col gap-4 w-1/2">
                    <p className="text-gray-500">Assistant Name</p>
                    <TextField
                      required
                      id="outlined-required"
                      label="First Name"
                      fullWidth
                      onKeyDown={(event) => {
                        // Allow only letters, backspace, delete, arrow keys, and tab
                        if (
                          !/[a-zA-ZñÑ\s]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[a-zA-ZñÑ\s-]*$/.test(value)) {
                          setAssistantNameFirst(value);
                        }
                      }}
                      error={error === "assistantfirstname" ? true : false}
                      helperText={
                        error === "assistantfirstname"
                          ? "First Name is Required"
                          : ""
                      }
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Middle Name"
                      fullWidth
                      onKeyDown={(event) => {
                        // Allow only letters, backspace, delete, arrow keys, and tab
                        if (
                          !/[a-zA-ZñÑ]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[a-zA-ZñÑ\s-]*$/.test(value)) {
                          setAssistantNameMiddle(value);
                        }
                      }}
                      error={error === "assistantmiddlename" ? true : false}
                      helperText={
                        error === "assistantmiddlename"
                          ? "Middle Name is Required"
                          : ""
                      }
                    />
                    <TextField
                      required
                      id="outlined-required"
                      label="Last Name"
                      onKeyDown={(event) => {
                        // Allow only letters, backspace, delete, arrow keys, and tab
                        if (
                          !/[a-zA-ZñÑ]/.test(event.key) &&
                          event.key !== "Backspace" &&
                          event.key !== "Delete" &&
                          event.key !== "ArrowLeft" &&
                          event.key !== "ArrowRight" &&
                          event.key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      fullWidth
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[a-zA-ZñÑ\s-]*$/.test(value)) {
                          setAssistantNameLast(value);
                        }
                      }}
                      error={error === "assistantlastname" ? true : false}
                      helperText={
                        error === "assistantlastname"
                          ? "Last Name is Required"
                          : ""
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-4 w-1/2">
                    <p className="text-gray-500">Other Details</p>
                    <TextField
                      required
                      id="outlined-required"
                      label="Email"
                      fullWidth
                      onChange={(event) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (emailRegex.test(event.target.value)) {
                          setAssistantEmail(event.target.value);
                          setError(null);
                        } else {
                          setAssistantEmail("");
                          setError("email");
                        }
                      }}
                      error={error === "email" ? true : false}
                      helperText={
                        error === "email" ? "Invalid email format" : ""
                      }
                    />

                    <TextField
                      required
                      id="outlined-required"
                      label="Mobile Number"
                      placeholder={"09xxxxxxxxx"}
                      fullWidth
                      inputProps={{ maxLength: 11 }}
                      onChange={(event) => {
                        const input = event.target.value;
                        if (!/^\d{11}$/.test(input)) {
                          setError("mobilenumber");
                        } else {
                          setError(null);
                          setAssistantNumberMobile(input);
                        }
                      }}
                      error={error === "mobilenumber" ? true : false}
                      helperText={
                        error === "mobilenumber"
                          ? "Mobile Number must be 11 digits"
                          : ""
                      }
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-row-reverse gap-4">
              <Button
                onClick={() => {
                  registerUser(
                    addDentistIsOpen
                      ? "dentist"
                      : addAssistantIsOpen
                      ? "assistant"
                      : ""
                  );
                }}
                variant={"contained"}
              >
                <p>Register</p>
              </Button>
              <Button
                onClick={() => {
                  setAddUserModalIsOpen(false);
                  clearInputData()
                }}
              >
                <p>Cancel</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={changeUserPromptModalIsOpen}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            padding: "15px",
            boxShadow: 24,
            borderRadius: "10px",
          }}
        >
          {
            <div className="flex flex-col">
              <div className="pb-[15px]">
                {`Are you sure you want to switch user as ${
                  userToChangeTo.staffrole === "dentist" ? "Dr." : "Assistant"
                } ${userToChangeTo.namefirst}?`}
              </div>
              <div className="flex flex-row-reverse gap-[15px]">
                <Button variant="contained" onClick={changeCurrentUser}>
                  Switch
                </Button>
                <Button onClick={() => setChangeUserPromptModalIsOpen(false)}>
                  Cancel
                </Button>
              </div>
            </div>
          }
        </Box>
      </Modal>
      <Modal open={deleteUserPromptModalIsOpen}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            padding: "15px",
            boxShadow: 24,
            borderRadius: "10px",
          }}
        >
          <div className="flex flex-col">
            <div className="pb-[15px]">
              {`Are you sure you want to remove ${
                userToDelete.staffrole === "dentist" ? "Dr." : "Assistant"
              } ${userToDelete.namefirst} from the clinic?`}
            </div>
            <div className="flex flex-row-reverse gap-[15px]">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setUsersToDeleteID((currentArray) => [
                    ...currentArray,
                    `${userToDelete.id}`,
                  ]);
                  setUsersToDeleteStaffrole((currentArray) => [
                    ...currentArray,
                    `${userToDelete.staffrole}`,
                  ]);
                  return setDeleteUserPromptModalIsOpen(false);
                }}
              >
                Delete
              </Button>
              <Button onClick={() => setDeleteUserPromptModalIsOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={adminAccessPromptModalIsOpen}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            padding: "15px",
            boxShadow: 24,
            borderRadius: "10px",
          }}
        >
          <div className="flex flex-col">
            <div className="pb-[15px]">
              {`To switch users, you need admin access. Please input the admin access code below:`}
            </div>
            <div className="mb-[15px]">
              <TextField
                required
                type="password"
                error={adminAccessCodeIsEmpty}
                helperText={adminAccessCodePrompt}
                id="adminAccessCode"
                label="Admin Access Code"
                sx={{
                  width: "100%",
                }}
                onChange={(event) => {
                  setAdminAccessCodePrompt("");
                  setAdminAccessCodeIsEmpty(false);
                  return setAdminAccessCode(event.target.value);
                }}
              />
            </div>
            <div className="flex flex-row-reverse gap-[15px]">
              <Button variant="contained" onClick={adminAccessCodeHandler}>
                Enter
              </Button>
              <Button
                onClick={() => {
                  setAdminAccessPromptModalIsOpen(false);
                  return setAdminAccessCodeIsEmpty(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Collapse in={alert}>
        <Alert
          severity="success"
          sx={{ marginBottom: "15px" }}
          onClose={() => setAlert(false)}
        >
          {alertText}
        </Alert>
      </Collapse>
      <p className="text-2xl font-bold">Clinic Information</p>
      <div className="my-5">
        <p className="text-lg">
          Name: {clinicInformation && clinicInformation.clinicname}
        </p>
        <p className="text-lg">
          Address: {clinicInformation && clinicInformation.clinicaddress}
        </p>
        <p className="text-lg">
          Email: {clinicInformation && clinicInformation.clinicemail}
        </p>
        <p className="text-lg">
          Mobile#: {clinicInformation && clinicInformation.clinicnumbermobile}
        </p>
        <p className="text-lg">
          Telephone#:{" "}
          {clinicInformation && clinicInformation.clinicnumbertelephone}
        </p>
      </div>
      <div className="flex flex-row gap-x-[15px]">
        <p className="text-2xl font-bold">Clinic Users</p>
        <div>
          {clinicUsersIsEdit ? (
            <div className="flex flex-row gap-x-[15px]">
              <Button
                onClick={() => {
                  setColorArray(colorArrayCopy);
                  setNumberArray(numberArrayCopy);
                  setUsersToDeleteID([]);
                  setUsersToDeleteStaffrole([]);
                  setError("");
                  return setClinicUsersIsEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={updateClinicUsers} variant="contained">
                Save
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => setClinicUsersIsEdit(true)}
              variant="contained"
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="my-5 text-lg">
        <table className="table-auto w-full text-left border-separate border-spacing-[8px]">
          <thead>
            <tr>
              <th>Name</th>
              <th>Staff Role</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Color</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(clinicUsersList) &&
              clinicUsersList.map((user, i) => {
                return (
                  <tr key={i} className="h-[60px]">
                    <td>
                      <div>
                        {`${user.namefirst} ${user.namemiddle} ${user.namelast}`}
                      </div>
                    </td>
                    <td>
                      <div>
                        {user.staffrole === "dentist" ? "Dentist" : "Assistant"}
                      </div>
                    </td>
                    <td>
                      <div>{user.email}</div>
                    </td>
                    <td>
                      <div>
                        <TextField
                          value={numberArray[i]}
                          onChange={(event) =>
                            changeNumber(event.target.value, i)
                          }
                          error={
                            !validator.isMobilePhone(numberArray[i]) &&
                            clinicUsersIsEdit
                              ? true
                              : false
                          }
                          helperText={
                            !validator.isMobilePhone(numberArray[i]) &&
                            clinicUsersIsEdit
                              ? "Enter a valid mobile number"
                              : ""
                          }
                          style={{
                            width: "100%",
                          }}
                          disabled={!clinicUsersIsEdit}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex place-items-center cursor-pointer">
                        {user.staffrole === "dentist" ? (
                          <MuiColorInput
                            format="hex"
                            value={colorArray[i]}
                            onChange={(color) => changeColor(color, i)}
                            sx={{
                              width: "100%",
                            }}
                            disabled={!clinicUsersIsEdit}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="flex place-items-center ml-[20px] w-[200px]">
                        {clinicUsersIsEdit ? (
                          <div className="flex place-items-center">
                            {user.id !== adminDentistID ? (
                              !usersToDeleteID.includes(`${user.id}`) ? (
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={() => {
                                    setDeleteUserPromptModalIsOpen(true);
                                    return setUserToDelete(user);
                                  }}
                                >
                                  Delete
                                </Button>
                              ) : (
                                <div>To be deleted upon save</div>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div>
                            {user.id !== parseInt(currentUser, 10) ? (
                              <div>
                                <Button
                                  onClick={() => {
                                    if (
                                      parseInt(
                                        localStorage.getItem("currentUser"),
                                        10
                                      ) === adminDentistID
                                    )
                                      setChangeUserPromptModalIsOpen(true);
                                    else setAdminAccessPromptModalIsOpen(true);
                                    return setUserToChangeTo(user);
                                  }}
                                >
                                  Switch User
                                </Button>
                              </div>
                            ) : (
                              <div className="flex flex-row place-items-center gap-x-[15px] ml-[15px]">
                                <LuCheckSquare className="text-green-700" />
                                Using
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <Button onClick={() => addUserOnClickHandler()}>
                <p className="normal-case text-lg">+ Add User</p>
              </Button>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="text-2xl font-bold">Colorblind Mode Settings</p>
      <div className="mt-5">
        <div>
          <Select
            id="dropdown-gender"
            value={colorBlind}
            onChange={(event) => setColorBlind(event.target.value)}
            className={colorblindClass}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={1}>Deuteranopia/Protanopia </MenuItem>
            <MenuItem value={3}>Tritanopia</MenuItem>
          </Select>
        </div>
        <div className="my-5">
          <Button onClick={setMode} variant={"contained"}>
            Save
          </Button>
        </div>
        <p className="text-2xl font-bold">Data Handling</p>
        <div className="my-5">
          <Button variant={"contained"} onClick={posttable}>
            Upload Data to Cloud
          </Button>
        </div>
        <div>
          <Button variant={"contained"} onClick={gettable}>
            Retrieve Data From Cloud
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;
