import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { AMALGAM_FILLING, CAVITY, COMPOSITE_FILLING, CROWN, METAL_CROWN, NORMAL } from "./Constant";
import { useEffect, useState } from "react";

function TeethDropdown({ teethColor, dispatchType, handleChange }) {
  const [teethValue, setTeethValue] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTeethValue(teethColor)
    if (teethValue !== "") {
      setLoading(false)
    }
  }, [teethColor])

  useEffect(() => {
    if (teethValue !== "") {
      setLoading(false);
    }
  }, [teethValue]);

  
  if (loading) return <div>Loading..</div>
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Change</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={teethValue}
        label="Change"
        onChange={e => handleChange(e, dispatchType)}
      >
        <MenuItem value={CAVITY}>Cavity</MenuItem>
        <MenuItem value={COMPOSITE_FILLING}>Composite Filling</MenuItem>
        <MenuItem value={AMALGAM_FILLING}>Amalgam Filling</MenuItem>
        <MenuItem value={CROWN}>Crown</MenuItem>
        <MenuItem value={METAL_CROWN}>Metal Crown</MenuItem>
        <MenuItem value={NORMAL}>Normal</MenuItem>
      </Select>
    </FormControl>
  );
}

export default TeethDropdown;
