// InformationTab.js
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Box, Button, Modal } from "@mui/material";
import { JSON_NAME, TOKEN_NAME } from "../../../components/clinic/Constant";
import dayjs from "dayjs";
import globalVariables from "../../../globalVariables";
import EditMedicalHistory from "../medical-history/EditMedicalHistory";
import { useMediaQuery } from "react-responsive";

function InformationTab() {
  const PERSONAL_INFO = "personal-info";
  const MEDICAL_HISTORY = "medical-history";
  const CONTACT_INFO = "contact-info";
  const recievedToken = localStorage.getItem(TOKEN_NAME);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const [activeTab, setActiveTab] = useState(PERSONAL_INFO);
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState(true);
  const [medicalHistory, setMedicalHistory] = useState({});
  const [editModal, setEditModal] = useState(false);
  const tab = classNames(
    "bg-[#d8bfd8] float-left border-light-steel-blue border-2 cursor-pointer w-1/3 hover:bg-[#ccb6d8] font-bold",
    isPhone ? " p-1" : "p-10"
  );
  const textTab = classNames("shadow-md py-5 my-5 rounded-md p-5");
  const tabContainer = classNames("bg-[#add8e6] overflow-hidden");
  const tabContent = classNames("p-20 bg-white border-light-gray border-2");
  const h2Style = classNames("font-bold flex text-xl");
  const medicalRecord = classNames(isPhone ? "bg-white w-[90%] ml-[5%] mt-[2%] p-10" : "bg-white w-[50%] ml-[25%] mt-[2%] p-10")

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  function medicalInformation() {
    const storedData = localStorage.getItem(JSON_NAME);
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=medicaldata&patientid=${storedData}`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const trimmedData = data.trim();
          setMedicalHistory(JSON.parse(trimmedData));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  function patientInformation() {
    const storedData = localStorage.getItem(JSON_NAME);
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&id=${storedData}&method=forms`,
        {
          method: "GET",
          headers: { "ngrok-skip-browser-warning": true, token: recievedToken },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPatient(data[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  useEffect(() => {
    patientInformation();
    medicalInformation();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      style={{
        backgroundColor: "white",
        overflowY: "auto",
        fontFamily: "Arial, sans-serif",
        width: isPhone ? "100%" : "1200px",
        height: "800px",
      }}
    >
      <div className={tabContainer}>
        <button className={tab} onClick={() => openTab(PERSONAL_INFO)}>
          Personal Information
        </button>
        {isPhone ? (
          <button className={tab} onClick={() => openTab(MEDICAL_HISTORY)}>
            Medical <div>History</div>
          </button>
        ) : (
          <button className={tab} onClick={() => openTab(MEDICAL_HISTORY)}>
            Medical History
          </button>
        )}
        <button className={tab} onClick={() => openTab(CONTACT_INFO)}>
          Contact Information
        </button>
      </div>

      {activeTab === PERSONAL_INFO ? (
        <div className={tabContent}>
          <h2 className={h2Style}>Personal Information</h2>
          <div className={textTab}>
            <p className="font-bold text-lg">Name:</p>
            <p>
              {patient.namefirst} {patient.namemiddle} {patient.namelast}
            </p>
          </div>
          <div className={textTab}>
            <p className="font-bold text-lg">Date of Birth:</p>
            <p>{dayjs(patient.dateofbirth).format("MMMM, DD YYYY")}</p>
          </div>
          <div className={textTab}>
            <p className="font-bold text-lg">Gender: </p>
            <p>{patient.patientgender}</p>
          </div>
          {/* Add more personal information fields as needed */}
        </div>
      ) : (
        ""
      )}

      {activeTab === MEDICAL_HISTORY ? (
        <div className={tabContent}>
          <div className="w-[100%]">
            <Button onClick={() => setEditModal(true)}>Edit</Button>
            <Modal open={editModal} onClose={() => setEditModal(false)}>
              <div className={medicalRecord}>
                <EditMedicalHistory
                  gender={patient.patientgender}
                  open={editModal}
                  value={medicalHistory}
                  id={patient.id}
                  medicalInfo={medicalInformation}
                  loading={setLoading}
                  editModal={setEditModal}
                />
              </div>
            </Modal>
            <h2 className={h2Style}>Medical History</h2>
            <div className="mt-5" />
            <div className={textTab}>
              <p className="font-bold text-lg">
                Last Medical/Physical Examination:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_1.month !==
                  undefined &&
                  JSON.stringify(
                    medicalHistory.medicalHistoryQuestions.question_1.month
                  )}
                ,{" "}
                {medicalHistory.medicalHistoryQuestions.question_1.year !==
                  undefined &&
                  JSON.stringify(
                    medicalHistory.medicalHistoryQuestions.question_1.year
                  )}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Name and Address of Personal Physician:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_2.doctor !==
                  undefined &&
                  `Dr. ${JSON.stringify(
                    medicalHistory.medicalHistoryQuestions.question_2.doctor
                  )}, `}
                {medicalHistory.medicalHistoryQuestions.question_2 !==
                  undefined &&
                  JSON.stringify(
                    medicalHistory.medicalHistoryQuestions.question_2
                      .clinic_address
                  )}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Under the care of any Physician:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_3.isYes ===
                true
                  ? `Yes, Dr. ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_3.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">Been Hospitalized:</p>{" "}
              <p>
                {medicalHistory.medicalHistoryQuestions.question_4.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_4.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Had Serious Illness or Operation:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_5.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_5.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">Any Disease or Problems:</p>{" "}
              <p>
                {medicalHistory.medicalHistoryQuestions.question_6.isYes ===
                true
                  ? `Yes`
                  : "No"}
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_6.isYes ===
                true
                  ? `Type: ${medicalHistory.medicalHistoryQuestions.question_6.field
                      .map((item) => item.label)
                      .join(", ")}`
                  : ""}
              </p>
            </div>

            <div className={textTab}>
              <p className="font-bold text-lg">Blood Pressure (mmHg):</p>{" "}
              {medicalHistory.medicalHistoryQuestions.question_7.length > 0 ? (
                <p>
                  {JSON.stringify(
                    medicalHistory.medicalHistoryQuestions.question_7.field
                  )}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Had Abonrmal Bleeding with Previous Tooth Extractions, Surgery
                or Trauma:
              </p>{" "}
              <p>
                {medicalHistory.medicalHistoryQuestions.question_8.isYes ===
                true
                  ? "Yes"
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Surgery or Radiation treatment in head and neck area?:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_9.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_9.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">Drug Maintenance Present:</p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_10.isYes ===
                true
                  ? `Yes`
                  : "No"}
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_10.isYes ===
                true
                  ? `Type: ${medicalHistory.medicalHistoryQuestions.question_10.field
                      .map((item) => {
                        if (item.label === "Others maintenance drugs") {
                          return item.value;
                        } else {
                          return item.label;
                        }
                      })
                      .join(", ")}`
                  : ""}
              </p>
            </div>

            <div className={textTab}>
              <p className="font-bold text-lg">
                Allergy or have Reacted Adversely to any Drug, Antibiotic, or
                Analgesic:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_11.isYes ===
                true
                  ? `Yes`
                  : "No"}
              </p>
              <p>
                {`${
                  medicalHistory.medicalHistoryQuestions.question_11.isYes ===
                  true
                    ? "Type: " +
                      medicalHistory.medicalHistoryQuestions.question_11.field
                        .map((item) => {
                          if (
                            item.label ===
                              "Other Antibiotics (please indicate)" ||
                            item.label ===
                              "Other Analgesics (please indicate)" ||
                            item.label === "Others Drugs"
                          ) {
                            return item.value;
                          } else {
                            return item.label;
                          }
                        })
                        .join(", ")
                    : ""
                }`}
              </p>
            </div>

            <div className={textTab}>
              <p className="font-bold text-lg">Food Allergy:</p>{" "}
              <p>
                {medicalHistory.medicalHistoryQuestions.question_12.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_12.field
                    )}`
                  : "No"}
              </p>
            </div>

            <div className={textTab}>
              <p className="font-bold text-lg">
                Serious Trouble Associated with any Previous Dental Treatment:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_13.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_13.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Have any Disease, Condition, or Problem not listed above:
              </p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_14.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_14.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">Smoker:</p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_15.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_15.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">Drinking Alcoholic Beverage:</p>
              <p>
                {medicalHistory.medicalHistoryQuestions.question_16.isYes ===
                true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_16.field
                    )}`
                  : "No"}
              </p>
            </div>
            {patient.patientgender === "Female" ? (
              <div className={textTab}>
                <p className="font-bold text-lg">Pregnant:</p>
                <p>
                  {medicalHistory.medicalHistoryQuestions.question_17.field !==
                    undefined &&
                    `${JSON.stringify(
                      medicalHistory.medicalHistoryQuestions.question_17.field
                    )}`}
                </p>
              </div>
            ) : (
              ""
            )}
            {patient.patientgender === "Female" ? (
              <div className={textTab}>
                <p className="font-bold text-lg">
                  Missed last period or suspected pregnancy:
                </p>
                <p>
                  {medicalHistory.medicalHistoryQuestions.question_18.isYes ===
                  true
                    ? `Yes`
                    : "No"}
                </p>
              </div>
            ) : (
              ""
            )}
            {patient.patientgender === "Female" ? (
              <div className={textTab}>
                <p className="font-bold text-lg">Presently Breastfeeding:</p>
                <p>
                  {medicalHistory.medicalHistoryQuestions.question_19.isYes ===
                  true
                    ? `Yes`
                    : "No"}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="mt-5" />
            <h2 className={h2Style}>Dental History</h2>
            <div className="mb-5" />
            <div className={textTab}>
              <p className="font-bold text-lg">Reason of Visitation:</p>
              <p>{medicalHistory.dentalHistoryQuestions.question_1.field}</p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">Last Dental Visit:</p>
              <p>
                <p>
                  {JSON.stringify(
                    medicalHistory.dentalHistoryQuestions.question_2.procedure
                  )}
                  ,{" "}
                  {JSON.stringify(
                    medicalHistory.dentalHistoryQuestions.question_2
                      .date_of_procedure
                  )}
                </p>
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Allergic to any Dental Material:
              </p>
              <p>
                {medicalHistory.dentalHistoryQuestions.question_3.isYes === true
                  ? "Yes"
                  : "No"}
              </p>
              <p>
                {medicalHistory.dentalHistoryQuestions.question_3.isYes === true
                  ? `Ty[e: ${medicalHistory.dentalHistoryQuestions.question_3.field
                      .map((item) => {
                        if (item.label === "others") {
                          return item.value;
                        } else {
                          return item.label;
                        }
                      })
                      .join(", ")}`
                  : ""}
              </p>
            </div>

            <div className={textTab}>
              <p className="font-bold text-lg">
                Informed that Patient Grinds Teeth at Night:
              </p>
              <p>
                {medicalHistory.dentalHistoryQuestions.question_4.isYes === true
                  ? "Yes"
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Patient Clench's Teeth Anytime of the Day while Awake:
              </p>
              <p>
                {medicalHistory.dentalHistoryQuestions.question_5.isYes === true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.dentalHistoryQuestions.question_5.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                Engages with Type of Contact Sport:
              </p>
              <p>
                {medicalHistory.dentalHistoryQuestions.question_6.isYes === true
                  ? `Yes, ${JSON.stringify(
                      medicalHistory.dentalHistoryQuestions.question_6.field
                    )}`
                  : "No"}
              </p>
            </div>
            <div className={textTab}>
              <p className="font-bold text-lg">
                How often does Patient Drink or Eat the Following:
              </p>
              <p className="font-bold text-lg mt-5">Chew on Ice Cubes:</p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7
                    .chew_on_ice_cubes
                )}
              </p>
              <p className="font-bold text-lg mt-5">Coffee:</p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7.coffee
                )}
              </p>
              <p className="font-bold text-lg mt-5">Cola:</p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7.cola
                )}
              </p>
              <p className="font-bold text-lg mt-5">Colored Juices:</p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7
                    .colored_juices
                )}
              </p>
              <p className="font-bold text-lg mt-5">Red Wine:</p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7.red_wine
                )}
              </p>
              <p className="font-bold text-lg mt-5">Tea:</p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7.tea
                )}
              </p>
              <p className="font-bold text-lg mt-5">
                Tomato Based Foood/Drink:
              </p>
              <p>
                {JSON.stringify(
                  medicalHistory.dentalHistoryQuestions.question_7
                    .tomato_based_foodDrink
                )}
              </p>
              <p className="font-bold text-lg mt-5">
                {medicalHistory.dentalHistoryQuestions.question_7
                  .others_field &&
                  `${JSON.stringify(
                    medicalHistory.dentalHistoryQuestions.question_7
                      .others_field
                  )} (Other)`}
              </p>
              <p>
                {medicalHistory.dentalHistoryQuestions.question_7
                  .others_field &&
                  JSON.stringify(
                    medicalHistory.dentalHistoryQuestions.question_7
                      .tomato_based_foodDrink
                  )}
              </p>
            </div>
          </div>
          {/* Add more medical history fields as needed */}
        </div>
      ) : (
        ""
      )}

      {activeTab === CONTACT_INFO ? (
        <div className={tabContent}>
          <h2 className={h2Style}>Contact Information</h2>
          <div className={textTab}>
            <p className="font-bold text-lg">Email:</p>
            <p>{patient.email}</p>
          </div>
          <div className={textTab}>
            <p className="font-bold text-lg">Phone: </p>
            <p>{patient.numbermobile}</p>
          </div>
          <div className={textTab}>
            <p className="font-bold text-lg">Address: </p>
            <p>{patient.patientaddress}</p>
          </div>
          {/* Add more contact information fields as needed */}
        </div>
      ) : (
        ""
      )}
    </Box>
  );
}

export default InformationTab;
