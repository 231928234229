import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import './ClinicAddress.css';
import { ColoredButton, RegisterContainer, Textfield } from "../../../../../components";

const ClinicAddress = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let { clinicDetails } = location.state || {};

    // variables for address
    const [addressIsEmpty, setAddressIsEmpty] = useState(false);
    const [address, setAddress] = useState('');

    // variables for city
    const [cityIsEmpty, setCityIsEmpty] = useState(false);
    const [city, setCity] = useState('');

    // variables for province
    const [provinceIsEmpty, setProvinceIsEmpty] = useState(false);
    const [province, setProvince] = useState('');

    const next = () => {
        // check if address is empty
        if(!address.trim().length) return setAddressIsEmpty(true);
        else setAddressIsEmpty(false);

        // check if city is empty
        if(!city.trim().length) return setCityIsEmpty(true);
        else setCityIsEmpty(false);

        // check if province is empty
        if(!province.trim().length) return setProvinceIsEmpty(true);
        else setProvinceIsEmpty(false);

        const clinicDetailsWithAddress = {
            clinicname: clinicDetails.clinicname,
            clinicemail: clinicDetails.clinicemail,
            clinicaddress: `${address}, ${city}, ${province}`,
            email: clinicDetails.email,
            numbermobile: clinicDetails.numbermobile,
            password: clinicDetails.password,
            namefirst: clinicDetails.namefirst,
            namemiddle: clinicDetails.namemiddle,
            namelast: clinicDetails.namelast,
            numberprc: clinicDetails.numberprc,
            color: clinicDetails.color
        }

        navigate('/auth/clinic/signup/clinic-contact', { state: { clinicDetailsWithAddress } });
    };

    return (
        <div className="clinic-address-page">
            <div className="clinic-address-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="Where is your clinic?"
                    subheader={`Please enter where ${clinicDetails.clinicname} is`}
                    fields={
                        <div className="clinic-address-container-fields">
                            <Textfield
                                title="Street Address"
                                prompt="This field is required."
                                isEmpty={addressIsEmpty}
                                getValue={setAddress}
                                setValue={address}
                                
                            />
                            <div className="clinic-address-container-fields-bottom">
                                <Textfield
                                    title="City"
                                    prompt="This field is required."
                                    isEmpty={cityIsEmpty}
                                    getValue={setCity}
                                    setValue={city}
                                />
                                <Textfield
                                    title="Province"
                                    prompt="This field is required."
                                    isEmpty={provinceIsEmpty}
                                    getValue={setProvince}
                                    setValue={province}
                                />
                            </div>
                        </div>
                    }
                    options={
                        <div className="clinic-address-container-options">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default ClinicAddress;