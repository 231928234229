import classNames from "classnames";
import { Button, Popover } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TOKEN_NAME } from "./Constant";
import jwtDecode from "jwt-decode";
import { logo_tp, logo_black } from "../../assets";
import globalVariables from "../../globalVariables";
import { useMediaQuery } from "react-responsive";
import { CiMenuBurger } from "react-icons/ci";
import SidebarModal from "./SidebarModal";

function Header({ setIsLoggedOut, userType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tab, setTab] = useState("");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(true);
  const login = localStorage.getItem("login");
  const recievedToken = localStorage.getItem(TOKEN_NAME)

  const isPhone = useMediaQuery({ query: '(max-width: 768px)' });

  const iconClass = classNames("flex justify-between align-center w-[300px] h-[50px] hover:cursor-pointer", isPhone ? "hidden" : "")
  const textClass = classNames("flex", isPhone ? "text-xl" : "text-2xl")
  const burgerClass = classNames(isPhone ? "text-2xl mt-[3px]" : "hidden")

  const [clinicName, setClinicName] = useState('');
  const [adminDentistInformation, setAdminDentistInformation] = useState({});
  const [modal, setModal] = useState(false)

  const changeName = () => {
    if (recievedToken) {
      const token = jwtDecode(recievedToken);
      try {
        fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=dentistheaderonly&id=${token.user}`, {
          method: "GET",
          headers: { "ngrok-skip-browser-warning": true, token: recievedToken },
        }).then((res) => {
          if (!res.ok) {
            console.log("Fetch unsuccessful");
          } else {
            res.json().then((result) => {
              setName(result[0].namefirst);
              setLoading(false);

            });
          }
        });
      } catch (error) {
        console.error("Login failed:", error.message);
      }
    }
  };

  const getClinicName = async () => {
    let clinicID;
    if (recievedToken) {
      const token = jwtDecode(recievedToken);
      try {
        const response = await fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${token.user}`, {
          method: "GET",
          headers: { "ngrok-skip-browser-warning": true, token: recievedToken }
        });

        if (!response.ok) {
          console.log("Fetch dentistinformation unsuccessful");
          return;
        }

        const result = await response.json();
        setAdminDentistInformation(result[0]);
        clinicID = result[0].adminclinicid;

        if(localStorage.getItem("currentUser") === undefined || localStorage.getItem("currentUser") === null) {
          localStorage.setItem("currentUser", token.user);
          localStorage.setItem("currentUserRole", "dentist");
        }

      } catch (error) {
        console.error("Fetch dentistinformation failed:", error.message);
      }
    }

    if (clinicID) {
      try {
        const response = await fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=clinicinformation&id=${clinicID}`, {
          method: "GET",
          headers: { "ngrok-skip-browser-warning": true, token: recievedToken }
        });

        if (!response.ok) {
          console.log("Fetch clinicinformation unsuccessful");
          return;
        }

        const result = await response.json();
        setClinicName(result[0].clinicname);
      } catch (error) {
        console.error("Fetch clinicinformation failed:", error.message);
      }
    }
  };  

  const handleLogout = () => {
    setIsLoggedOut(true);
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserRole");
    localStorage.removeItem("colorblind");
    localStorage.removeItem("userType");
    setAnchorEl(null);
  };

  const handleLogo = () => {
    navigate("/clinic");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const blueBoxStyle = classNames(
    "bg-[#ac7de8] flex justify-between p-5 text-white font-light pl-10 pr-10"
  );

  const textFormatStyle = classNames(isPhone ? "hidden" : "text-4xl");

  useEffect(() => {
    changeName();
    getClinicName();   
  }, [userType === "clinic"]);

  useEffect(() => {
    if (location.pathname.startsWith("/clinic/document")) {
      setTab("Document");
    } else if (location.pathname.startsWith("/clinic/invoice")) {
      setTab("Invoice");
    } else if (location.pathname.startsWith("/clinic/records")) {
      setTab("Records");
    } else if (location.pathname.startsWith("/clinic/accounting")) {
      setTab("Accounting");
    } else if (location.pathname.startsWith("/clinic/settings")) {
      setTab("Settings");
    } else {
      setTab("Home");
    } 
  }, [location])

  function closeSidebar() {
    setModal(false)
  }

  return (
    <div className={blueBoxStyle} >
      <SidebarModal open={modal} onClose={() => setModal(false)} closeModal={closeSidebar}/>
      <div className="w-[150px]">
      <CiMenuBurger className={burgerClass} onClick={() => setModal(true)}/>
        <p className={textFormatStyle}>{tab}</p>
      </div>
      
      <div
        className={iconClass}
        onClick={handleLogo}
      >
        <img className="ml-8" src={logo_black} alt="" />
        <img className="h-[30px] mt-5" src={logo_tp} alt="" />
      </div>
      
      <div className="">
        
        <p className={textClass}>
          {loading === true ? "loading..." : `${clinicName}`}
          <IoIosArrowDown
            id={"dropdownSelector"}
            className="mt-1 ml-1 hover:cursor-pointer hover:text-[#e3d4f7]"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
        </p>
        <Popover
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="w-[200px] grid grid-rows-2">
            <Button color="secondary" onClick={() => {navigate("/clinic/settings"); setAnchorEl(null)}}>Settings</Button>
            <Button id={"logoutButton"} color="secondary" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default Header;
