import React, { useState } from "react";
import './ClinicDetails.css';
import { useLocation, useNavigate } from "react-router-dom";

import validator from "validator";
import { ColoredButton, RegisterContainer, Textfield } from "../../../../../components";

const ClinicDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dentistInformation } = location.state || {};

    const [clinicNameIsEmpty, setClinicNameIsEmpty] = useState(false);
    const [clinicName, setClinicName] = useState('');

    const [emailIsEmpty, setEmailIsEmpty] = useState(false);
    const [email, setEmail] = useState('');
    const [emailPrompt, setEmailPrompt] = useState('This field is required!');

    const next = () => {
        let clinicDetails;

        // check if clinicName is empty
        if(!clinicName.trim().length) return setClinicNameIsEmpty(true);
        else setClinicNameIsEmpty(false);

        // save clinicName to clinicDetails
        clinicDetails = {
            clinicaname: clinicName,
        }

        // check if email is empty
        if(!email.trim().length) {
            setEmailPrompt('This field is required!');
            return setEmailIsEmpty(true);
        } else setEmailIsEmpty(false);

        // check if email is valid: if so, save email to clinicDetails
        if(validator.isEmail(email)) {
            clinicDetails = {
                clinicname: clinicDetails.clinicaname,
                clinicemail: email
            }
            setEmailPrompt('This field is required!');
            setEmailIsEmpty(false);
        } else {
            setEmailPrompt('Entered value is not a valid email.');
            return setEmailIsEmpty(true);
        }

        clinicDetails = {
            clinicname: clinicDetails.clinicname,
            clinicemail: clinicDetails.clinicemail,
            email: dentistInformation.email,
            numbermobile: dentistInformation.numbermobile,
            password: dentistInformation.password,
            namefirst: dentistInformation.namefirst,
            namemiddle: dentistInformation.namemiddle,
            namelast: dentistInformation.namelast,
            numberprc: dentistInformation.numberprc,
            color: dentistInformation.color
        };

        navigate('/auth/clinic/signup/clinic-address', { state: { clinicDetails } });
    };

    return (
        <div className="clinic-details">
            <div className="clinic-details-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="Now, your clinic is?"
                    subheader="Please enter your registered clinic name and email."
                    fields={(
                        <div className="clinic-details-fields-container">
                            <Textfield 
                                title="Registered Clinic Name"
                                prompt="This field is required!"
                                isEmpty={clinicNameIsEmpty}
                                getValue={setClinicName}
                                setValue={clinicName}
                            />
                            <Textfield 
                                title="Clinic Email"
                                prompt={emailPrompt}
                                isEmpty={emailIsEmpty}
                                getValue={setEmail}
                                setValue={email}
                            />
                        </div>
                    )}
                    options={(
                        <div className="clinic-details-options-container">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default ClinicDetails;