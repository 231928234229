import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormGroup,
  Modal,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import globalVariables from "../../../../../globalVariables";
import capitalizeFirstLetter from "../../../../../utils/capitalizeFirstLetter/CapitalizeFirstLetter";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/plugin/isSameOrBefore'
import { LuFileEdit, LuX, LuTrash2 } from "react-icons/lu";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

const DayView = ({
  dayViewDate,
  calendarView,
  highlightCard,
  fulfillAppointmentRequest,
  setFulfillAppointmentRequest,
}) => {
  const currentDate = new Date(dayViewDate);

  const [fulfillRequest, setFulfillRequest] = useState(false);
  const [appointmentRequest, setAppointmentRequest] = useState({});
  const [successfulDeleteNotification, setSuccessfulDeleteNotification] =
    useState(false);
  const [successfulCancelNotification, setSuccessfulCancelNotification] =
    useState(false);
  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] =
    useState(false);

  const [chairs, setChairs] = useState(3);
  const [appointmentCreationModalIsOpen, setAppointmentCreationModalIsOpen] =
    useState(false);
  const [appointmentStartTime, setAppointmentStartTime] = useState(
    dayjs(currentDate)
  );

  const [appointmentChairIsEmpty, setAppointmentChairIsEmpty] = useState("");
  const [appointmentChair, setAppointmentChair] = useState(0);

  const [appointmentCardIsOpen, setAppointmentCardIsOpen] = useState(false);
  const [appointmentCardData, setAppointmentCardData] = useState({});
  const [appointmentCardIsReadOnly, setAppointmentCardIsReadOnly] =
    useState(true);

  const [highlight, setHighlight] = useState(highlightCard);

  const [doctorList, setDoctorList] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);

  const [patientIsEmpty, setPatientIsEmpty] = useState(false);
  const [patient, setPatient] = useState({});
  const [newPatientIsEmpty, setNewPatientIsEmpty] = useState(false);
  const [newPatient, setNewPatient] = useState("");

  const [dentistIsEmpty, setDentistIsEmpty] = useState(false);
  const [dentist, setDentist] = useState({});

  const [dateTimeIsInError, setDateTimeIsInError] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [appointment, setAppointment] = useState([]);

  const [startingTimeInvalid, setStartingTimeInvalid] = useState("");
  const startingTimePrompt = useMemo(() => {
    setDateTimeIsInError(false);
    const today = dayjs().format("MM/DD/YYYY")

    switch (startingTimeInvalid) {
      case "minTime": {
        setDateTimeIsInError(true);
        return "Time cannot be before 8:00 AM";
      }
      case "maxTime": {
        setDateTimeIsInError(true);
        return "Time cannot be after 5:30 PM";
      }
      case "overlappingTime": {
        setDateTimeIsInError(true);
        return "Chosen time overlaps with another appointment";
      }
      case "overlappingDate": {
        setDateTimeIsInError(true);
        return `Appointments cannot be made before ${today}`;
      }
      case "contradictingDoctorTime": {
        if (!Object.keys(dentist).length) {
          setStartingTimeInvalid("");
          setDateTimeIsInError(false);
          return "";
        }
        setDateTimeIsInError(true);
        return `Dr. ${dentist.label} already has an appointment this time`;
      }
      case "contradictingPatientTime": {
        if (!Object.keys(dentist).length) {
          setStartingTimeInvalid("");
          setDateTimeIsInError(false);
          return "";
        }
        setDateTimeIsInError(true);
        return `Patient already has an appointment this time`;
      }
      default: {
        setDateTimeIsInError(false);
        return "";
      }
    }
  }, [startingTimeInvalid, dentist]);
  const [startingTime, setStartingTime] = useState(dayjs(currentDate));

  const [endingTimeInvalid, setEndingTimeInvalid] = useState("");
  const endingTimePrompt = useMemo(() => {
    setDateTimeIsInError(false);
    switch (endingTimeInvalid) {
      case "minDate": {
        setDateTimeIsInError(true);
        return `Date cannot be before ${dayjs(startingTime).format(
          "MMM D, YYYY dddd"
        )}`;
      }
      // case "maxDate": {
      //   setDateTimeIsInError(true);
      //   return `Date cannot be after ${dayjs(startingTime).format(
      //     "MMM D, YYYY dddd"
      //   )}`;
      // }
      case "minTime": {
        setDateTimeIsInError(true);
        return `Time cannot be before ${dayjs(appointmentStartTime).format(
          "hh:mm"
        )}`;
      }
      case "maxTime": {
        setDateTimeIsInError(true);
        return "Time cannot be after 5:30 PM";
      }
      default: {
        setDateTimeIsInError(false);
        return "";
      }
    }
  }, [endingTimeInvalid, startingTime]);
  const [endingTime, setEndingTime] = useState(dayjs(currentDate));

  const [procedureIsEmpty, setProcedureIsEmpty] = useState(false);
  const [procedure, setProcedure] = useState("");

  const [isUnknownPatient, setIsUnknownPatient] = useState(false);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const times = [
    "",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
  ];

  const getDoctorList = useCallback(async () => {
    let request;
    // get dentist id through token
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/auth/verification`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    const dentistid = request.user;

    // get dentist information
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${dentistid}`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    request = request[0];
    const clinicid = request.adminclinicid;

    // get list of dentists associated with this clinic
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&clinicid=${clinicid}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return console.log("...");
    }
    request = await request.json();

    request = request.map((dentist) => {
      const namefirst = capitalizeFirstLetter(dentist.namefirst);
      const namemiddle = capitalizeFirstLetter(dentist.namemiddle);
      const namelast = capitalizeFirstLetter(dentist.namelast);
      return {
        label: `${namefirst} ${namemiddle} ${namelast}`,
        id: dentist.id,
      };
    });

    setDoctorList(request);
  }, []);

  const getPatientList = useCallback(async () => {
    let request;
    // get all patients
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=information`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();

    request = request.map((patient) => {
      const namefirst = capitalizeFirstLetter(patient.namefirst);
      const namemiddle = capitalizeFirstLetter(patient.namemiddle);
      const namelast = capitalizeFirstLetter(patient.namelast);
      return {
        label: `${namefirst} ${namemiddle} ${namelast}`,
        id: patient.id,
      };
    });

    setPatientList(request);
  }, []);

  const getAppointmentList = useCallback(async () => {
    let appointments;
    // get all appointment
    try {
      appointments = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=appointment`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    appointments = await appointments.json();
    appointments = appointments.map(async (appointment) => {
      const appointmentDate = new Date(appointment.timestart);
      if (appointmentDate.toDateString() === currentDate.toDateString()) {
        // get patient name
        let patientName;
        if (appointment.patientid !== 0) {
          try {
            patientName = await fetch(
              `${globalVariables.WEB_ADDRESS_PORT}/?type=information&id=${appointment.patientid}`,
              {
                method: "GET",
                headers: {
                  "ngrok-skip-browser-warning": true,
                  token: localStorage.token,
                  "Content-Type": "application/json",
                },
              }
            );
          } catch (error) {
            console.log("...");
          }
          patientName = await patientName.json();
          patientName = patientName[0];
          // set patientName to patient name
          patientName = {
            name: `${capitalizeFirstLetter(
              patientName.namefirst
            )} ${capitalizeFirstLetter(
              patientName.namemiddle
            )} ${capitalizeFirstLetter(patientName.namelast)}`,
            id: patientName.id,
          };
        } else {
          patientName = { name: `${appointment.newpatientname}`, id: 0 };
        }

        // get dentist name
        let dentistName;
        if (appointment.dentistid !== null) {
          try {
            dentistName = await fetch(
              `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${appointment.dentistid}`,
              {
                method: "GET",
                headers: {
                  "ngrok-skip-browser-warning": true,
                  token: localStorage.token,
                  "Content-Type": "application/json",
                },
              }
            );
          } catch (error) {
            console.log("...");
          }
          dentistName = await dentistName.json();
          dentistName = dentistName[0];
          // set dentistName to dentist name
          dentistName = {
            name: `${capitalizeFirstLetter(
              dentistName.namefirst
            )} ${capitalizeFirstLetter(
              dentistName.namemiddle
            )} ${capitalizeFirstLetter(dentistName.namelast)}`,
            id: dentistName.id,
          };
        } else {
          return;
        }

        // convert starting time
        let timestart = new Date(appointment.timestart);
        // convert starting time to row number
        let rowStart = timestart.getHours() - 7;
        rowStart = rowStart * 2 - 1;
        rowStart = rowStart + timestart.getMinutes() / 30;
        rowStart = rowStart + 1;

        // convert ending time
        let timeend = new Date(appointment.timeend);
        // convert ending time to row end
        let rowEnd = timeend.getHours() - 7;
        rowEnd = rowEnd * 2 - 1;
        rowEnd = rowEnd + timeend.getMinutes() / 30;
        rowEnd = rowEnd + 1;

        return {
          id: appointment.id,
          patient: patientName.name,
          patientid: patientName.id,
          dentist: dentistName.name,
          dentistid: dentistName.id,
          rowStart: rowStart,
          rowEnd: rowEnd,
          procedure: appointment.title,
          color: appointment.color,
          chair: appointment.chair,
          status: appointment.status,
          cancellationreason: appointment.cancellationreason,
          rescheduletimestart: dayjs(appointment.rescheduletimestart),
          rescheduletimeend: dayjs(appointment.rescheduletimeend),
          timestart: dayjs(timestart),
          timeend: dayjs(timeend),
        };
      }
    });
    appointments = await Promise.all(appointments);
    appointments = appointments.filter(
      (appointment) => appointment !== undefined
    );

    return setAppointmentList(appointments);
  }, [currentDate]);

  const onAppointmentCreationHandler = (chair, time) => {
    // get meridiem
    let meridiem = time.split(" ");
    meridiem = meridiem[1];
    // get hour
    let hour = time.split(":");
    hour =
      meridiem === "PM" && hour[0] !== "12"
        ? parseInt(hour[0]) + 12
        : parseInt(hour[0]);
    // get minute
    let minute = time.split(":");
    minute = minute[1];
    minute = minute.split(" ");
    minute = minute[0];
    // format date
    let formattedTime = dayjs(currentDate);
    formattedTime = formattedTime.hour(hour);
    formattedTime = formattedTime.minute(minute);
    formattedTime = formattedTime.second(0);
    formattedTime = formattedTime.millisecond(0);

    // functions
    setAppointmentStartTime(formattedTime);
    setAppointmentChair(chair + 1);
    setAppointmentCreationModalIsOpen(true);
    setStartingTime(formattedTime.toDate());
    setEndingTime(
      formattedTime.minute(parseInt(formattedTime.minute()) + 30).toDate()
    );
  };

  const contradictingTest = (i0, e0, i1, e1) => {
    if (i1 < i0 && i0 < e1 && e1 < e0) return true;
    if (i1 === i0 && i0 < e1 && e1 < e0) return true;
    if (i1 < i0 && e0 < e1) return true;
    if (i0 < i1 && i1 < e0 && e0 < e1) return true;
    if (i0 < i1 && i1 < e0 && e0 === e1) return true;
    if (i0 < i1 && e1 < e0) return true;
    if (i1 < i0 && i1 < e1 && e1 === e0) return true;
    if (i0 === i1 && i1 < e0 && e0 < e1) return true;
    if (i0 === i1 && e0 === e1) return true;
    return false;
  };

  const createAppointment = async () => {
    setStartingTimeInvalid("");
    setDateTimeIsInError(false);
    const today = dayjs().format("MM/DD/YYYY")
    let request;
    // check if patient is empty
    if (!Object.keys(patient).length) setPatientIsEmpty(true);
    else setPatientIsEmpty(false);
    // check if new patient is empty
    if (!newPatient.trim().length) setNewPatientIsEmpty(true);
    else setNewPatientIsEmpty(false);
    // check if dentist is empty
    if (!Object.keys(dentist).length) setDentistIsEmpty(true);
    else setDentistIsEmpty(false);
    // check if procedure is empty
    if (!procedure.trim().length) setProcedureIsEmpty(true);
    else setProcedureIsEmpty(false);
    // check if need to return
    if (
      isUnknownPatient
        ? !newPatient.trim().length
        : !Object.keys(patient).length ||
          !Object.keys(dentist).length ||
          !procedure.trim().length ||
          startingTimeInvalid ||
          endingTimeInvalid
    )
      return;
    // get dentist color
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${dentist.id}`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    request = request[0];
    const dentistColor = request.color;
    // get formatted starting time
    const formattedStartingTime = new Date(startingTime).toISOString();
    // get formatted ending time
    const formattedEndingTime = new Date(endingTime).toISOString();
    // create object
    const newAppointment = {
      patientid: isUnknownPatient ? 0 : patient.id,
      dentistid: dentist.id,
      newpatientname: isUnknownPatient ? newPatient : "",
      timestart: formattedStartingTime,
      timeend: formattedEndingTime,
      title: procedure,
      status: "pending",
      chair: appointmentChair,
      color: dentistColor,
    };

    // check if new appointment is contradicting another
    let appointments;
    // get all appointment
    try {
      appointments = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=appointment`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    appointments = await appointments.json();

    appointments = appointments.map((appointment) => {
      if (appointment.chair === newAppointment.chair) {
        if (
          contradictingTest(
            appointment.timestart,
            appointment.timeend,
            newAppointment.timestart,
            newAppointment.timeend
          )
        ) {
          return "overlappingTime";
        }
      } else {
        if (appointment.dentistid === newAppointment.dentistid) {
          if (
            contradictingTest(
              appointment.timestart,
              appointment.timeend,
              newAppointment.timestart,
              newAppointment.timeend
            )
          ) {
            return "contradictingDoctorTime";
          }
        }
        if (appointment.patientid === newAppointment.patientid) {
          if (
            contradictingTest(
              appointment.timestart,
              appointment.timeend,
              newAppointment.timestart,
              newAppointment.timeend
            )
          ) {
            return "contradictingPatientTime";
          }
        }
      } 
    });
    if (appointments.includes("overlappingTime")) {
      setStartingTimeInvalid("overlappingTime");
      return setDateTimeIsInError(true);
    } else if (appointments.includes("contradictingDoctorTime")) {
      setStartingTimeInvalid("contradictingDoctorTime");
      return setDateTimeIsInError(true);
    } else if (appointments.includes("contradictingPatientTime")) {
      setStartingTimeInvalid("contradictingPatientTime");
      return setDateTimeIsInError(true);
    } else {
      setStartingTimeInvalid("");
      setDateTimeIsInError(false);
    }
    const timestartToDate = dayjs(newAppointment.timestart).format("MM/DD/YYYY")
    const timeendToDate = dayjs(newAppointment.timeend).format("MM/DD/YYYY")
    if(dayjs(timestartToDate).isBefore(today) || dayjs(timeendToDate).isBefore(today) ){
      setStartingTimeInvalid("overlappingDate");
      return setDateTimeIsInError(true);
    }
    // store appointment
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=postappointment`,
        {
          method: "POST",
          body: JSON.stringify(newAppointment),
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }

    // clear values
    setPatientIsEmpty(false);
    setPatient({});
    setNewPatientIsEmpty(false);
    setNewPatient("");
    setIsUnknownPatient(false);
    setDentistIsEmpty(false);
    setDentist({});
    setStartingTimeInvalid(false);
    setEndingTimeInvalid(false);
    setProcedureIsEmpty(false);
    setProcedure("");
    setAppointmentChair(0);
    setDateTimeIsInError(false);
    return setAppointmentCreationModalIsOpen(false);
  };

  const updateAppointment = async () => {
    setStartingTimeInvalid("");
    setDateTimeIsInError(false);
    const today = dayjs().format("MM/DD/YYYY")
    let request;
    // check if patient is empty
    if (!Object.keys(patient).length) setPatientIsEmpty(true);
    else setPatientIsEmpty(false);
    // check if new patient is empty
    if (!newPatient.trim().length) setNewPatientIsEmpty(true);
    else setNewPatientIsEmpty(false);
    // check if dentist is empty
    if (!Object.keys(dentist).length) setDentistIsEmpty(true);
    else setDentistIsEmpty(false);
    // check if procedure is empty
    if (!procedure.trim().length) setProcedureIsEmpty(true);
    else setProcedureIsEmpty(false);
    // check if chair is empty
    if (!appointmentChair) setAppointmentChairIsEmpty(true);
    else setAppointmentChairIsEmpty(false);
    // check if need to return
    if (
      isUnknownPatient
        ? !newPatient.trim().length
        : !Object.keys(patient).length ||
          !Object.keys(dentist).length ||
          !procedure.trim().length ||
          !appointmentChair
    )
      return;
    // get dentist color

    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${dentist.id}`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    request = request[0];
    const dentistColor = request.color;
    // get formatted starting time
    const formattedStartingTime = new Date(startingTime).toISOString();
    // get formatted ending time
    const formattedEndingTime = new Date(endingTime).toISOString();
    // create object
    const newAppointment = {
      patientid: patient.id,
      dentistid: dentist.id,
      newpatientname: patient.label,
      timestart: formattedStartingTime,
      timeend: formattedEndingTime,
      title: procedure,
      status: "pending",
      chair: appointmentChair,
      color: dentistColor,
    };

    // check if new appointment is contradicting another
    let appointments;
    // get all appointment
    try {
      appointments = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=appointment`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    appointments = await appointments.json();

    appointments = appointments.map((appointment) => {
      if (appointment.id !== appointmentCardData.id) {
        if (appointment.chair === newAppointment.chair) {
          contradictingTest(
            appointment.timestart,
            appointment.timeend,
            newAppointment.timestart,
            newAppointment.timeend
          );
          if (
            contradictingTest(
              appointment.timestart,
              appointment.timeend,
              newAppointment.timestart,
              newAppointment.timeend
            )
          ) {
            return "overlappingTime";
          }
        } else {
          if (appointment.dentistid === newAppointment.dentistid) {
            if (
              contradictingTest(
                appointment.timestart,
                appointment.timeend,
                newAppointment.timestart,
                newAppointment.timeend
              )
            ) {
              return "contradictingTime";
            }
          }
        }
      }
    });

    if (appointments.includes("overlappingTime")) {
      setStartingTimeInvalid("overlappingTime");
      return setDateTimeIsInError(true);
    } else if (appointments.includes("contradictingTime")) {
      setStartingTimeInvalid("contradictingTime");
      return setDateTimeIsInError(true);
    } else {
      setStartingTimeInvalid("");
      setDateTimeIsInError(false);
    }

    if (startingTimeInvalid || endingTimeInvalid) return;

    const timestartToDate = dayjs(newAppointment.timestart).format("MM/DD/YYYY")
    const timeendToDate = dayjs(newAppointment.timeend).format("MM/DD/YYYY")
    if(dayjs(timestartToDate).isBefore(today) || dayjs(timeendToDate).isBefore(today) ){
      setStartingTimeInvalid("overlappingDate");
      return setDateTimeIsInError(true);
    }

    // store appointment
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=updateappointment&id=${
          appointmentCardIsOpen ? appointmentCardData.id : appointmentRequest.id
        }`,
        {
          method: "PATCH",
          body: JSON.stringify(newAppointment),
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }

    // clear values
    setPatientIsEmpty(false);
    setPatient({});
    setDentistIsEmpty(false);
    setDentist({});
    setStartingTimeInvalid(false);
    setEndingTimeInvalid(false);
    setProcedureIsEmpty(false);
    setProcedure("");
    setAppointmentChairIsEmpty(false);
    setAppointmentChair(0);
    setDateTimeIsInError(false);
    setAppointmentCardIsReadOnly(true);
    setAppointmentCardIsOpen(false);
    setFulfillAppointmentRequest({
      fulfill: false,
      appointmentRequest: {
        patientName: "",
        purpose: "",
        status: "no appointment",
      },
    });
    return setFulfillRequest(false);
  };

  const deleteAppointment = async (method) => {
    let request;
    // store appointment
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=deleteappointment&id=${
          appointmentCardIsOpen ? appointmentCardData.id : appointmentRequest.id
        }`,
        {
          method: "DELETE",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();

    // clear values
    setPatientIsEmpty(false);
    setPatient({});
    setDentistIsEmpty(false);
    setDentist({});
    setStartingTimeInvalid(false);
    setEndingTimeInvalid(false);
    setProcedureIsEmpty(false);
    setProcedure("");
    setAppointmentChairIsEmpty(false);
    setAppointmentChair(0);
    setDateTimeIsInError(false);
    setAppointmentCardIsReadOnly(true);
    setAppointmentCardIsOpen(false);
    setFulfillAppointmentRequest({
      fulfill: false,
      appointmentRequest: {
        patientName: "",
        purpose: "",
        status: "no appointment",
      },
    });
    setFulfillRequest(false);
    setDeleteConfirmationModalIsOpen(false);
    if (method === "cancel") {
      return setSuccessfulCancelNotification(true);
    } else {
      return setSuccessfulDeleteNotification(true);
    }
  };

  useEffect(() => {
    calendarView("day");
    setFulfillRequest(fulfillAppointmentRequest.fulfill);
    setAppointmentRequest(fulfillAppointmentRequest.appointmentRequest);
    getDoctorList();
    getPatientList();
    getAppointmentList();
  }, [
    appointmentStartTime,
    appointmentCreationModalIsOpen,
    appointmentCardIsOpen,
    dayViewDate,
    highlightCard,
    fulfillAppointmentRequest,
  ]);

  useEffect(() => {
    const ar = fulfillAppointmentRequest.appointmentRequest;
    //.minute(parseInt(formattedTime.minute()) + 30).toDate()
    if (fulfillAppointmentRequest.fulfill) {
      setPatient({ label: ar.patientName, id: ar.patientID });
      setStartingTime(ar.timestart);
      setEndingTime(dayjs(ar.timestart).add(30, "minute"));
      setProcedure(ar.purpose);
      setAppointmentChair(1);
    }
  }, [fulfillAppointmentRequest]);

  function sendCancel() {
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=requestcancellation&patientid=${patient.id}&reason=${cancelReason}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      ).catch((error) => {
        console.error("Error fetching data:", error);
      });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  return (
    <div
      className="relative grid grid-flow-row max-h-[74vh] overflow-y-auto pr-[10px]"
      style={{ gridTemplateColumns: `100px repeat(${chairs},minmax(0,1fr))` }}
    >
      <Modal open={appointmentCardIsOpen}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            width: isPhone ? "90%" : "40%",
            boxShadow: 24,
            borderRadius: "10px",
          }}
        >
          <div
            className="flex flex-col p-[15px]"
            style={{
              backgroundColor: appointmentCardData.color,
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <div className="flex flex-row items-center place-content-between">
              <div className="flex flex-col gap-y-[7px]">
                <div className="font-bold text-xl">
                  {`${appointmentCardData.procedure} for ${appointmentCardData.patient}`}
                </div>
              </div>
              <div className="flex flex-row items-center">
                {appointmentCardData.status === "cancelled" ? (
                  ""
                ) : (
                  <Tooltip title="Cancel Appointment" placement="top" arrow>
                    <div className="relative w-[30px] h-[20px]">
                      <MdOutlineFreeCancellation
                        className="absolute right-0 inset-y-0 w-[20px] h-[20px] hover:stroke-[0.5] active:w-[18px] cursor-pointer"
                        onClick={() => setCancelModal(true)}
                      />
                    </div>
                  </Tooltip>
                )}
                <Tooltip title="Edit Appointment" placement="top" arrow>
                  <div className="relative w-[30px] h-[20px]">
                    <LuFileEdit
                      className="absolute right-0 inset-y-0 w-[20px] h-[20px] hover:stroke-[2.5] active:w-[18px] cursor-pointer"
                      onClick={() => setAppointmentCardIsReadOnly(false)}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Delete" placement="top" arrow>
                  <div className="relative w-[30px] h-[20px]">
                    <LuTrash2
                      className="absolute right-0 inset-y-0 w-[20px] h-[20px] hover:stroke-[2.5] active:w-[18px] cursor-pointer"
                      onClick={() => setDeleteConfirmationModalIsOpen(true)}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Close" placement="top" arrow>
                  <div className="relative w-[30px] h-[20px]">
                    <LuX
                      className="absolute right-0 inset-y-0 w-[20px] h-[20px] hover:stroke-[2.5] active:w-[18px] cursor-pointer"
                      onClick={() => {
                        setPatientIsEmpty(false);
                        setPatient({});
                        setDentistIsEmpty(false);
                        setDentist({});
                        setStartingTimeInvalid(false);
                        setEndingTimeInvalid(false);
                        setProcedureIsEmpty(false);
                        setProcedure("");
                        setAppointmentChairIsEmpty(false);
                        setAppointmentChair(0);
                        setDateTimeIsInError(false);
                        setAppointmentCardIsReadOnly(true);
                        return setAppointmentCardIsOpen(false);
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="mt-[10px] pt-[5px] pb-[5px] pr-[7px] pl-[10px] w-fit bg-white rounded-full">
              <div className="relative flex flex-row w-max item-center">
                <div className="mt-[-3px] mr-[22px] left-0">{`${appointmentCardData.status}`}</div>
                <div
                  className="absolute right-0 top-[50%] bottom-[50%] translate-y-[-50%] w-[15px] h-[15px] rounded-full "
                  style={{
                    backgroundColor:
                      appointmentCardData.status === "requesting appointment"
                        ? "orange"
                        : appointmentCardData.status ===
                          "requesting cancellation"
                        ? "red"
                        : appointmentCardData.status === "active"
                        ? "green"
                        : appointmentCardData.status === "pending"
                        ? "#ff0090"
                        : appointmentCardData.status === "done"
                        ? "#00d0ff"
                        : "orange",
                  }}
                />
              </div>
            </div>

            {appointmentCardData.status === "cancelled" ? (
              <div className="mt-[10px] pt-[5px] pb-[5px] pr-[7px] pl-[10px] w-fit bg-white rounded-full">
                <div className="relative flex flex-row w-max item-center">
                  <div className="mt-[-3px] mr-[22px] left-0">{`Reason: ${appointmentCardData.cancellationreason}`}</div>
                </div>
              </div>
            ) : (
              ""
            )}

{appointmentCardData.status === "rescheduling" ? (
  <div className="mt-[10px] pt-[5px] pb-[5px] pr-[7px] pl-[10px] w-fit bg-white rounded-full">
    <div className="relative flex flex-row w-max item-center">
      <div className="mt-[-3px] mr-[22px] left-0">{`Schedule: ${dayjs(appointmentCardData.rescheduletimestart).format("MM/DD/YYYY hh:mm A")}-${dayjs(appointmentCardData.rescheduletimeend).format("MM/DD/YYYY hh:mm A")}`}</div>
    </div>
  </div>
) : (
  ""
)}

          </div>
          <div className="p-[15px]">
            {patient.id !== 0 ? (
              <Autocomplete
                id="select-patient"
                options={patientList}
                getOptionLabel={(patient) => patient.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{
                  width: "100%",
                  paddingTop: "15px",
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    defaultValue={appointmentCardData.patient}
                    error={patientIsEmpty}
                    {...params}
                    label="Patient"
                    helperText={patientIsEmpty ? "Cannot be empty" : ""}
                  />
                )}
                defaultValue={patientList.find(
                  (patient) => patient.label === appointmentCardData.patient
                )}
                readOnly={appointmentCardIsReadOnly}
                onChange={(event, value) => {
                  if (value === null) return setPatient({});
                  else return setPatient(value);
                }}
              />
            ) : (
              <TextField
                required
                error={patientIsEmpty}
                helperText={patientIsEmpty ? "Cannot be empty" : ""}
                value={patient.label}
                id="NewPatient"
                label="New Patient"
                sx={{
                  width: "100%",
                  marginTop: "15px",
                }}
                inputProps={{
                  readOnly: appointmentCardIsReadOnly,
                }}
                onChange={(event) =>
                  setPatient({ label: event.target.value, id: 0 })
                }
              />
            )}
            <Autocomplete
              id="select-doctor"
              options={doctorList}
              getOptionLabel={(doctor) => doctor.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "15px",
              }}
              renderInput={(params) => (
                <TextField
                  required
                  defaultValue={appointmentCardData.dentist}
                  error={dentistIsEmpty}
                  {...params}
                  label="Doctor"
                  helperText={dentistIsEmpty ? "Cannot be empty" : ""}
                />
              )}
              defaultValue={doctorList.find(
                (dentist) => dentist.label === appointmentCardData.dentist
              )}
              readOnly={appointmentCardIsReadOnly}
              onChange={(event, value) => {
                if (value === null) return setDentist({});
                else return setDentist(value);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div
                className="select-date-time"
                class={`flex flex-row gap-x-[15px] pt-[15px]`}
              >
                <DateTimePicker
                  onError={setStartingTimeInvalid}
                  minDate={appointmentCardData.timestart}
                  minTime={appointmentCardData.timestart}
                  
                  maxTime={dayjs(appointmentCardData.timestart)
                    .hour(17)
                    .minute(30)}
                  minutesStep={30}
                  defaultValue={appointmentCardData.timestart}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    second: null,
                  }}
                 
                  sx={{
                    width: `100%`,
                  }}
                  slotProps={{
                    textField: {
                      error: dateTimeIsInError,
                      helperText: startingTimePrompt,
                    },
                  }}
                  id="select-starting-time"
                  label="Starting Time"
                  readOnly={appointmentCardIsReadOnly}
                  onChange={(event) => {
                    setAppointmentStartTime(event);
                    return setStartingTime(event.toDate());
                  }}
                />
                <DateTimePicker
                  onError={setEndingTimeInvalid}
                  minDate={appointmentCardData.timestart}
                  minTime={appointmentCardData.timestart}
                  maxTime={dayjs(appointmentCardData.timestart)
                    .hour(17)
                    .minute(30)}
                  minutesStep={30}
                  defaultValue={appointmentCardData.timeend}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    second: null,
                  }}
                  sx={{
                    width: `100%`,
                  }}
                  slotProps={{
                    textField: {
                      error: dateTimeIsInError,
                      helperText: endingTimePrompt,
                    },
                  }}
                  id="select-ending-time"
                  label="Ending Time"
                  readOnly={appointmentCardIsReadOnly}
                  onChange={(event) => {
                    return setEndingTime(event.toDate());
                  }}
                />
              </div>
            </LocalizationProvider>
            <div className="flex flex-row mt-[15px] gap-x-[15px]">
              <TextField
                required
                error={procedureIsEmpty}
                helperText={procedureIsEmpty ? "Cannot be empty" : ""}
                id="procedure"
                label="Procedure"
                sx={{ width: "75%" }}
                defaultValue={appointmentCardData.procedure}
                inputProps={{
                  readOnly: appointmentCardIsReadOnly,
                }}
                onChange={(event) => setProcedure(event.target.value)}
              />
              <Autocomplete
                id="select-chair"
                options={[
                  { label: "1", id: 1 },
                  { label: "2", id: 2 },
                  { label: "3", id: 3 },
                ]}
                getOptionLabel={(chair) => chair.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: "25%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    error={appointmentChairIsEmpty}
                    {...params}
                    label="Chair"
                    helperText={
                      appointmentChairIsEmpty ? "Cannot be empty" : ""
                    }
                  />
                )}
                defaultValue={{
                  label: `${appointmentCardData.chair}`,
                  id: parseInt(appointmentCardData.chair),
                }}
                readOnly={appointmentCardIsReadOnly}
                onChange={(event, value) => {
                  if (value === null) return setAppointmentChair(0);
                  else return setAppointmentChair(value.id);
                }}
              />
            </div>
            {appointmentCardIsReadOnly ? (
              ""
            ) : (
              <div
                className="buttons-container"
                class={`flex flex-row-reverse gap-x-[15px] pt-[15px]`}
              >
                <Button variant={"contained"} onClick={updateAppointment}>
                  <p>Save</p>
                </Button>
                <Button onClick={() => setAppointmentCardIsReadOnly(true)}>
                  <p>Cancel</p>
                </Button>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <Modal open={appointmentCreationModalIsOpen}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            width: isPhone ? "90%" : "40%",
            boxShadow: 24,
            borderRadius: "10px",
            padding: "15px",
          }}
        >
          <div className="flex flex-row place-items-center">
            <div
              className="appointment-creation-title"
              class={`font-bold text-xl`}
            >
              {`Create Appointment at Chair ${appointmentChair}`}
            </div>
            <div className="flex flex-row place-items-center ml-[15px]">
              <Switch
                onChange={(event) => setIsUnknownPatient(event.target.checked)}
              />
              <div>New patient?</div>
            </div>
          </div>
          {isUnknownPatient ? (
            <TextField
              required
              error={newPatientIsEmpty}
              helperText={newPatientIsEmpty ? "Cannot be empty" : ""}
              id="NewPatient"
              label="New Patient"
              sx={{
                width: "100%",
                marginTop: "15px",
              }}
              onChange={(event) => setNewPatient(event.target.value)}
            />
          ) : (
            <Autocomplete
              id="select-patient"
              options={patientList}
              getOptionLabel={(patient) => patient.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "15px",
              }}
              renderInput={(params) => (
                <TextField
                  required
                  error={patientIsEmpty}
                  {...params}
                  label="Patient"
                  helperText={patientIsEmpty ? "Cannot be empty" : ""}
                />
              )}
              onChange={(event, value) => {
                if (value === null) return setPatient({});
                else return setPatient(value);
              }}
            />
          )}
          <Autocomplete
            id="select-doctor"
            options={doctorList}
            getOptionLabel={(doctor) => doctor.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{
              width: "100%",
              paddingTop: "15px",
            }}
            renderInput={(params) => (
              <TextField
                required
                error={dentistIsEmpty}
                {...params}
                label="Doctor"
                helperText={dentistIsEmpty ? "Cannot be empty" : ""}
              />
            )}
            onChange={(event, value) => {
              if (value === null) return setDentist({});
              else return setDentist(value);
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              className="select-date-time"
              class={`flex flex-row gap-x-[15px] pt-[15px]`}
            >
              <DateTimePicker
                onError={setStartingTimeInvalid}
                minDate={appointmentStartTime.date(
                  parseInt(appointmentStartTime.date()) - 1
                )}
                minTime={appointmentStartTime.hour(7).minute(59)}
                maxTime={appointmentStartTime.hour(17).minute(30)}
                minutesStep={30}
                defaultValue={appointmentStartTime}
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  second: null,
                }}
                sx={{
                  width: `100%`,
                }}
                slotProps={{
                  textField: {
                    error: dateTimeIsInError,
                    helperText: startingTimePrompt,
                  },
                }}
                id="select-starting-time"
                label="Starting Time"
                onChange={(event) => {
                  setAppointmentStartTime(event);
                  return setStartingTime(event.toDate());
                }}
              />
              <DateTimePicker
                onError={setEndingTimeInvalid}
                minDate={appointmentStartTime}
                minTime={appointmentStartTime}
                maxTime={appointmentStartTime.hour(17).minute(30)}
                minutesStep={30}
                defaultValue={appointmentStartTime.minute(
                  parseInt(appointmentStartTime.minute()) + 30
                )}
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  second: null,
                }}
                sx={{
                  width: `100%`,
                }}
                slotProps={{
                  textField: {
                    error: dateTimeIsInError,
                    helperText: endingTimePrompt,
                  },
                }}
                id="select-ending-time"
                label="Ending Time"
                onChange={(event) => {
                  return setEndingTime(event.toDate());
                }}
              />
            </div>
          </LocalizationProvider>
          <TextField
            required
            error={procedureIsEmpty}
            helperText={procedureIsEmpty ? "Cannot be empty" : ""}
            id="procedure"
            label="Procedure"
            sx={{
              width: "100%",
              marginTop: "15px",
            }}
            onChange={(event) => setProcedure(event.target.value)}
          />
          <div
            className="buttons-container"
            class={`flex flex-row-reverse gap-x-[15px] pt-[15px]`}
          >
            <Button variant={"contained"} onClick={createAppointment}>
              <p>Create Appointment</p>
            </Button>
            <Button
              onClick={() => {
                setPatientIsEmpty(false);
                setPatient({});
                setNewPatientIsEmpty(false);
                setNewPatient("");
                setIsUnknownPatient(false);
                setDentistIsEmpty(false);
                setDentist({});
                setStartingTimeInvalid(false);
                setEndingTimeInvalid(false);
                setProcedureIsEmpty(false);
                setProcedure("");
                setAppointmentChair(0);
                setDateTimeIsInError(false);
                return setAppointmentCreationModalIsOpen(false);
              }}
            >
              <p>Cancel</p>
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={fulfillRequest}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            width: isPhone ? "90%" : "40%",
            boxShadow: 24,
            borderRadius: "10px",
          }}
        >
          <div
            className="flex flex-col p-[15px]"
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <div className="flex flex-row items-center place-content-between">
              <div className="flex flex-col gap-y-[7px]">
                <div className="font-bold text-xl">
                  {`${appointmentRequest.purpose} Request from ${appointmentRequest.patientName}`}
                </div>
              </div>
            </div>
            <div className="mt-[10px] pt-[5px] pb-[5px] pr-[7px] pl-[10px] w-fit bg-white rounded-full">
              <div className="relative flex flex-row w-max item-center">
                <div className="mt-[-3px] mr-[22px] left-0">{`status: ${appointmentRequest.status}`}</div>
                <div
                  className="absolute right-0 top-[50%] bottom-[50%] translate-y-[-50%] w-[15px] h-[15px] rounded-full "
                  style={{
                    backgroundColor:
                      appointmentRequest.status === "requesting appointment"
                        ? "orange"
                        : appointmentRequest.status ===
                          "requesting cancellation"
                        ? "red"
                        : appointmentRequest.status === "active"
                        ? "green"
                        : appointmentRequest.status === "pending"
                        ? "#ff0090"
                        : appointmentRequest.status === "done"
                        ? "#00d0ff"
                        : "orange",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-[15px]">
            <Autocomplete
              id="select-patient"
              options={patientList}
              getOptionLabel={(patient) => patient.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "15px",
              }}
              renderInput={(params) => (
                <TextField
                  required
                  defaultValue={appointmentRequest.patientName}
                  error={patientIsEmpty}
                  {...params}
                  label="Patient"
                  helperText={patientIsEmpty ? "Cannot be empty" : ""}
                />
              )}
              defaultValue={patientList.find(
                (patient) => patient.label === appointmentRequest.patientName
              )}
              onChange={(event, value) => {
                if (value === null) return setPatient({});
                else return setPatient(value);
              }}
            />
            <Autocomplete
              id="select-doctor"
              options={doctorList}
              getOptionLabel={(doctor) => doctor.label}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "15px",
              }}
              renderInput={(params) => (
                <TextField
                  required
                  error={dentistIsEmpty}
                  {...params}
                  label="Doctor"
                  helperText={dentistIsEmpty ? "Cannot be empty" : ""}
                />
              )}
              onChange={(event, value) => {
                if (value === null) return setDentist({});
                else return setDentist(value);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div
                className="select-date-time"
                class={`flex flex-row gap-x-[15px] pt-[15px]`}
              >
                <DateTimePicker
                  onError={setStartingTimeInvalid}
                  minDate={dayjs()}
                  minTime={dayjs().hour(7).minute(59)}
                  maxTime={dayjs().hour(17).minute(30)}
                  minutesStep={30}
                  defaultValue={dayjs(startingTime)}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    second: null,
                  }}
                  sx={{
                    width: `100%`,
                  }}
                  slotProps={{
                    textField: {
                      error: dateTimeIsInError,
                      helperText: startingTimePrompt,
                    },
                  }}
                  id="select-starting-time"
                  label="Starting Time"
                  onChange={(event) => {
                    setAppointmentStartTime(event);
                    return setStartingTime(event.toDate());
                  }}
                />
                <DateTimePicker
                  onError={setEndingTimeInvalid}
                  minDate={dayjs()}
                  minTime={dayjs().hour(7).minute(59)}
                  maxTime={dayjs().hour(17).minute(30)}
                  minutesStep={30}
                  defaultValue={dayjs(endingTime)}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    second: null,
                  }}
                  sx={{
                    width: `100%`,
                  }}
                  slotProps={{
                    textField: {
                      error: dateTimeIsInError,
                      helperText: endingTimePrompt,
                    },
                  }}
                  id="select-ending-time"
                  label="Ending Time"
                  onChange={(event) => {
                    return setEndingTime(event.toDate());
                  }}
                />
              </div>
            </LocalizationProvider>
            <div className="flex flex-row mt-[15px] gap-x-[15px]">
              <TextField
                required
                error={procedureIsEmpty}
                helperText={procedureIsEmpty ? "Cannot be empty" : ""}
                id="procedure"
                label="Procedure"
                sx={{ width: "75%" }}
                defaultValue={appointmentRequest.purpose}
                onChange={(event) => setProcedure(event.target.value)}
              />
              <Autocomplete
                id="select-chair"
                options={[
                  { label: "1", id: 1 },
                  { label: "2", id: 2 },
                  { label: "3", id: 3 },
                ]}
                getOptionLabel={(chair) => chair.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: "25%" }}
                renderInput={(params) => (
                  <TextField
                    required
                    error={appointmentChairIsEmpty}
                    {...params}
                    label="Chair"
                    helperText={
                      appointmentChairIsEmpty ? "Cannot be empty" : ""
                    }
                  />
                )}
                defaultValue={{ label: `1`, id: 1 }}
                onChange={(event, value) => {
                  if (value === null) return setAppointmentChair(0);
                  else return setAppointmentChair(value.id);
                }}
              />
            </div>
            <div
              className="buttons-container"
              class={`flex flex-row-reverse gap-x-[15px] pt-[15px]`}
            >
              <Button variant={"contained"} onClick={updateAppointment}>
                <p>Save</p>
              </Button>
              <Button
                variant={"contained"}
                onClick={() => setCancelModal(true)}
              >
                <p>Cancel</p>
              </Button>
              <Button
                onClick={() => {
                  setPatientIsEmpty(false);
                  setPatient({});
                  setDentistIsEmpty(false);
                  setDentist({});
                  setStartingTimeInvalid(false);
                  setEndingTimeInvalid(false);
                  setProcedureIsEmpty(false);
                  setProcedure("");
                  setAppointmentChairIsEmpty(false);
                  setAppointmentChair(0);
                  setDateTimeIsInError(false);
                  setFulfillAppointmentRequest({
                    fulfill: false,
                    appointmentRequest: {
                      id: null,
                      patientID: null,
                      patientName: "",
                      purpose: "",
                      status: "no appointment",
                    },
                  });
                  return setFulfillRequest(false);
                }}
              >
                <p>Close</p>
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={deleteConfirmationModalIsOpen}>
        <Box
          component="form"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "0",
            backgroundColor: "white",
            padding: "15px",
            boxShadow: 24,
            borderRadius: "10px",
          }}
        >
          <div className="flex flex-col">
            <div className="pb-[15px]">
              Are you sure you want to delete this appointment?
            </div>
            <div className="flex flex-row-reverse gap-[15px]">
              <Button
                variant="contained"
                onClick={deleteAppointment}
                color="error"
              >
                Delete
              </Button>
              <Button onClick={() => setDeleteConfirmationModalIsOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal open={cancelModal} onClose={() => setCancelModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            width: isPhone ? "70%" : "30%",
            height: "300px",
            padding: "16px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0.375rem",
          }}
        >
          What is your reason for cancellation?
          <TextField
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            value={cancelReason}
            onChange={(event) => setCancelReason(event.target.value)}
          />
          <div className="flex justify-between w-[200px]">
            <Button
              variant={"contained"}
              onClick={() => {
                sendCancel();
                setCancelModal(false);
                deleteAppointment("cancel");
              }}
            >
              <p>Submit</p>
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                setCancelModal(false);
                setCancelReason("");
              }}
            >
              <p>Cancel</p>
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={successfulDeleteNotification}
        autoHideDuration={6000}
        onClose={() => setSuccessfulDeleteNotification(false)}
      >
        <Alert
          autoHideDuration={6000}
          onClose={() => setSuccessfulDeleteNotification(false)}
          severity="success"
          variant="filled"
        >
          Appointment deleted
        </Alert>
      </Snackbar>
      <Snackbar
        open={successfulCancelNotification}
        autoHideDuration={6000}
        onClose={() => setSuccessfulCancelNotification(false)}
      >
        <Alert
          autoHideDuration={6000}
          onClose={() => setSuccessfulCancelNotification(false)}
          severity="success"
          variant="filled"
        >
          Appointment Cancelled
        </Alert>
      </Snackbar>
      {times.map((time, i) => (
        <Fragment>
          <div
            key={i}
            className="day-view-time"
            class={`grid col-start-1 text-right h-[50px] pr-[10px]`}
            style={{
              borderBottomWidth: i !== 20 ? `2px` : `0px`,
            }}
          >
            <div
              className="day-view-time-text"
              class={`-translate-y-[13px] bg-white pr-[5px]`}
            >
              {time}
            </div>
          </div>
          {[...Array(chairs)].map((_, j) => (
            <div
              key={j}
              className="day-view-cell"
              class={`grid text-center h-[50px] content-center hover:bg-gray-100 active:bg-gray-50 z-0`}
              style={{
                gridColumnStart: j + 2,
                borderBottomWidth: i !== 20 ? `2px` : `0px`,
                borderLeftWidth: i !== 20 ? `2px` : `2px`,
                pointerEvents: i === 0 || i === 20 ? `none` : ``,
              }}
              onClick={() => {
                onAppointmentCreationHandler(j, time);
              }}
            >
              {i === 0 ? (
                <div class="text-lg font-bold">{`Chair ${j + 1}`}</div>
              ) : (
                ""
              )}
            </div>
          ))}
        </Fragment>
      ))}
      {appointmentList.map((appointment, i) => (
        <Fragment>
          <div
            key={i}
            className={`absolute flex flex-row rounded-md p-[5px] z-10 translate-x-[2px] hover:cursor-pointer overflow-hidden`}
            style={{
              height: `calc((48px * (${appointment.rowEnd} - ${appointment.rowStart})) + (2px * (${appointment.rowEnd} - ${appointment.rowStart})))`,
              width: `calc((100% / (${chairs} - ${appointment.chair} + 1)) - (30px / (${chairs} - ${appointment.chair} + 1))`,
              backgroundColor: appointment.color,
              filter:
                appointment.status === "requesting cancellation"
                  ? "brightness(80%)"
                  : "",
              gridColumnStart: `calc(${appointment.chair} + 1)`,
              gridRow: `${appointment.rowStart} / ${appointment.rowEnd}`,
              borderWidth: highlight.id === appointment.id ? `2px` : ``,
              borderColor: highlight.id === appointment.id ? `black` : ``,
            }}
            onClick={() => {
              setPatient({
                label: appointment.patient,
                id: appointment.patientid,
              });
              setDentist({
                label: appointment.dentist,
                id: appointment.dentistid,
              });
              setStartingTime(dayjs(appointment.timestart).toDate());
              setEndingTime(dayjs(appointment.timeend).toDate());
              setProcedure(appointment.procedure);
              setAppointmentChair(appointment.chair);
              setAppointmentCardIsOpen(true);
              setHighlight({ id: 0 });
              return setAppointmentCardData(appointment);
            }}
          >
            <div className="appointment-card-patient-details">
              <div
                className="appointment-card-patient-name"
                class={`flex flex-row text-sm gap-x-[5px] mr-[15px]`}
              >
                <div class={`font-bold text-gray-500`}>{`Patient:`}</div>
                <div>{appointment.patient}</div>
              </div>
              <div
                className="appointment-card-patient-procedure"
                class={`flex flex-row text-sm gap-x-[5px] mr-[15px]`}
              >
                <div class={`font-bold text-gray-500`}>{`Procedure:`}</div>
                <div>{appointment.procedure}</div>
              </div>
              {appointment.status === "cancelled" ? (
                <div
                  className="appointment-card-patient-procedure"
                  class={`flex flex-row text-sm gap-x-[5px] mr-[15px]`}
                >
                  <div class={`font-bold text-gray-500`}>{`Reason:`}</div>
                  <div>{appointment.cancellationreason}</div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="appointment-card-status-container"
              class="absolute right-[5px] top-[5px]"
            >
              <Tooltip title={appointment.status} placement="top" arrow>
                <div
                  className="appointment-card-status"
                  class={`w-[15px] h-[15px] rounded-full`}
                  style={{
                    backgroundColor:
                      appointment.status === "requesting appointment"
                        ? "orange"
                        : appointment.status === "requesting cancellation"
                        ? "red"
                        : appointment.status === "active"
                        ? "green"
                        : appointment.status === "pending"
                        ? "#ff0090"
                        : appointment.status === "done"
                        ? "#00d0ff"
                        : "orange",
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default DayView;
