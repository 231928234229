import React, { useEffect, useState } from "react";
import './UserDetails.css';

import { RegisterContainer, Textfield, ColoredButton } from "../../../../components";

import validator from "validator";
import { useNavigate } from "react-router-dom";
import GlobalVariables from "../../../../globalVariables";

const UserDetails = () => {
    const navigate = useNavigate();

    const [emailOrNumberIsEmpty, setEmailOrNumberIsEmpty] = useState(false);
    const [emailOrNumber, setEmailOrNumber] = useState('');
    const [emailOrNumberPrompt, setEmailOrNumberPrompt] = useState('This field is required.');

    const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
    const [password, setPassword] = useState('');

    const next = async () => {
        let userDetails;

        // check if email or number field is empty
        if(!emailOrNumber.trim().length) return setEmailOrNumberIsEmpty(true);
        else setEmailOrNumberIsEmpty(false);

        // check if email or number is valid
        if(validator.isEmail(emailOrNumber)) {
            userDetails = {
                email: emailOrNumber,
                numbermobile: '',
            }
            setEmailOrNumberPrompt('');
            setEmailOrNumberIsEmpty(false);
        } else if (validator.isMobilePhone(emailOrNumber)) {
            // Check if phone number has exactly 11 digits
            const phoneNumberRegex = /^\d{11}$/;
            if (!phoneNumberRegex.test(emailOrNumber)) {
                setEmailOrNumberPrompt('Phone number must be exactly 11 digits.');
                return setEmailOrNumberIsEmpty(true);
            }

            userDetails = {
                email: '',
                numbermobile: emailOrNumber,
            }
            setEmailOrNumberPrompt('');
            setEmailOrNumberIsEmpty(false);
        } else {
            setEmailOrNumberPrompt('Enter a valid email or number.');
            return setEmailOrNumberIsEmpty(true);
        }

        // check if password is empty
        if(!password.trim().length) return setPasswordIsEmpty(true);
        else setPasswordIsEmpty(false);

        // set user details
        userDetails = {
            email: userDetails.email,
            numbermobile: userDetails.numbermobile,
            password: password
        }

        // send request to server
        let request = await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/auth/patient/signup/user-details`, {
                method: 'POST',
                body: JSON.stringify(userDetails),
                headers: { "ngrok-skip-browser-warning": true, 'Content-Type' : 'application/json'}
            }
        );
        request = await request.json();
        if(!request.ok) {
            if(request.emailOrNumberExists) {
                setEmailOrNumberPrompt("Email already exists.");
                return setEmailOrNumberIsEmpty(true);
            }
        };

        // set user details
        userDetails = {
            email: request.email,
            numbermobile: request.numbermobile,
            password: request.password
        }

        // navigate to next page
        navigate('/auth/patient/signup/register/name', { state: { userDetails }});
    };

    useEffect(() => {

    }, [emailOrNumberPrompt]);

    return (
        <div className="user-details">
            <div className="user-details-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="Hello, new patient!"
                    subheader="Please enter your email and password."
                    fields={(
                        <div className="user-details-fields-container">
                            <Textfield 
                                title="Email or Mobile Number"
                                prompt={emailOrNumberPrompt}
                                isEmpty={emailOrNumberIsEmpty}
                                getValue={setEmailOrNumber}
                            />
                            <Textfield 
                                title="Password"
                                type="password"
                                prompt="This field is required!"
                                isEmpty={passwordIsEmpty}
                                getValue={setPassword}
                            />
                        </div>
                    )}
                    options={(
                        <div className="user-details-options-container">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default UserDetails;
