import React, { useEffect, useState } from "react";
import './MedicalHistoryItem.css'

const MedicalHistoryItem = ({ question, fields, withYesOrNo, isYes, setYes }) => {

    const [yesOrNo, setYesOrNo] = useState(false);

    useEffect(() => {
        isYes && typeof isYes === 'function' && isYes(yesOrNo);
       
    }, [isYes, yesOrNo]);

    useEffect(() => {
        if(setYes !== undefined) setYesOrNo(setYes);
    }, [])

    return (
        <div className="medical-history-item-question">
            <div className="medical-history-item-header">
                <div className="medical-history-item-question-text">{question}</div>    
                <div className="medical-history-item-question-yes-or-no">
                    {
                        withYesOrNo ? 
                            (
                                <div className="medical-history-item-question-yes-or-no-radio-container">
                                    <div className="medical-history-item-question-yes-or-no-radio">
                                        <input 
                                            type="checkbox" 
                                            checked={yesOrNo}
                                            onChange={() => setYesOrNo(true)}                                       
                                        />
                                        Yes
                                    </div>
                                    <div className="medical-history-item-question-yes-or-no-radio">
                                        <input 
                                            type="checkbox" 
                                            checked={!yesOrNo}
                                            onChange={() => setYesOrNo(false)}                               
                                        />
                                        No
                                    </div>
                                </div>
                            ) 
                        : ''
                    }
                </div>
            </div>
            {withYesOrNo ? (yesOrNo ? fields : '') : fields}
        </div>
    );
};

export default MedicalHistoryItem;