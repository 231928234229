import { Route, Routes } from "react-router-dom";
import RecordsPage from "./sub-pages/RecordsPage";
import PatientRecord from "./sub-pages/PatientRecord";

function MainRecordsPage({userType}) {
  return (
    <div>
      <Routes>
        <Route path="/" element={<RecordsPage userType={userType}/>} />
        <Route path="/patient" element={<PatientRecord userType={userType}/>} />
      </Routes>
    </div>
  );
}

export default MainRecordsPage;
