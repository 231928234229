import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import './Address.css';
import { ColoredButton, RegisterContainer, Textfield } from "../../../../../components";

const Address = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let { patientInformation } = location.state || {};

    // variables for address
    const [addressIsEmpty, setAddressIsEmpty] = useState(false);
    const [address, setAddress] = useState('');

    // variables for city
    const [cityIsEmpty, setCityIsEmpty] = useState(false);
    const [city, setCity] = useState('');

    // variables for province
    const [provinceIsEmpty, setProvinceIsEmpty] = useState(false);
    const [province, setProvince] = useState('');

    const next = () => {
        // check if address is empty
        if(!address.trim().length) return setAddressIsEmpty(true);
        else setAddressIsEmpty(false);

        // check if city is empty
        if(!address.trim().length) return setCityIsEmpty(true);
        else setCityIsEmpty(false);

        // check if province is empty
        if(!address.trim().length) return setProvinceIsEmpty(true);
        else setProvinceIsEmpty(false);

        patientInformation = {
            email: patientInformation.email,
            numbermobile: patientInformation.numbermobile,
            password: patientInformation.password,
            namelast: patientInformation.namelast,
            namefirst: patientInformation.namefirst,
            namemiddle: patientInformation.namemiddle,
            dateofbirth: patientInformation.dateofbirth,
            patientgender: patientInformation.patientgender,
            patientweight: patientInformation.patientweight,
            patientheight: patientInformation.patientheight,
            patientaddress: `${address}, ${city}, ${province}`
        }

        navigate('/auth/patient/signup/register/civil-details', { state : { patientInformation } });
    };

    return (
        <div className="address-page">
            <div className="address-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="Where do you live?"
                    subheader="Please enter your address below."
                    fields={
                        <div className="address-container-fields">
                            <Textfield
                                title="Street Address"
                                prompt="This field is required."
                                isEmpty={addressIsEmpty}
                                getValue={setAddress}
                            />
                            <div className="address-container-fields-bottom">
                                <Textfield
                                    title="City"
                                    prompt="This field is required."
                                    isEmpty={cityIsEmpty}
                                    getValue={setCity}
                                />
                                <Textfield
                                    title="Province"
                                    prompt="This field is required."
                                    isEmpty={provinceIsEmpty}
                                    getValue={setProvince}
                                />
                            </div>
                        </div>
                    }
                    options={
                        <div className="address-container-options">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default Address;