import React, { useState, useEffect, useCallback, useRef } from "react";
import './Dropdown.css';

const Dropdown = ({ items = [], title, prompt, style, getValue, setValue, isEmpty }) => {
    const dropdownRef = useRef(null);
    
    const [dropdownTitle, setDropdownTitle] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [dropdownPrompt, setDropdownPrompt] = useState('');
    const [dropdownColor, setDropdownColor] = useState('#000000');
    const [dropdownBorderColor, setDropdownBorderColor] = useState('#c4c4c4');
    const [dropdownOutlineColor, setDropdownOutlineColor] = useState('#5b346b');
    const [dropdownHeight, setDropdownHeight] = useState('');
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const onItemClickHandler = (item) => {
        setDropdownValue(item);
        getValue && getValue(item);
        setDropdownIsOpen(false);
    };

    const isEmptyHandler = useCallback(() => {
        if(prompt !== undefined) setDropdownPrompt(prompt);
        setDropdownColor('#ff0000');
        setDropdownBorderColor('#ff0000');
        setDropdownOutlineColor('#ff0000');
    }, [prompt]);

    const isNotEmptyHandler = () => {
        setDropdownPrompt('');
        setDropdownColor('#000000');
        setDropdownBorderColor('#c4c4c4');
        setDropdownOutlineColor('#5b346b');
    }

    useEffect(() => {
        if(title !== undefined) setDropdownTitle(title);
        if(style && style.color !== undefined) setDropdownColor(style.color);
        if(style && style.borderColor !== undefined) setDropdownBorderColor(style.borderColor);
        if(style && style.outlineColor !== undefined) setDropdownOutlineColor(style.outlineColor);
        if(style && style.height !== undefined) setDropdownHeight(style.height);
        if(setValue !== undefined) setDropdownValue(setValue);
        if(isEmpty) isEmptyHandler();
        if(!isEmpty) isNotEmptyHandler();

        const closeDropdownOnOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownIsOpen(false);
            }
        };
        document.addEventListener("click", closeDropdownOnOutsideClick);
        return () => document.removeEventListener("click", closeDropdownOnOutsideClick);
    }, [items, title, prompt, style, isEmpty, isEmptyHandler]);

    return (
        <div ref={dropdownRef} className={`dropdown ${isEmpty ? "prompted" : ""}`} tabIndex={-1}>
            <div 
                className="dropdown-title" 
                style={{color: dropdownColor}}
            >
                {dropdownTitle}
            </div>
            <div 
                className="dropdown-box"
                style={{
                    borderColor: dropdownIsOpen ?  dropdownOutlineColor : dropdownBorderColor,
                    padding: dropdownHeight,
                    color: dropdownValue ? '' : 'rgba(0, 0, 0, 0)'
                }}
                onClick={() => setDropdownIsOpen(true)}
            >
                {dropdownValue ? dropdownValue : '.'}
            </div>
            <div className={`dropdown-list ${dropdownIsOpen ? "" : "closed"}`}>
                {items.map((item) => (
                    <div
                        className={`dropdown-list-item ${dropdownIsOpen ? "" : "closed"}`}
                        key={item}
                        onClick={() => onItemClickHandler(item)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div 
                className="dropdown-prompt" 
                style={{color: dropdownColor}}
            >
                {dropdownPrompt}
            </div>
        </div>
    );
};

export default Dropdown;