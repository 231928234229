import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import globalVariables from "../../../globalVariables";
import classNames from "classnames";

// pages
import DayView from "./appointment-views/day-view/DayView";
import WeekView from "./appointment-views/week-view/WeekView";
import MonthView from "./appointment-views/month-view/MonthView";

// components and elements
import { LuListTodo, LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { Select, MenuItem, FormControl, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useMediaQuery } from "react-responsive";
import CapitalizeFirstLetter from "../../../utils/capitalizeFirstLetter/CapitalizeFirstLetter";

const HomePage = () => {
  const navigate = useNavigate();
  const [calendarView, setCalendarView] = useState('day');

  const [date, setDate] = useState(dayjs());

  const [highlightCard, setHighlightCard] = useState({ id: 0 });

  const [appointmentRequestSideBarIsOpen, setAppointmentRequestSideBarIsOpen] = useState(false);
  const [fulfillAppointmentRequest, setFulfillAppointmentRequest] = useState({fulfill: false, appointmentRequest: {id: null, patientID: null, patientName: "", purpose: "", status: "no appointment"}});
  const isPhone = useMediaQuery({ query: '(max-width: 768px)' });

  const calendarViewOptionHandler = (event) => {
    setCalendarView(event.target.value);
    return navigate(`${event.target.value}-view`);
  }

  const barClass = classNames("container flex flex-row pt-2 overflow-hidden", isPhone ? "w-[600px]" : "")

  const datePreviousHandler = () => {
    if (calendarView === "day") return setDate(date.date(date.date() - 1));
    if (calendarView === "week") return setDate(date.week(date.week() - 1));
    if (calendarView === "month") return setDate(date.month(date.month() - 1));
  }

  const dateNextHandler = () => {
    if (calendarView === "day") return setDate(date.date(date.date() + 1));
    if (calendarView === "week") return setDate(date.week(date.week() + 1));
    if (calendarView === "month") return setDate(date.month(date.month() + 1));
  }

  const [appointmentRequestList, setAppointmentRequestList] = useState([]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, 
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options).replace(',', '');
  };

  const getAppointmentRequests = useCallback(async () => {
    let appointmentRequests;
    // get request
    try {
      appointmentRequests = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=appointment`, {
          method: 'GET',
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.log("...");
    }
    appointmentRequests = await appointmentRequests.json();
    appointmentRequests = appointmentRequests.map(async (appointmentRequest) => {
      if(appointmentRequest.status === "requesting appointment") {
        // get patient name
        let patient;
        let patientName;
        let patientID;
        try {
          patient = await fetch(
                `${globalVariables.WEB_ADDRESS_PORT}/?type=information&id=${appointmentRequest.patientid}`, {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": true,
                        token: localStorage.token,
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        patient = await patient.json();
        patient = patient[0];
        // set patientName to patient name
        patientName = `${CapitalizeFirstLetter(patient.namefirst)} ${CapitalizeFirstLetter(patient.namemiddle)} ${CapitalizeFirstLetter(patient.namelast)}`;
        patientID = patient.id;
        return {id: appointmentRequest.id, patientID: patientID, patientName: patientName, purpose: appointmentRequest.title, status: appointmentRequest.status, timestart: appointmentRequest.timestart};
      }
    });
    appointmentRequests = await Promise.all(appointmentRequests);
    appointmentRequests = appointmentRequests.filter(appointmentRequest => appointmentRequest !== undefined);

    setAppointmentRequestList(appointmentRequests);
  }, [])

  useEffect(() => {
    getAppointmentRequests();
  }, [calendarView, date, highlightCard, fulfillAppointmentRequest]);

  return (
    <div className="clinic-home-page">
      <div className="appointments-container">
        <div className="appointments-topbar" class="flex flex-row items-center place-content-between pb-2 border-b-2">
          <div className="appointments-topbar-title-and-chooser" class="flex flex-row items-center space-x-5">
            <div className="appointments-topbar-title" class="font-bold text-xl select-none">Appointments</div>
            <FormControl
              sx={{
                width: 300,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div className="date-previous" class="min-w-[40px] align-center">
                <LuChevronLeft
                  class="text-slate-500 h-10 w-10 hover:text-[#ac7de8] active:h-9 active:w-9 cursor-pointer"
                  onClick={datePreviousHandler}
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={date}
                  views={
                    calendarView === 'day' ? ['month', 'day', 'year']
                      : calendarView === 'week' ? ['month', 'day', 'year']
                        : calendarView === 'month' ? ['month', 'year']
                          : ''
                  }
                  format={
                    calendarView === 'day' ? "MMM D, YYYY | ddd"
                      : calendarView === 'week' ? `Week ${date.week()} MMM YYYY`
                        : calendarView === 'month' ? "MMM YYYY"
                          : ''
                  }
                  slotProps={{ textField: { size: 'small' } }}
                  openTo="day"
                  onChange={(value) => {
                    return setDate(value);
                  }}
                />
              </LocalizationProvider>
              <div className="date-next" class="min-w-[40px] align-center">
                <LuChevronRight
                  class="text-slate-500 h-10 w-10 hover:text-[#ac7de8] active:h-9 active:w-9 cursor-pointer"
                  onClick={dateNextHandler}
                />
              </div>
            </FormControl>
          </div>
          <div className="appointments-options-container" class="flex flex-row items-center space-x-5">
            <FormControl sx={{ width: 100 }} size="small">
              <Select
                value={calendarView}
                onChange={calendarViewOptionHandler}
              >
                <MenuItem value={"day"}>Day</MenuItem>
                <MenuItem value={"week"}>Week</MenuItem>
                <MenuItem value={"month"}>Month</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Appointment Requests" placement="top" arrow>
              <div 
                className="relative appointments-requests"
                onClick={() => setAppointmentRequestSideBarIsOpen((value) => value = !value)}
              >
                <LuListTodo class="text-slate-500 h-6 w-6"/>
                {appointmentRequestList.length > 0 ? 
                  <div className="absolute flex bottom-[-1px] left-[-1px] h-[17px] w-[17px] bg-white rounded-full place-content-center place-items-center">
                    <div className="h-[11px] w-[11px] bg-[#FA3E3E] rounded-full"/>
                  </div> : ''
                }
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={barClass}>
          <div className="calendar-container flex-1 transition-all duration-300"
            style={{
              width: '100%'
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="day-view" />} />
              <Route path="/day-view" element={<DayView dayViewDate={date} calendarView={setCalendarView} highlightCard={highlightCard} fulfillAppointmentRequest={fulfillAppointmentRequest} setFulfillAppointmentRequest={setFulfillAppointmentRequest}/>} />
              <Route path="/week-view" element={<WeekView weekViewDate={date} toDate={setDate} calendarView={setCalendarView} highlightCard={setHighlightCard} />} />
              <Route path="/month-view" element={<MonthView monthViewDate={date} toDate={setDate} calendarView={setCalendarView} />} />
            </Routes>
          </div>
          <div className="flex-0 pl-[15px] transition-all duration-300"
            style={{
              width: '25%',
              marginLeft: appointmentRequestSideBarIsOpen ? '0%' : '-25%',
              opacity: appointmentRequestSideBarIsOpen ? 1 : 0,
              transform: appointmentRequestSideBarIsOpen ? 'translateX(0%)' : 'translateX(100%)',
              visibility: appointmentRequestSideBarIsOpen ? 'visible' : 'hidden'
            }}
          >
            <div className="border-b-2 font-bold pb-[10px] mb-[10px]">Appointment Requests</div>
            <div className="flex flex-col gap-y-[5px]">
              {appointmentRequestList.map((appointmentRequest, i) => (
                <div
                  key={i}
                  className="w-[100%] bg-[#e5e5e5] rounded-lg p-[10px] hover:bg-[#e5ceed] active:bg-[#f6ddff] cursor-pointer"
                  onClick={() => {
                    setFulfillAppointmentRequest({ fulfill: true, appointmentRequest: appointmentRequest });
                  }}
                >
                  {console.log()}
                  <div className="flex flex-col">
                    <div className="font-bold text-sm">Patient</div>
                    <div className="ml-[10px]">{appointmentRequest.patientName}</div>
                    <div className="font-bold text-sm">Purpose</div>
                    <div className="ml-[10px]">{appointmentRequest.purpose}</div>
                    <div className="font-bold text-sm">Suggested Time</div>
                    <div className="ml-[10px]">{formatDate(appointmentRequest.timestart)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
