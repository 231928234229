import React from "react";
import './RegisterContainer.css';

import { logo_icon, logo } from "../../assets";

const RegisterContainer = ({header, subheader, fields, options}) => {
    return (
        <div className="register-container">
            <div className="register-prompt-container">
                <div className="register-logo-container">
                    <img className="logo-icon" src={logo_icon} alt=""/>
                    <img className="logo-img" src={logo} alt=""/>
                </div>
                <div className="header">{header}</div>
                <div className="subheader">{subheader}</div>
            </div>
            <div className="register-fields-container">
                {fields}
            </div>
            <div className="register-options-container">
                {options}
            </div>
        </div>
    );
};

export default RegisterContainer;