import React, { Fragment, useState, useEffect } from "react";

import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import {
  HomePage
} from "./pages";

import ClinicHomePage from "./pages/clinic/ClinicHomePage";

import GlobalVariables from "./globalVariables";
import MainAuthenticationPage from "./pages/authentication/MainAuthenticaionPage";
import CustomModal from "./components/clinic/CustomModal";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
      
  const authentication = async () => {
    try {
      // verify token
      let request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
            method: 'GET',
            headers: {
              "ngrok-skip-browser-warning": true, 
              token : localStorage.token
            }
        }
      );
      if(!request.ok) {
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        return console.log(request);
      }
      request = await request.json();

      // set isAuthenticated and userType
      setIsAuthenticated(true);
      setUserType(request.type);

    } catch (error) {
      console.log("...");
    } finally {
      setIsLoading(false);
      setModal(false);
    }
  };

  useEffect(() => {
    authentication()
  }, [isAuthenticated, userType]);

  if(isLoading) return <div></div>;

  return (
    <Fragment>
      <CustomModal open={modal} onClose={() => authentication()}>
        Your session has expired.
      </CustomModal>
      <div className="App">
        <Routes>
        <Route
            path="/"
            element={
              !isAuthenticated?
                <Navigate to="/auth"/>
                : (userType === "clinic") ? 
                  <Navigate to="/clinic"/> 
                  : <Navigate to="/patient"/>
            }
          />
          <Route
            path="/auth/*"
            element={
              !isAuthenticated?
                <MainAuthenticationPage
                  setIsAuthenticated={setIsAuthenticated}
                  setUserType={setUserType}
                />
                : (userType === "clinic") ? 
                  <Navigate to="/clinic"/> 
                  : <Navigate to="/patient"/>
            }
          />
          <Route 
            path="/clinic/*" 
            element={
              !isAuthenticated?
                <Navigate to="/auth"/>
                : (userType === "clinic") ? 
                  <ClinicHomePage 
                    setIsAuthenticated={setIsAuthenticated}
                    setUserType={setUserType}
                    userType={userType}
                  /> 
                  : <Navigate to="/patient"/>
            }
          />
          <Route 
            path="/patient/*" 
            element={
              !isAuthenticated?
                <Navigate to="/auth"/>
                : (userType === "clinic") ? 
                  <Navigate to="/clinic"/> 
                  : <HomePage
                      setIsAuthenticated={setIsAuthenticated}
                      setUserType={setUserType}
                    />
            } 
          />
        </Routes>
      </div>
    </Fragment>
  );
};

export default App;
