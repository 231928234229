import { useEffect, useState } from "react";
import { JSON_NAME, TOKEN_NAME } from "../../../components/clinic/Constant";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Button } from "@mui/base";
import ProcedureModal from "../../../components/clinic/ProcedureModal";
import BiggerCustomModal from "../../../components/clinic/BiggerCustomModal";
import CustomModal from "../../../components/clinic/CustomModal";
import dayjs from "dayjs";
import globalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

function PatientPayment() {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ModeofPayment, setModeofPayment] = useState("Cash");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [paymentDate, setPaymentDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [table, setTable] = useState([]);
  const recievedToken = localStorage.getItem(TOKEN_NAME);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const [error, setError] = useState(false);
  const maxBigIntValue = 9223372036854775807;

  const tableClass = classNames(
    isPhone ? "font-bold text-l" : "font-bold text-xl"
  );

  const modal2Class = classNames(
    "grid-cols-1 gap-5 p-[32px]",
    isPhone ? "w-[350px]" : "w-[30vw]"
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (recievedToken) {
      const storedData = localStorage.getItem(JSON_NAME);
      setLoading(true);
      try {
        fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=getprocedure&patientid=${storedData}`,
          {
            method: "GET",
            headers: {
              token: recievedToken,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((sent) => {
            console.log(sent);
            setTable(sent);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error at: ", error);
      }
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const closeModal2 = () => {
    setModal2(false);
    setError(false);
    setModeofPayment("Cash");
    setReferenceNumber(null);
    setPaymentDate(dayjs().format("YYYY-MM-DD"));
  };

  const closeModal3 = () => {
    setModal3(false);
  };

  const openModal3 = () => {
    setModal3(true);
  };

  const setSelectedModal = (row) => {
    setModal2(true);
    setSelectedRow(row);
    // setModeofPayment(row.ModeofPayment || "");
    // setReferenceNumber(row.referenceNumber || "Cash");
    setPaymentDate(row.paymentDate || dayjs().format("YYYY-MM-DD"));
  };

  const saveStatus = (id, modeOfPayment, refNumber, datePaid) => {
    // Check if datePaid is undefined, and handle the scenario
    if (typeof datePaid === "undefined") {
      console.error("Received datePaid is undefined.");
      return;
    }
    if (id === null || id === undefined) {
      console.error("Received id is null or undefined.");
      return;
    }
    if (
      (selectedRow && selectedRow.iscompleted === null) ||
      selectedRow.iscompleted === undefined
    ) {
      console.error("Received iscompleted is null or undefined.");
      return;
    }

    if (selectedRow && recievedToken) {
      try {
        let updatedRefNumber = refNumber; // Initialize updated reference number

        // If switching to Cash mode, set reference number to null
        if (modeOfPayment === "Cash") {
          updatedRefNumber = null;
        }
        if (
          (modeOfPayment === "GCash" || modeOfPayment === "Bank Transfer") &&
          refNumber.length == ""
        ) {
          setError(true);
          return;
        }
        if (updatedRefNumber !== null) {
          // Handle the case when updatedRefNumber is not null
        }
        setError(false);

        fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=updateprocedurestatus`,
          {
            method: "PATCH",
            headers: {
              token: recievedToken,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
              iscompleted: selectedRow.iscompleted,
              modeOfPayment: modeOfPayment,
              referenceNumber:
                modeOfPayment === "Cash"
                  ? undefined
                  : updatedRefNumber, // Use updated reference number as a string
              datePaid: datePaid,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // const updatedTable = table.map((entry) => {
            //   if (entry.id === selectedRow.id) {
            //     return {
            //       ...entry,
            //       iscompleted: selectedRow.iscompleted,
            //       modeOfPayment: modeOfPayment,
            //       referenceNumber:
            //         modeOfPayment === "Cash" ? "N/A" : String(updatedRefNumber), // Parse as string
            //       paymentDate: new Date(datePaid), // Parse date
            //     };
            //   }
            //   return entry;
            // });
            setModal2(false);
            fetchData();
          })
          .catch((error) => {
            console.error("Error saving status:", error); // Adjusted error message
          });
      } catch (error) {
        console.error("Error at: ", error);
      }
    }
  };

  const deleteStatus = (id) => {
    setModal2(false);
    setModal3(false);
    if (recievedToken) {
      try {
        fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=removeprocedure&id=${id}`,
          {
            method: "DELETE",
            headers: {
              token: recievedToken,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then(() => {
            fetchData();
          })
          .catch((error) => {
            console.error("Error deleting procedure:", error); // Adjusted error message
          });
      } catch (error) {
        console.error("Error fetching data:", error); // Adjusted error message
      }
    }
  };

  // const addProcedure = (newProcedure) => {
  //   if (newProcedure) {
  //     const updatedTable = [...table];
  //     const selectedRowValues = selectedRow
  //       ? {
  //           referenceNumber: selectedRow.referenceNumber || "",
  //           paymentDate:
  //             selectedRow.paymentDate || dayjs().format("YYYY-MM-DD"),
  //           ModeofPayment: selectedRow.ModeofPayment || "Cash",
  //         }
  //       : {
  //           referenceNumber: "",
  //           paymentDate: dayjs().format("YYYY-MM-DD"),
  //           ModeofPayment: "Cash",
  //         };
  //     updatedTable.push({
  //       ...newProcedure,
  //       ...selectedRowValues,
  //     });
  //     setTable(updatedTable);
  //     localStorage.setItem("table", JSON.stringify(updatedTable));
  //   } else {
  //     console.error("Invalid procedure data:", newProcedure);
  //   }
  // };

  if (loading) return <div>Loading..</div>;
  return (
    <div>
      <ProcedureModal open={modal} onClose={closeModal} reload={fetchData} />
      <CustomModal open={modal3} onClose={closeModal3} disableClose={true}>
        Are you sure you want to delete?
        <div className="flex justify-between w-[90%]">
          <Button
            className={"mt-[10%] text-blue-500 font-bold"}
            onClick={() => closeModal3()}
          >
            No
          </Button>
          <Button
            className={"mt-[10%] text-blue-500"}
            onClick={() => deleteStatus(selectedRow.id)}
          >
            Yes
          </Button>
        </div>
      </CustomModal>

      <BiggerCustomModal
        open={modal2}
        onClose={closeModal2}
        disableClose={true}
        sx={{ width: "600px", height: "400px" }}
      >
        <div className={modal2Class}>
          <div className="flex justify-between w-[90%]">
            <p>Status: </p>
            <Select
              id="dropdown-prefix"
              value={selectedRow ? selectedRow.iscompleted : false}
              label={"Payment Status"}
              onChange={(event) => {
                if (selectedRow) {
                  setSelectedRow({
                    ...selectedRow,
                    iscompleted: event.target.value,
                  });
                  if (event.target.value) {
                    setPaymentDate(dayjs().format("YYYY-MM-DD"));
                  }
                }
              }}
              style={{ height: "30px" }}
            >
              <MenuItem value={false}>Unpaid</MenuItem>
              <MenuItem value={true}>Paid</MenuItem>
            </Select>
          </div>

          {selectedRow && selectedRow.iscompleted && (
            <>
              <div className="flex justify-between w-[90%]">
                <p>Mode of Payment:</p>
                <Select
                  id="ModeofPayment"
                  value={ModeofPayment}
                  onChange={(event) => {
                    setModeofPayment(event.target.value);
                    // If the selected mode of payment is "Cash", set the reference number to "N/A"
                    if (event.target.value === "Cash") {
                      setSelectedRow({
                        ...selectedRow,
                        referencenumber: "N/A",
                      });
                    } else {
                      // Otherwise, clear the reference number
                      setSelectedRow({
                        ...selectedRow,
                        referencenumber: "",
                      });
                    }
                  }}
                  style={{ height: "30px" }}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="GCash">GCash</MenuItem>
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                </Select>
              </div>
              <div className="flex justify-between w-[90%]">
                <p>Date:</p>
                <TextField
                  id="payment-date"
                  type="date"
                  value={paymentDate}
                  onChange={(event) => setPaymentDate(event.target.value)}
                  disabled={!selectedRow.iscompleted} // Only enable date entry if payment is completed
                  inputProps={{
                    max: dayjs().format("YYYY-MM-DD"), // Set max date to current date
                    inputMode: "numeric", // Set input mode to numeric
                    pattern: "\\d{4}-\\d{2}-\\d{2}", // Enforce YYYY-MM-DD format
                  }}
                />
              </div>
            </>
          )}

          {selectedRow &&
            selectedRow.iscompleted &&
            (ModeofPayment === "GCash" ||
              ModeofPayment === "Bank Transfer") && (
              <div className="flex justify-between w-[90%]">
                <p>Reference Number:</p>
                <TextField
                  id="reference-number"
                  value={referenceNumber}
                  onChange={(e) => {
                    const input = e.target.value;
                  
                    if (/^[a-zA-Z0-9]*$/.test(input)) {
                      setReferenceNumber(input);
                    }
                  }}
                  inputProps={{
                    input: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 30,
                  }}
                  error={error}
                  helperText={
                    error === true ? "Refrence Number Invalid" : ""
                  }
                />
              </div>
            )}

          <div className="flex justify-around w-[100%]">
            <Button
              className={"mt-[10%] text-blue-500 mb-10"}
              onClick={() =>
                saveStatus(
                  selectedRow.id,
                  ModeofPayment,
                  referenceNumber,
                  paymentDate
                )
              }
            >
              Save Status
            </Button>
            <Button
              className={"mt-[10%] text-red-500 mb-10"}
              onClick={openModal3}
            >
              Delete Status
            </Button>
          </div>
        </div>
      </BiggerCustomModal>

      <Button id={"add-procedure"} onClick={() => setModal(true)}>
        Add
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <p className={tableClass}>Procedures</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Description</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Cost</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Payment Status</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Date Created</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Mode of Payment</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Reference Number</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Date Paid</p>
              </TableCell>
              <TableCell>
                <p className={tableClass}>Issuer</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table.map((entry) => (
              <TableRow
                className={"hover:bg-slate-100"}
                onClick={() => setSelectedModal(entry)}
                key={entry.id}
              >
                <TableCell>
                  <p className="">{entry.procedurename}</p>
                </TableCell>
                <TableCell>
                  <p className="">{entry.description}</p>
                </TableCell>
                <TableCell>
                  <p className="">{entry.cost}</p>
                </TableCell>
                <TableCell>
                  {entry.iscompleted ? (
                    <p className="text-green-400">Paid</p>
                  ) : (
                    <p className="text-red-400">Unpaid</p>
                  )}
                </TableCell>
                <TableCell>
                  <p className="">
                    {dayjs(entry.datecreated).format("MM/DD/YYYY")}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="">
                    {entry.iscompleted
                      ? entry.modeofpayment
                      : ""}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="">
                    {entry.iscompleted && ModeofPayment !== "Cash"
                      ? entry.referencenumber
                      : ""}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="">
                    {entry.iscompleted && entry.datepaid
                      ? dayjs(entry.datepaid).format("MM/DD/YYYY")
                      : ""}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="">{entry.fullname}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default PatientPayment;
