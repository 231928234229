import React, { useState } from "react";
import './BillingPage.css'
import { useEffect } from "react";
import { useCallback } from "react";
import dayjs from "dayjs";
import GlobalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import Modal from "react-modal";
import { ColoredButton } from "../../../components";

const BillingPage = () => {
    const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

    const [modalIsOpen, setModelIsOpen] = useState(false);
    const [procedureToOpen, setProcedureToOpen] = useState({});

    const [patientID, setPatientID] = useState('');
    const [procedures, setProcedures] = useState([]);
    const [balance, setBalance] = useState('0.00');
    const [recentPayable, setRecentPayable] = useState('0.00');

    const getPatientID = useCallback(async () => {
        let patient = await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
                method: 'GET',
                headers: { 
                    token : localStorage.token,
                    "ngrok-skip-browser-warning": true, 
                    'Content-Type' : 'application/json'
                }
            }
        )
        if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
        patient = await patient.json();
        setPatientID(patient.user)
    }, []);

    const getProcedures = useCallback(async () => {
        // get procedures
        let requestProcedures
        try {
            requestProcedures = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=getprocedure&patientid=${patientID}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        requestProcedures = await requestProcedures.json();

        let proceduresFormatted = requestProcedures.map((procedure) => {
            let formatteddate = new Date();
            if(procedure.datecreated !== null) {
                formatteddate = new Date(procedure.datecreated);
                formatteddate = formatteddate.toLocaleDateString();
            }
        
        let formattedDatePaid = "";
        if(procedure.datepaid !== null) {
            let formattedDate = new Date(procedure.datepaid);
            formattedDatePaid = formattedDate.toLocaleDateString();
        }
            return {
                id: procedure.id,
                description : procedure.description,
                cost : procedure.cost,
                iscompleted : procedure.iscompleted,
                procedurename : procedure.procedurename,
                datecreated : formatteddate,
                datepaid: formattedDatePaid,
                referencenumber: procedure.referencenumber,
                fullname: procedure.fullname,
                modeofpayment:procedure.modeofpayment
            }
        });
        proceduresFormatted = await Promise.all(proceduresFormatted);

        // set procedures
        setProcedures(proceduresFormatted);
    }, [patientID]);

    const getInvoice = useCallback(async () => {
        // get invoice
        let invoice;
        try {
            invoice = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=getprocedure&patientid=${patientID}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            return setBalance((Math.round(0 * 100) / 100).toFixed(2));
        }
        invoice = await invoice.json();
        if(!Object.keys(invoice).length) return setBalance((Math.round(0 * 100) / 100).toFixed(2));

        invoice = invoice.filter((invoice) => !invoice.iscompleted);
        invoice = invoice.map((inv) => {
            return Number(inv.cost);
        });
        invoice = invoice.reduce((accumulator, currentvalue) => accumulator + currentvalue, 0);

        // set balance ammount
        setBalance((Math.round(invoice * 100) / 100).toFixed(2));
    }, [patientID]);

    const getRecentPayable = useCallback(async () => {
        // get recent payables
        let procedures;
        try {
            procedures = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=getprocedure&patientid=${patientID}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            return setRecentPayable((Math.round(0 * 100) / 100).toFixed(2));
        }
        procedures = await procedures.json();
        if(!Object.keys(procedures).length) return setRecentPayable((Math.round(0 * 100) / 100).toFixed(2));

        let prevID = 0;
        let currentID;
        let recent;
        procedures.map((procedure) => {
            currentID = procedure.id;
            if(prevID < currentID) {
                prevID = currentID;
                recent = procedure;
            }
            return 0;
        });

        // set recent payable
        setRecentPayable((Math.round(recent.cost * 100) / 100).toFixed(2));
    }, [patientID]);

    const onProcedureClickHandler = (id) => {
        if(id !== undefined) {
            const toOpen = procedures.filter((procedure) => procedure.id === id);
            setProcedureToOpen(toOpen[0]);
        }
        setModelIsOpen((prev) => !prev);
    }

    useEffect(() => {
        getPatientID();
        if(patientID) {
            getProcedures();
            getInvoice();
            getRecentPayable();
        }
    }, [getPatientID, patientID, getProcedures, getInvoice, getRecentPayable, procedureToOpen]);

    return (
        <div className="billing-container">
            <div className="billing-header">
                <div className="billing-header-text">Billing</div>
            </div>
            <div className="billing-box-container">
                <div className="billing-box-row-header">
                    <div className="billing-box-header">Procedure</div>
                    <div className="billing-box-header">Cost</div>
                    <div className="billing-box-header">Date</div>
                    {!isPhone ? 
                        <div className="billing-box-header">Payment Status</div>
                        : ''
                    }
                </div>
                <Modal
                    className="procedure-modal"
                    isOpen={modalIsOpen}
                >
                    <div className="procedure-modal-header-title">procedure</div>
                    <div className="procedure-modal-header">
                        <div className="procedure-modal-header-text">
                            {procedureToOpen.procedurename}
                        </div>
                        <div className={`procedure-modal-header-procedure-status ${procedureToOpen.iscompleted ? "" : "incomplete"}`}>
                            {procedureToOpen.iscompleted ? "complete" : "incomplete"}
                        </div>
                    </div>
                    <div className="procedure-modal-body">
                        <div className="procedure-modal-procedure-description-title">Description:</div>
                        <div className="procedure-modal-procedure-description">{procedureToOpen.description}</div>
                        <div className="procedure-model-procedure-cost-title">Cost:</div>
                        <div className="procedure-model-procedure-description">PHP {(Math.round(procedureToOpen.cost * 100 / 100).toFixed(2))}</div>
                        
                        
                        <div className="procedure-model-procedure-cost-title">Mode Of Payment:</div>
    <div className="procedure-modal-procedure-description">
        {procedureToOpen.iscompleted ? procedureToOpen.modeofpayment : "N/A"}
    </div>
    
    <div className="procedure-model-procedure-cost-title">Reference Number:</div>
    <div className="procedure-modal-procedure-description">
        {procedureToOpen.iscompleted ? procedureToOpen.referencenumber : "N/A"}
    </div>
    
    <div className="procedure-model-procedure-cost-title">Date Paid:</div>
    <div className="procedure-modal-procedure-description">
        {procedureToOpen.iscompleted ? (procedureToOpen.datepaid ? dayjs(procedureToOpen.datepaid).format("MM/DD/YYYY") : "") : "N/A"}
    </div>
                        <div className="procedure-model-procedure-cost-title">Issuer:</div>
                        <div className="procedure-modal-procedure-description">{procedureToOpen.fullname}</div>
                    </div>
                    <div className="procedure-modal-footer">
                        <ColoredButton
                            text="Pay"
                            color="#ffffff"
                            backgroundColor="#5b346b"
                            backgroundColorOnHover="#6d3f80"
                            onClick={() => onProcedureClickHandler()}
                        />
                    </div>
                </Modal>
                {procedures && procedures.map((procedure) => (
                <div className="procedures">
                    <div className="billing-box-row" id={procedure.id} key={procedure.id} onClick={() => {onProcedureClickHandler(procedure.id)}}>
                        <div className="billing-box-item">{procedure.procedurename}</div>
                       
                   
                        <div className={`billing-box-item-cost ${procedure.iscompleted ? "complete" : "incomplete"}`}>
                            <div>₱{(Math.round(procedure.cost * 100 / 100).toFixed(2))}</div>
                        </div>
                        <div className="billing-box-item">{procedure.datecreated}</div>
                        {!isPhone ? 
                            <div className="billing-box-item">
                                {procedure.iscompleted ? "complete" : "incomplete"}
                                {procedure.iscompleted ? '' : <div className="billing-box-procedure-status-circle"/>}
                            </div>
                                : ''
                            }                        
                        </div>
                    </div>
                ))}
            </div>
            {isPhone ?
                <div className="billingpage-balance-container">
                    <div className="billingpage-balance-box">
                        <div className="billingpage-balance-box-header-container">
                            <div className="billingpage-balance-box-header">Balance</div>
                        </div>
                        <div className="billingpage-balance-box-contents-container">
                            <div className="billingpage-balance-box-contents-recent-payable">
                                <div className="billingpage-balance-box-contents-recent-payable-title">
                                    Recent Payable
                                </div>
                                <div className="billingpage-balance-box-contents-recent-payable-amount">
                                    PHP {recentPayable}
                                </div>
                            </div>
                            <div className="billingpage-balance-box-contents-previous-balance">
                                <div className="billingpage-balance-box-contents-previous-balance-title">
                                    Previous Balance
                                </div>
                                <div className="billingpage-balance-box-contents-previous-balance-amount">
                                    PHP {Math.round((balance - recentPayable) * 100 / 100).toFixed(2)}
                                </div>
                            </div>
                            <div className="billingpage-balance-box-contents-balance">
                                <div className="billingpage-balance-box-contents-balance-title">
                                    Total Balance
                                </div>
                                <div className="billingpage-balance-box-contents-balance-amount">
                                    PHP {balance}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }
        </div>
    );
};

export default BillingPage;