import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import { produce } from "immer";
import { useEffect, useReducer, useState } from "react";
import { Button } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import theme from "../../../components/clinic/Theme";
import TeethImage from "./TeethImage";
import { JSON_NAME, TOKEN_NAME } from "../../../components/clinic/Constant";
import PatientPayment from "./PatientPayment";
import InformationTab from "./PatientForms";
import globalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const IMAGE_SELECT = "images";
const FORMS_SELECT = "forms";
const PAYMENT_SELECT = "payment";
const TREATMENT_SELECT = "treatment";

const reducer = (state, action) => {
  switch (action.type) {
    case IMAGE_SELECT:
      state.selectRecords = IMAGE_SELECT;
      return;
    case FORMS_SELECT:
      state.selectRecords = FORMS_SELECT;
      return;
    case PAYMENT_SELECT:
      state.selectRecords = PAYMENT_SELECT;
      return;
    case TREATMENT_SELECT:
      state.selectRecords = TREATMENT_SELECT;
      return;
    default:
      throw new Error("unexpected action type" + action.type);
  }
};

function PatientRecord({ userType }) {
  const [state, dispatch] = useReducer(produce(reducer), {
    selectRecords: IMAGE_SELECT,
  });
  const [patient, setPatient] = useState([]);
  const [loading, setLoading] = useState(true);
  const storedData = localStorage.getItem(JSON_NAME);
  const recievedToken = localStorage.getItem(TOKEN_NAME);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const boxClass = classNames(isPhone ? "bg-white py-10 border-none w-full": "bg-white p-10 border-none w-full h-[60%]")
  const titleClass = classNames(isPhone ? "text-3xl mb-5" : "text-5xl mb-5")

  const [dentistID, setDentistID] = useState(0);

  function patientInformation() {
    if (recievedToken && userType === "clinic") {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&id=${storedData}`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPatient(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  const getDentistID = async () => {
    let request;
    // get dentist id through token
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
        method: 'GET',
        headers: {
          "ngrok-skip-browser-warning": true,
          token: localStorage.token
        }
      }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    setDentistID(request.user);
  };

  useEffect(() => {
    patientInformation();
    getDentistID();
  }, [userType]);

  useEffect(() => {
    patientInformation();
  }, [localStorage.login]);


  const selectImage = () => {
    dispatch({ type: IMAGE_SELECT });
  };

  const selectForms = () => {
    dispatch({ type: FORMS_SELECT });
  };

  const selectPayment = () => {
    dispatch({ type: PAYMENT_SELECT });
  };

  const handleBack = () => {
    window.history.back();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="mt-20 relative"
      style={{ position: "absolute", zIndex: "2" }}
    >
      <AiOutlineArrowLeft
        className="text-4xl absolute z-3 mt-[-60px] hover:text-blue-500"
        onClick={handleBack}
        title="Go Back"
      />
      <p className={titleClass}>
        {patient[0].namefirst +
          " " +
          patient[0].namemiddle +
          " " +
          patient[0].namelast}
      </p>
      <ThemeProvider theme={theme}>
        <ButtonGroup
          className={"bg-gray-200 border-solid border-2 border-black"}
          variant="outlined"
          aria-label="outlined button group"
        >
          <Button size={isPhone ? "small" : "large"} color="main_grey" onClick={selectImage}>
            Images
          </Button>
          <Button size={isPhone ? "small" : "large"} color="main_grey" onClick={selectForms}>
            Forms
          </Button>
          {localStorage.getItem("currentUserRole") === "dentist" ?
            <Button size={isPhone ? "small" : "large"} color="main_grey" onClick={selectPayment}>
              Procedures and cost
            </Button> :
            ''
          }          
        </ButtonGroup>
      </ThemeProvider>
      <Box className={boxClass}>
        {state.selectRecords === IMAGE_SELECT ? (
          <TeethImage userType={userType} />
        ) : (
          ""
        )}
        {state.selectRecords === FORMS_SELECT ? (
          <InformationTab />
        ) : (
          ""
        )}
        {state.selectRecords === PAYMENT_SELECT ? (
          <PatientPayment userType={userType} />
        ) : (
          ""
        )}
      </Box>
    </div>
  );
}

export default PatientRecord;
