import React, { useState } from "react";
import './ClinicContact.css';
import { useLocation, useNavigate } from "react-router-dom";

import validator from "validator";
import { ColoredButton, RegisterContainer, Textfield } from "../../../../../components";
import globalVariables from "../../../../../globalVariables";

const ClinicContact = ({ setAuth, setType }) => {
    const location = useLocation();
    const { clinicDetailsWithAddress } = location.state || {};

    const [mobileNumberIsEmpty, setMobileNumberIsEmpty] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [mobileNumberPrompt, setMobileNumberPrompt] = useState('This field is required!');

    const [telephoneNumberIsEmpty, setTelephoneNumberIsEmpty] = useState(false);
    const [telephoneNumber, setTelephoneNumber] = useState('');
    const [telephoneNumberPrompt, setTelephoneNumberPrompt] = useState('This field is required!');

    const next = async () => {
        let clinicDetails;

        // check if mobile number is empty
        if(!mobileNumber.trim().length) {
            setMobileNumberPrompt('This field is required!');
            return setMobileNumberIsEmpty(true);
        } else setMobileNumberIsEmpty(false);

        // validate mobile number
        if(validator.isMobilePhone(mobileNumber)) {
            clinicDetails = {
                clinicnumbermobile: mobileNumber
            }
            setMobileNumberPrompt('This field is required!');
            setMobileNumberIsEmpty(false);
        } else {
            setMobileNumberPrompt('Entered mobile number is invalid');
            return setMobileNumberIsEmpty(true);
        }

        // check if telephone number is empty
        if(!telephoneNumber.trim().length) {
            setTelephoneNumberPrompt('This field is required!');
            return setTelephoneNumberIsEmpty(true);
        } else setTelephoneNumberIsEmpty(false);

        // validate telephone number
        if(validator.isMobilePhone(telephoneNumber)) {
            clinicDetails = {
                clinicnumbermobile: clinicDetails.clinicnumbermobile,
                clinicnumbertelephone: telephoneNumber
            }
            setTelephoneNumberPrompt('This field is required!');
            setTelephoneNumberIsEmpty(false);
        } else {
            setTelephoneNumberPrompt('Entered telephone number is invalid');
            return setTelephoneNumberIsEmpty(true);
        }

        clinicDetails = {
            clinicname: clinicDetailsWithAddress.clinicname,
            clinicemail: clinicDetailsWithAddress.clinicemail,
            clinicaddress: clinicDetailsWithAddress.clinicaddress,
            clinicnumbermobile: clinicDetails.clinicnumbermobile,
            clinicnumbertelephone: clinicDetails.clinicnumbertelephone,
            email: clinicDetailsWithAddress.email,
            numbermobile: clinicDetailsWithAddress.numbermobile,
            password: clinicDetailsWithAddress.password,
            namefirst: clinicDetailsWithAddress.namefirst,
            namemiddle: clinicDetailsWithAddress.namemiddle,
            namelast: clinicDetailsWithAddress.namelast,
            numberprc: clinicDetailsWithAddress.numberprc,
            color: clinicDetailsWithAddress.color
        };

        let request;
        try {
            request = await fetch(
                `${globalVariables.WEB_ADDRESS_PORT}/auth/clinic/signup/register`, {
                    method: 'POST',
                    body: JSON.stringify(clinicDetails),
                    headers: { "ngrok-skip-browser-warning": true, 'Content-Type' : 'application/json'}
                }
            );
        } catch (error) {
            console.log("...");
        }
        request = await request.json();

        localStorage.setItem("token", request.token);
        localStorage.setItem("login", 1);
        setAuth(true);
        setType('clinic');
    };

    return (
        <div className="clinic-contact">
            <div className="clinic-contact-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="How can we reach your clinic?"
                    subheader={`Please enter ${clinicDetailsWithAddress.clinicname}'s mobile and telephone number.`}
                    fields={(
                        <div className="clinic-contact-fields-container">
                            <Textfield 
                                title="Clinic Mobile Number"
                                prompt={mobileNumberPrompt}
                                isEmpty={mobileNumberIsEmpty}
                                getValue={setMobileNumber}
                                setValue={mobileNumber}
                                placeholder={"09xxxxxxxxx"}
                            />
                            <Textfield 
                                title="Clinic Telephone Number"
                                prompt={telephoneNumberPrompt}
                                isEmpty={telephoneNumberIsEmpty}
                                getValue={setTelephoneNumber}
                                setValue={telephoneNumber}
                                placeholder={"xxxxxxxx"}
                            />
                        </div>
                    )}
                    options={(
                        <div className="clinic-contact-options-container">
                            <ColoredButton
                                text="Register"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default ClinicContact;