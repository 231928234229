import { Box, Button, Modal } from "@mui/material";
import { DELETE_DATA } from "./Constant";

function BiggerCustomModal({ open, onClose, children, disableClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <p className="text-lg"> {children} </p>
          <div className="flex justify-end w-[90%] mb-5">
            {disableClose === true ? (
              ""
            ) : (
              <Button onClick={onClose}> Close </Button>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default BiggerCustomModal;
