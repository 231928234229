import React, { useCallback, useState } from "react";
import './InboxPage.css'

import { LuMail, LuTimer, LuPiggyBank, LuCheckCircle2 } from "react-icons/lu";

import GlobalVariables from "../../../globalVariables";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const InboxPage = () => {
    const navigate = useNavigate();

    const [patientID, setPatientID] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [notificationIsOpened, setNotificationIsOpened] = useState('');

    const getPatientID = useCallback(async () => {
        let patient = await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
                method: 'GET',
                headers: { 
                    token : localStorage.token,
                    "ngrok-skip-browser-warning": true, 
                    'Content-Type' : 'application/json'
                }
            }
        )
        if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
        patient = await patient.json();
        setPatientID(patient.user)
    }, []);

    const getNotifications = useCallback(async () => {
        // get today's date
        const today = new Date();
        today.setDate(today.getDate());
        // get invoice
        let requestNotification;
        try {
            requestNotification = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=notification&patientid=${patientID}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        if(!requestNotification.ok) return console.log({ "message" : "error", "error" : requestNotification });
        requestNotification = await requestNotification.json();

        let notificationsFormatted = requestNotification.map((notification) => {
            let formatteddate = new Date();
            if(notification.date !== null) {
                formatteddate = new Date(notification.date);
                if(today.toDateString() === formatteddate.toDateString()) {
                    if(today.getHours() === formatteddate.getHours()) {
                        if(today.getMinutes() === formatteddate.getMinutes()) {
                            formatteddate = `${today.getSeconds() - formatteddate.getSeconds()} second${today.getSeconds() - formatteddate.getSeconds() === 1 ? '' : 's'} ago`;
                        } else formatteddate = `${today.getMinutes() - formatteddate.getMinutes()} minute${today.getMinutes() - formatteddate.getMinutes() === 1 ? '' : 's'} ago`;
                    } else formatteddate = `${today.getHours() - formatteddate.getHours()} hour${today.getHours() - formatteddate.getHours() === 1 ? '' : 's'} ago`;
                } else formatteddate = formatteddate.toLocaleDateString();
            }
            return {
                id: notification.id,
                title : notification.title,
                description : notification.description,
                status : notification.status,
                type : notification.type,
                date : formatteddate
            }
        });
        notificationsFormatted = await Promise.all(notificationsFormatted);

        // set balance ammount
        setNotifications(notificationsFormatted);
    }, [patientID]);

    const openNotification = useCallback(async (id, status) => {
        // set notification is opened
        setNotificationIsOpened(notificationIsOpened === id ? null : id);

        // check if notification status is unread
        if(status === "unread") {
            try {
                await fetch(
                    `${GlobalVariables.WEB_ADDRESS_PORT}/?type=notification&id=${id}`, {
                        method: 'PATCH',
                        body: JSON.stringify(
                            {
                                status: "read"
                            }
                        ),
                        headers: { 
                            token : localStorage.token,
                            "ngrok-skip-browser-warning": true, 
                            'Content-Type' : 'application/json'
                        }
                    }
                );
            } catch (error) {
                console.log("...");
            }
        }
    }, [notificationIsOpened]);

    useEffect(() => {
        getPatientID();
        if(patientID) {
            getNotifications();
        }
    }, [getPatientID, patientID, getNotifications, openNotification])

    return (
        <div className="inbox-container">
            <div className="inbox-header">
                <div className="inbox-header-title">Inbox</div>
            </div>
            <div className="inbox-body">
                {notifications.map((notification) => (
                    <div 
                        className={`inbox-notification ${(notificationIsOpened === notification.id) ? "opened" : ""}`} 
                        id={notification.id} 
                        key={notification.id}
                        style={{
                            color: (notificationIsOpened === notification.id) ? "" : (notification.status !== "unread") ? "#9b9b9b" : "",
                            borderColor: (notificationIsOpened === notification.id) ? "#c3b1e1" : ""
                        }}
                        onClick={() => {
                            openNotification(notification.id, notification.status);
                            if(notificationIsOpened === notification.id) {
                                if(notification.type === "appointment reminder") {
                                    navigate("/patient/dashboard");
                                } else if(notification.type === "payment reminder") {
                                    navigate("/patient/billing");
                                }
                            }
                        }}
                    >
                        <div className="inbox-notification-header">
                            <div className="inbox-notification-title-container">
                                {(notification.type === "appointment reminder") ?
                                    <LuTimer/>
                                    : (notification.type === "payment reminder") ?
                                        <LuPiggyBank/>
                                        : <LuMail/>
                                }
                                <div className="inbox-notification-title">{notification.title}</div>
                            </div>
                            {(notification.status === "unread") ?
                                <div className="inbox-notification-unread"/>
                                : <LuCheckCircle2/>
                            }
                        </div>
                        <div className="inbox-notification-date">{notification.date}</div>
                        <div 
                            className={`inbox-notification-description ${(notificationIsOpened === notification.id) ? "opened" : ""}`}
                        >
                            {notification.description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InboxPage;