import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./CivilDetails.css";

import {
  Dropdown,
  Textfield,
  ColoredButton,
  RegisterContainer,
} from "../../../../../components";

import GlobalVariables from "../../../../../globalVariables";
import { Button } from "@mui/material";

const CivilDetails = ({ setAuth, setType }) => {
  const location = useLocation();
  let { patientInformation } = location.state || {};

  const civilStatuses = ["Single", "Married", "Widowed", "Legally Separated"];

  const [civilStatusIsEmpty, setCivilStatusIsEmpty] = useState(false);
  const [civilStatus, setCivilStatus] = useState("");

  const [occupationIsEmpty, setOccupationIsEmpty] = useState(false);
  const [occupation, setOccupation] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const register = async () => {
    // check if civil status field is empty
    if (!civilStatus.trim().length) return setCivilStatusIsEmpty(true);
    else setCivilStatusIsEmpty(false);

    // check if occupation field is empty
    if (!occupation.trim().length) return setOccupationIsEmpty(true);
    else setOccupationIsEmpty(false);

    patientInformation = {
      email: patientInformation.email,
      numbermobile: patientInformation.numbermobile,
      password: patientInformation.password,
      namelast: patientInformation.namelast,
      namefirst: patientInformation.namefirst,
      namemiddle: patientInformation.namemiddle,
      dateofbirth: patientInformation.dateofbirth,
      patientgender: patientInformation.patientgender,
      patientweight: patientInformation.patientweight,
      patientheight: patientInformation.patientheight,
      patientaddress: patientInformation.patientaddress,
      civilstatus: civilStatus,
      occupation: occupation,
      profilepicture: selectedFile,
    };

    // request
    let request;
    try {
      request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/auth/patient/signup/register`,
        {
          method: "POST",
          body: JSON.stringify(patientInformation),
          headers: {
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();

    console.log(request);

    localStorage.setItem("token", request.token);
    setAuth(true);
    setType("patient");
  };

  // Function to handle input change in the Occupation field
  const handleOccupationChange = (value) => {
    // Regular expression to check if the input contains only letters
    const onlyLettersRegex = /^[a-zA-Z\s]*$/;
    // Check if the input matches the regex
    if (onlyLettersRegex.test(value) || value === "") {
      // If it matches, update the Occupation state
      setOccupation(value);
      // Clear the error message
      setOccupationIsEmpty(false);
    } else {
      // If it doesn't match, display the error message
      setOccupationIsEmpty(true);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
  };

  return (
    <div className="civil-details">
      <div
        className="civil-details-container"
        onKeyDown={(event) => {
          if (event.key === "Enter") register();
        }}
      >
        <RegisterContainer
          header="We're almost there..."
          subheader="Please enter your civil status and occupation."
          fields={
            <div className="civil-details-container-fields">
              <Dropdown
                items={civilStatuses}
                title="Civil Status"
                prompt="This field is required!"
                isEmpty={civilStatusIsEmpty}
                getValue={setCivilStatus}
              />
              <Textfield
                title="Occupation"
                prompt="This field is required!"
                isEmpty={occupationIsEmpty}
                getValue={handleOccupationChange} // Change to handleOccupationChange function
              />
              <input
                className="mt-5"
                type="file"
                name="file"
                accept="image/*"
                onChange={handleFileChange}
                id="file-upload"
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  sx={{ marginTop: "15px" }}
                >
                  Upload Profile Photo
                </Button>
                {selectedFile === null ? "" : <div> File uploaded</div>}
              </label>
            </div>
          }
          options={
            <div className="civil-details-container-options">
              <ColoredButton
                text="Register"
                color="#ffffff"
                backgroundColor="#5b346b"
                backgroundColorOnHover="#6d3f80"
                onClick={register}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CivilDetails;
