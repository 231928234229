import { Button, Modal, Box, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { EDIT_DATA, PUSH_DATA } from "./Constant";
import { useMediaQuery } from "react-responsive";

// open={state.showModal}
// onClose={closeModal}
// state={state}
// handleChange={handleChange}
// editMode={state.editMode}
// setError={state.setError}

function ExpenseModal({ open, onClose, state, dispatch, editMode, setError }) {
  // {expensetype: '', item: '', quantity: '', basecost: '', expense: ''}
  const [selectState, setSelectState] = useState("");
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" }); 
  useEffect(() => {
    setSelectState(state);
  }, [state]);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: isPhone ? "80%" : "60%",
          height: isPhone ? "60%" : "60%",
          padding: "32px",
          overflowY: "auto",
        }}
      >
        <div>
          <div className="mb-5">
            <TextField
              required
              id="expense-type-field"
              label="Type of Expense"
              helperText=""
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  expensetype: event.target.value,
                }))
              }
              value={selectState.expensetype}
              error={setError}
            />
          </div>
          <div className="mb-5">
            <TextField
              required
              id="set-name-field"
              label="Name"
              helperText=""
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  item: event.target.value,
                }))
              }
              value={selectState.item}
              error={setError}
            />
          </div>
          <div className="mb-5">
            <TextField
              id="set-quantity-field"
              label="Quantity"
              helperText=""
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  quantity: event.target.value.replace(/[^0-9]/g, ""),
                }))
              }
              value={selectState.quantity}
              error={setError}
            />
          </div>
          <div className="mb-5">
            <TextField
              id="set-basecost-field"
              label="Base Cost"
              helperText=""
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  basecost: event.target.value.replace(/[^0-9]/g, ""),
                }))
              }
              value={selectState.basecost}
              error={setError}
            />
          </div>
          <div className="mb-5">
            <TextField
              id="set-expense-field"
              label="Expense"
              helperText=""
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  expense: event.target.value.replace(/[^0-9]/g, ""),
                }))
              }
              value={selectState.expense}
              error={setError}
            />
          </div>
          <Button
            id={"save-expense-button"}
            onClick={() =>
              dispatch({
                type: editMode === true ? EDIT_DATA : PUSH_DATA,
                payload: selectState,
              })
            }
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default ExpenseModal;
