import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './AuthenticationPage.css';

import { Textfield } from "../../components";

import { logo, logo_icon } from "../../assets";

import GlobalVariables from "../../globalVariables";

import validator from "validator";

const AuthenticationPage = ({ setAuth, setType }) => {
    const navigate = useNavigate();
    let userCredentials = {};

    const [emailOrNumberIsEmpty, setEmailorNumberIsEmpty] = useState('');
    const [emailOrNumberPrompt, setEmailOrNumberPrompt] = useState('')
    const [emailOrNumber, setEmailOrNumber] = useState('');

    const [passwordIsEmpty, setPasswordIsEmpty] = useState('');
    const [passwordPrompt, setPasswordPrompt] = useState('');
    const [password, setPassword] = useState('');

    const toSignin = async () => {
        // check if email or number is empty
        if(!emailOrNumber.trim().length) {
            setEmailOrNumberPrompt("This field is required.");
            return setEmailorNumberIsEmpty(true);
        } else setEmailorNumberIsEmpty(false);

        // check if password is empty
        if(!password.trim().length) {
            setPasswordPrompt("This field is required.");
            return setPasswordIsEmpty(true);
        } else setPasswordIsEmpty(false);

        // check if email or number is valid
        if(validator.isEmail(emailOrNumber)) {
            userCredentials = {
                email: emailOrNumber,
                numbermobile: '',
                password: password
            };
        } else if(validator.isMobilePhone(emailOrNumber)) {
            userCredentials = {
                email: '',
                numbermobile: emailOrNumber,
                password: password
            };
        } else {
            setEmailOrNumberPrompt("Enter a valid email or number.");
            return setEmailorNumberIsEmpty(true);
        }

        // request login to server
        let request;
        try {
            request = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/auth/signin`, {
                    method: 'POST',
                    body: JSON.stringify(userCredentials),
                    headers: {
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json' 
                    }
                }
            );
        } catch (error) {
            setEmailOrNumberPrompt(`${error}`);
            return setEmailorNumberIsEmpty(true);
        }

        // check if request is ok
        if(!request.ok) {
            request = await request.json();
            if(request.message === "Password is incorrect.") {
                setPasswordPrompt(request.message);
                return setPasswordIsEmpty(true);
            }
            setEmailOrNumberPrompt(request.message);
            return setEmailorNumberIsEmpty(true);
        } else {
            setEmailOrNumberPrompt("");
            setEmailorNumberIsEmpty(false);
            setPasswordPrompt("");
            setPasswordIsEmpty(false);
        };
        request = await request.json();

        localStorage.setItem("token", request.token);
        localStorage.setItem("login", 1);
        setAuth(true);
        setType(request.usertype);
    };

    const toSignup = () => {
        navigate('/auth/signup');
    };

    return (
        <div className="authentication-page">
            <div className="authentication-page-main-container">
                <div className="greeting-container">
                    <div className="logo-container">
                        <img className="logo-icon" src={logo_icon} alt=""/>
                        <img className="logo-img" src={logo} alt=""/>
                    </div>
                    <div className="greeting">Welcome!</div>
                </div>
                <div className="field-main-container" 
                    onKeyDown={(event) => {
                        if(event.key === "Enter") toSignin();
                    }}
                >
                    <Textfield 
                        title="Email"
                        id="emailinput"
                        prompt={emailOrNumberPrompt}
                        isEmpty={emailOrNumberIsEmpty}
                        getValue={setEmailOrNumber}
                        setValue={emailOrNumber}
                    />
                    <Textfield 
                        title="Password"
                        id="passwordinput"
                        prompt={passwordPrompt}
                        type="password"
                        isEmpty={passwordIsEmpty}
                        getValue={setPassword}
                        setValue={password}
                    />
                </div>
                <div className="option-container">
                    <div className="sign-up-button" onClick={toSignup}>Sign Up</div>
                    <div className="sign-in-button">
                        <div id="signinbutton" className="sign-in-text" onClick={toSignin}>Sign In</div>
                    </div>
                </div>
            </div>
            <div className="images"></div>
        </div>
    );
};

export default AuthenticationPage;