import React, { useEffect, useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import "./HomePage.css";

import { Topbar, SideNavigation } from "../../../components";
import { Navigate, Route, Routes } from "react-router-dom";
import Modal from "react-modal";

import { Dashboard, BillingPage, InboxPage, MedicalHistory, AccountPage } from "../../index";

import GlobalVariables from "../../../globalVariables";

const HomePage = ({ setIsAuthenticated, setUserType }) => {
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [patientID, setPatientID] = useState('');
  const [hasNewInbox, setHasNewInbox] = useState(false);

  const [medicalRecordModalIsOpen, setMedicalRecordModalIsOpen] = useState(false);

  const [sideNavIsActive, setSideNavIsActive] = useState(() => {
    const storedValue = sessionStorage.getItem("sideNavIsActive");
    return storedValue ? JSON.parse(storedValue) : !isPhone;
  });

  const getPatientID = useCallback(async () => {
    let patient = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
            method: 'GET',
            headers: { 
                token : localStorage.token,
                "ngrok-skip-browser-warning": true, 
                'Content-Type' : 'application/json'
            }
        }
    );
    if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
    patient = await patient.json();
    setPatientID(patient.user)
  }, []);

  const checkIfPatientHasMedicalHistory = useCallback(async () => {
    // check if patient has a medical record
    let response;
    try {
      response = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=medicalhistory&patientid=${patientID}`, {
            method: 'GET',
            headers: { 
                token : localStorage.token,
                "ngrok-skip-browser-warning": true, 
                'Content-Type' : 'application/json'
            }
        }
      )
    } catch (error) {
      console.log("...");
    }
    response = await response.json();
    console.log(response);
    if(!Object.keys(response).length) return setMedicalRecordModalIsOpen(true);
    else return setMedicalRecordModalIsOpen(false);
  }, [patientID]);

  useEffect(() => {
    getPatientID();
    sessionStorage.setItem("sideNavIsActive", JSON.stringify(sideNavIsActive));
    if(patientID) {
      checkIfPatientHasMedicalHistory();
    }
    if(isLoggedOut) {
      setIsAuthenticated(false);
      setUserType('');
    }
  }, [getPatientID, patientID, sideNavIsActive, isLoggedOut, setIsAuthenticated, setUserType, checkIfPatientHasMedicalHistory]);

  return (
    <div className="home-page" id="homePage">
      <Topbar isActive={setSideNavIsActive} hasNewInbox={hasNewInbox}/>
      <div className="main-container">
        <SideNavigation isActive={sideNavIsActive} isLoggedOut={setIsLoggedOut} getActiveState={setSideNavIsActive} setHasNewInbox={setHasNewInbox} />
        <div
          className={`page-container ${sideNavIsActive ? "" : "hidden-nav"}`}
        >
          <Modal
            className="medical-record"
            isOpen={medicalRecordModalIsOpen}
          >
            <MedicalHistory
              onSubmit={checkIfPatientHasMedicalHistory}
            />
          </Modal>
          <Routes>
            <Route path="/" element={<Navigate to="dashboard"/>} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/billing" element={<BillingPage/>} />
            <Route path="/inbox" element={<InboxPage/>} />
            <Route path="/account" element={<AccountPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
