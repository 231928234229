import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    main_grey: {
      main: '#212121', 
    },
  },
});

export default theme;
