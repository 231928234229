import { Button, Modal, Box, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { JSON_NAME, TOKEN_NAME } from "./Constant";
import globalVariables from "../../globalVariables";
import { useMediaQuery } from "react-responsive";

// open={state.showModal}
// onClose={closeModal}
// state={state}
// handleChange={handleChange}
// editMode={state.editMode}
// setError={state.setError}

function ProcedureModal({ open, onClose, reload }) {
  // {expensetype: '', item: '', quantity: '', basecost: '', expense: ''}
  const [selectState, setSelectState] = useState({
    procedureName: "",
    procedureDescription: "",
    procedureCost: "",
  });
  const [error, setError] = useState(false);
  const recievedToken = localStorage.getItem(TOKEN_NAME);

  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    setSelectState({
      procedureName: "",
      procedureDescription: "",
      procedureCost: "",
    });
  }, [open])

  const createProdedure = () => {
    // || selectState.procedureCost === ""
    if (selectState.procedureName === "") {
      setError(true);
    } else {
      setError(false);
      onClose();
      const storedData = localStorage.getItem(JSON_NAME);
      try {
        fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=postprocedure`, {
          method: "POST",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            patientid: storedData,
            procedureName: selectState.procedureName,
            procedureDescription: selectState.procedureDescription,
            procedureCost: selectState.procedureCost,
            issuer: localStorage.currentUser
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then(() => {
            reload();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error at: ", error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: isPhone ? "80%" : "40%",
          height: "40%",
          padding: "2%",
          maxWidth: "80vw",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <div className="grid grid-cols-1 gap-5">
          <TextField
            required
            id="procedure-name"
            label="Procedure Name"
            helperText=""
            onChange={(event) =>
              setSelectState((prevState) => ({
                ...prevState,
                procedureName: event.target.value,
              }))
            }
            value={selectState.procedureName}
            error={error}
          />
          <TextField
            required
            id="procedure-payment-cost"
            label="Procedure Cost"
            helperText=""
            onChange={(event) =>
              setSelectState((prevState) => ({
                ...prevState,
                procedureCost: event.target.value.replace(/[^0-9]/g, ""),
              }))
            }
            value={selectState.procedureCost}
            error={error}
          />
          <TextField
            id="procedure-description"
            label="Procedure Description"
            helperText=""
            onChange={(event) =>
              setSelectState((prevState) => ({
                ...prevState,
                procedureDescription: event.target.value,
              }))
            }
            value={selectState.procedureDescription}
            error={error}
          />
          <Button id={"save-procedure"} onClick={() => createProdedure()}>
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default ProcedureModal;
