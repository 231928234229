import React, { useCallback, useEffect, useState } from "react";
import './AppointmentCard.css'
import GlobalVariables from "../../../globalVariables";

const AppointmentCard = ({ status, date, purpose, dentist, id, onUpdate }) => {
    const [isAppointmentRequest, setIsAppointmentRequest] = useState(true);
    const [appointmentStatusColor, setAppointmentStatusColor] = useState("orange");
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [showCancelReasonModal, setShowCancelReasonModal] = useState(false);
    const [cancellationReasons, setCancellationReasons] = useState([]);
    const [rescheduleStartTime, setRescheduleStartTime] = useState("");
    const [rescheduleEndTime, setRescheduleEndTime] = useState("");
    const [cancelActionTaken, setCancelActionTaken] = useState(false);
    const [previousCancelAction, setPreviousCancelAction] = useState(false);
    const [cancellationReason, setCancellationReason] = useState("");

    const onAttendHandler = async () => {
        await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateappointment&id=${id}`, {
                method: 'PATCH',
                body: JSON.stringify(
                    {
                        status: "active"
                    }
                ),
                headers: { 
                    token : localStorage.token,
                    "ngrok-skip-browser-warning": true, 
                    'Content-Type' : 'application/json'
                }
            }
        );
        onUpdate((prevState) => !prevState);
        setPreviousCancelAction(cancelActionTaken); // Update previous cancel action
        setCancelActionTaken(false); // Reset cancel action
    }
    const handleCancellationReasonChange = (e) => {
        setCancellationReason(e.target.value);
    };
    const handleRescheduleSubmit = async () => {
        if (rescheduleStartTime && rescheduleEndTime) {
            const response = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateappointment&id=${id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(
                        {
                            rescheduletimestart: rescheduleStartTime,
                            rescheduletimeend: rescheduleEndTime,
                            status: "rescheduling"
                        }
                    ),
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
            if (response.ok) {
                setShowRescheduleModal(false);
                onUpdate((prevState) => !prevState);
            } else {
                // Handle error
            }
        } else {
            // Handle validation error
        }
    };
    
    const handleCancellationReasonSubmit = async () => {
        if (cancellationReason.trim() !== "") {
            const response = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateappointment&id=${id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(
                        {
                            status: "cancelled",
                            cancellationreason: cancellationReason
                        }
                    ),
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
            if (response.ok) {
                setShowCancelReasonModal(false);
                onUpdate((prevState) => !prevState);
                setCancellationReason(""); // Clear the cancellation reason input
            } else {
                // Handle error
            }
        } else {
            // Handle validation error
        }
    };
    
    const onCancelHandler = async () => {
        setShowRescheduleModal(false); // Close reschedule modal
        setCancelActionTaken(true);
        setPreviousCancelAction(cancelActionTaken); // Update previous cancel action
    };
    const revertToPreviousState = () => {
        setShowRescheduleModal(false); // Close reschedule modal
        setShowCancelReasonModal(false); // Close cancel reason modal
        setCancelActionTaken(previousCancelAction); // Revert to previous cancel action
    };

    const reschedulebutton = async () => {
        setShowRescheduleModal(!showRescheduleModal);
        setShowCancelReasonModal(false);
    };

    const proceedcancellation = async () => {
        setShowCancelReasonModal(!showCancelReasonModal);
        setShowRescheduleModal(false);
    };

    const cancelAppointment = async () => {
        await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/?type=deleteappointment&id=${id}`, {
                method: 'DELETE',
                headers: { 
                    token : localStorage.token,
                    "ngrok-skip-browser-warning": true, 
                    'Content-Type' : 'application/json'
                }
            }
        );
        onUpdate((prevState) => !prevState);
    };

    const setStatusColor = useCallback(() => {
        if(status === "requesting appointment") return setAppointmentStatusColor("orange");
        if(status === "requesting cancellation") return setAppointmentStatusColor("red");
        if(status === "active") return setAppointmentStatusColor("green");
        if(status === "pending") return setAppointmentStatusColor("#ff0090");
        if(status === "done") return setAppointmentStatusColor("#00d0ff");
        return setAppointmentStatusColor("orange");
    }, [status])

    useEffect(() => {
        if(status === "requesting appointment") setIsAppointmentRequest(true);
        else setIsAppointmentRequest(false);
        setStatusColor();
    }, [status, setStatusColor]);



    const handleCancelReasonSubmit = () => {
        // Validate cancellation reasons
        if (cancellationReasons.length === 0) {
            alert("Please select a cancellation reason.");
            return; // Exit the function if validation fails
        }
    
        // Validate rescheduling
        const isValidReschedule = validateReschedule();
    
        if (isValidReschedule) {
            // Perform rescheduling logic
            // handleRescheduleSubmit();
            console.log("Rescheduling logic goes here");
        } else {
            alert("Invalid reschedule time. Please select a valid time range within clinic hours and in the future.");
        }
    
        // Proceed with cancellation if both validations pass
        setShowCancelReasonModal(false);
        cancelAppointment();
    };
    
    const validateReschedule = () => {
        // Convert start and end time strings to Date objects
        const startTime = new Date(rescheduleStartTime);
        const endTime = new Date(rescheduleEndTime);
    
        // Get current date and time
        const currentDate = new Date();
    
        // Get clinic opening and closing times
        const clinicOpeningTime = new Date();
        clinicOpeningTime.setHours(8, 0, 0, 0); // 8:00 AM
    
        const clinicClosingTime = new Date();
        clinicClosingTime.setHours(18, 0, 0, 0); // 6:00 PM
    
        // Check if selected times are within clinic hours and in the future
        return startTime >= clinicOpeningTime && endTime <= clinicClosingTime && startTime > currentDate && endTime > currentDate;
    };
    return (
        <div className="appointment-card">
            <div className="appointment-card-header">
                <div className="appointment-card-header-status">
                    <div className="appointment-card-header-status-light" style={{backgroundColor: appointmentStatusColor}}/>
                    <div className="appointment-card-header-status-text">{status}</div>
                </div>
                <div className="appointment-card-header-date">{date}</div>
            </div>
            <div className="appointment-card-body">
                <div className="appointment-card-body-for">{purpose}</div>
                <div className="appointment-card-body-dentist">{isAppointmentRequest ? "no dentist" : `Dr. ${dentist}`}</div>
            </div>
            <div className="appointment-card-footer">
                {cancelActionTaken || previousCancelAction ? ( // Revert to previous state or current cancel action state
                    <div>
                        <div className="appointment-card-footer-button" onClick={reschedulebutton}>Reschedule</div>
                        <div className="appointment-card-footer-button" onClick={proceedcancellation}>Proceed Cancellation</div>
                        <div className="appointment-card-footer-button" onClick={revertToPreviousState}>Go to Previous</div>
                    </div>
                ) : (
                    <div className="appointment-card-footer-button" onClick={onCancelHandler} style={{ display: (status === "done" || status === "requesting cancellation" || status === "accept") ? "none" : "block" }}>Cancel</div>
                )}
                {(status !== "requesting appointment" && status !== "requesting cancellation" && status !== "active" && status !== "done" && !cancelActionTaken) && (
                    <div className="appointment-card-footer-button" onClick={onAttendHandler}>Attend</div>
                )}
            </div>
            {showRescheduleModal && (
                <div className="reschedule-modal">
                    <h2>Select Date Range for Reschedule within clinic hours 6 AM - 8 PM</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td><label htmlFor="start-time">Start Time:</label></td>
                                <td><input type="datetime-local" id="start-time" value={rescheduleStartTime} onChange={(e) => setRescheduleStartTime(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td><label htmlFor="end-time">End Time:</label></td>
                                <td><input type="datetime-local" id="end-time" value={rescheduleEndTime} onChange={(e) => setRescheduleEndTime(e.target.value)} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={handleRescheduleSubmit}>Submit</button> {/* New submit button */}
                </div>
            )}
            {showCancelReasonModal && (
                <div className="cancel-reason-modal">
                    <h2>Provide Reasons for Cancellation</h2>
                    <input type="text" value={cancellationReason} onChange={handleCancellationReasonChange} placeholder="Enter cancellation reason" />
                    <button onClick={handleCancellationReasonSubmit}>Submit</button> {/* New submit button */}
                </div>
            )}
        </div>
    );
};

export default AppointmentCard;
