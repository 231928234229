import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { produce } from "immer";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DELETE_DATA,
  JSON_NAME,
  SET_DATA,
  SET_EDIT,
  SET_FALSE,
  SET_TRUE, 
  TOKEN_NAME,
} from "../../../components/clinic/Constant";
import globalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TRUE:
      state.openModal = true;
      return;
    case SET_FALSE:
      state.openModal = false;
      return;
    case DELETE_DATA:
      try {
        const response = fetch(
          `${globalVariables.WEB_ADDRESS_PORT}//?type=deletepatient&patientid=${action.payload}`,
          {
            method: "DELETE",
            headers: {
              "ngrok-skip-browser-warning": true, 
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const fetchDataResponse = fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/web/patient/?type=information`
        );
        const data = fetchDataResponse.json();

        return { ...state, openModal: false, userData: data };
      } catch (error) {
        console.error("Error deleting data:", error);
        return state;
      }
    case SET_DATA:
      state.userData = action.payload;
      return;
    case SET_EDIT:
      state.row = action.payload;
      return;

    default:
      throw new Error("unexpected action type" + action.type);
  }
};

function RecordsPage({userType}) {
  const [error, setError] = useState(false);
  const [redState, redDispatch] = useReducer(produce(reducer), {
    row: {},
    userData: [],
  });
  const navigate = useNavigate();
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const textClass = classNames("font-bold", isPhone ? "text-l" : "text-xl");

  const tableStyles = {
    minWidth: isPhone ? '100%' : 650,
    '& .MuiTableCell-root': {
      padding: isPhone ? '8px' : 'default',
      fontSize: isPhone ? '12px' : 'default', // Adjust font size for smaller screens
    },
  };

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_NAME)
    if (token && userType === "clinic") {
      try {
        fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=information&method=records`, {
          headers: { "ngrok-skip-browser-warning": true, token: token },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {redDispatch({ type: SET_DATA, payload: data })})
          .catch((error) => {
            setError(true);
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        setError(true);
        console.error("Error at: ", error);
      }
    }
  }, [userType]);

  function handleOnClick(row) {
    const token = localStorage.getItem(TOKEN_NAME)
    if (token) {
      localStorage.setItem(JSON_NAME, row.id);
      navigate("/clinic/records/patient");
    }
  }

  return (
    <div className="mt-10 w-[95%]">
      <TableContainer component={Paper}>
        <Table sx={tableStyles} aria-label="simple table">
          <TableHead>
            <TableRow className="bg-[#ac7de8]">
              <TableCell>
                <p className={textClass}>Last Name</p>
              </TableCell>
              <TableCell>
                <p className={textClass}>First Name</p>
              </TableCell>
              <TableCell>
                <p className={textClass}>Middle Name</p>
              </TableCell>
              <TableCell>
                <p className={textClass}>Email</p>
              </TableCell>
              <TableCell>
                <p className={textClass}>Contact Number </p>
              </TableCell>
            </TableRow>
          </TableHead>
          {error ? (
            <TableCell> There is no connection </TableCell>
          ) : (
            <TableBody>
              {redState.userData.map((row) => (
                <TableRow
                  key={row.id}
                  id={`${row.namelast}-row`}
                  onClick={() => handleOnClick(row)}
                  className={"hover:bg-gray-200"}
                >
                  <TableCell> {row.namelast} </TableCell>
                  <TableCell> {row.namefirst} </TableCell>
                  <TableCell> {row.namemiddle} </TableCell>
                  <TableCell> {row.email} </TableCell>
                  <TableCell> {row.numbermobile} </TableCell>
                </TableRow>
              ))}
            </TableBody>
            // {namelast: "One", namefirst: "Patient", namemiddle: "Number", email: "patientnumberone@gmail.com", numbermobile: "1234567"}
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default RecordsPage;
