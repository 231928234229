import React, { useEffect, useState } from "react";
import './ClinicDetails.css';

import { RegisterContainer, Textfield, ColoredButton } from "../../../../../components";

import validator from "validator";
import { useNavigate } from "react-router-dom";
import GlobalVariables from "../../../../../globalVariables";

const ClinicDetails = () => {
    const navigate = useNavigate();

    const [emailOrNumberIsEmpty, setEmailOrNumberIsEmpty] = useState(false);
    const [emailOrNumber, setEmailOrNumber] = useState('');
    const [emailOrNumberPrompt, setEmailOrNumberPrompt] = useState('This field is required!');

    const [passwordIsEmpty, setPasswordIsEmpty] = useState(false);
    const [password, setPassword] = useState('');

    const next = async () => {
        let clinicDetails;
        // check if email or number field is empty
        if(!emailOrNumber.trim().length) return setEmailOrNumberIsEmpty(true);
        else setEmailOrNumberIsEmpty(false);

        // check if email or number is valid
        if(validator.isEmail(emailOrNumber)) {
            clinicDetails = {
                email: emailOrNumber,
                numbermobile: '',
            }
            setEmailOrNumberPrompt('');
            setEmailOrNumberIsEmpty(false);
        } else if (validator.isMobilePhone(emailOrNumber)) {
            clinicDetails = {
                email: '',
                numbermobile: emailOrNumber,
            }
            setEmailOrNumberPrompt('');
            setEmailOrNumberIsEmpty(false);
        } else {
            setEmailOrNumberPrompt('Enter a valid email.');
            return setEmailOrNumberIsEmpty(true);
        }

        // check if password is empty
        if(!password.trim().length) return setPasswordIsEmpty(true);
        else setPasswordIsEmpty(false);

        // set clinic details
        clinicDetails = {
            email: clinicDetails.email,
            numbermobile: clinicDetails.numbermobile,
            password: password
        }

        // send request to server
        let request = await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/auth/clinic/signup/clinic-details`, {
                method: 'POST',
                body: JSON.stringify(clinicDetails),
                headers: { "ngrok-skip-browser-warning": true, 'Content-Type' : 'application/json'}
            }
        );
        if(!request.ok) {
            console.log({ "error" : request });
            if(request.emailOrNumberExists) {
                setEmailOrNumberPrompt('Email already in use.');
                setEmailOrNumberIsEmpty(true);
            } else {
                setEmailOrNumberPrompt('');
                setEmailOrNumberIsEmpty(false);
            }
            return;
        };
        request = await request.json();

        // set clinic details
        clinicDetails = {
            email: request.email,
            numbermobile: request.numbermobile,
            password: request.password
        }

        // navigate to next page
        navigate('/auth/clinic/signup/user-name', { state: { clinicDetails } });
    };

    useEffect(() => {

    }, [emailOrNumberPrompt]);

    return (
        <div className="clinic-details">
            <div className="clinic-details-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="Hello, new dentist!"
                    subheader="Please enter your email and password."
                    fields={(
                        <div className="clinic-details-fields-container">
                            <Textfield 
                                title="Email"
                                prompt={emailOrNumberPrompt}
                                isEmpty={emailOrNumberIsEmpty}
                                getValue={setEmailOrNumber}
                                setValue={emailOrNumber}
                            />
                            <Textfield 
                                title="Password"
                                type="password"
                                prompt="This field is required!"
                                isEmpty={passwordIsEmpty}
                                getValue={setPassword}
                                setValue={password}
                            />
                        </div>
                    )}
                    options={(
                        <div className="clinic-details-options-container">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default ClinicDetails;