import React, { useState } from "react";
import './ClinicName.css';
import { RegisterContainer, ColoredButton, Textfield } from "../../../../../components";
import { useLocation, useNavigate } from "react-router-dom";

const ClinicName = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { clinicDetails } = location.state || {};

    // Regular expression for validating names with letters, special characters, and accents
    const nameRegex = /^[a-zA-ZÀ-ÿ\s]+$/;
   
    // firstname field variables
    const [firstNameIsEmpty, setFirstNameIsEmpty] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');

    // middlename field variables
    const [middleNameIsEmpty, setMiddleNameIsEmpty] = useState(false);
    const [middleName, setMiddleName] = useState('');
    const [middleNameError, setMiddleNameError] = useState('');

    // lastname field variables
    const [lastNameIsEmpty, setLastNameIsEmpty] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');

    const validateName = (name) => {
        return nameRegex.test(name);
    };

    const handleFirstNameChange = (value) => {
        setFirstName(value);
        if (!validateName(value)) {
            setFirstNameError("This field accepts letters or names with accents or special characters only.");
        } else {
            setFirstNameError('');
        }
    };

    const handleMiddleNameChange = (value) => {
        setMiddleName(value);
        if (!validateName(value)) {
            setMiddleNameError("This field accepts letters or names with accents or special characters only.");
        } else {
            setMiddleNameError('');
        }
    };

    const handleLastNameChange = (value) => {
        setLastName(value);
        if (!validateName(value)) {
            setLastNameError("This field accepts letters or names with accents or special characters only.");
        } else {
            setLastNameError('');
        }
    };

    const next = () => {
        // check if firstname is empty or invalid
        if(!firstName.trim().length || firstNameError) return setFirstNameIsEmpty(true);
        else setFirstNameIsEmpty(false);

        // check if middlename is empty or invalid
        if(!middleName.trim().length || middleNameError) return setMiddleNameIsEmpty(true);
        else setMiddleNameIsEmpty(false);

        // check if lastname is empty or invalid
        if(!lastName.trim().length || lastNameError) return setLastNameIsEmpty(true);
        else setLastNameIsEmpty(false);

        // set clinic details
        const clinicNoPRC = {
            email: clinicDetails.email,
            numbermobile: clinicDetails.numbermobile,
            password: clinicDetails.password,
            namefirst: firstName,
            namemiddle: middleName,
            namelast: lastName
        };

        // navigate to next page
        navigate('/auth/clinic/signup/user-register', { state: { clinicNoPRC } });
    };

    return (
        <div className="clinic-name">
            <div className="clinic-name-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header={`So, Doctor ... ?`}
                    subheader={"What should we call you, doctor?"}
                    fields={
                        <div className="clinic-name-fields-container">
                            <div className="clinic-name-firstname-field-container">
                                <Textfield 
                                    title="First name"
                                    prompt={firstNameError || "This field is required!"}
                                    isEmpty={firstNameIsEmpty}
                                    getValue={handleFirstNameChange}
                                    setValue={firstName}
                                />
                            </div>
                            <div className="clinic-name-middlename-and-lastname-fields-container">
                                <Textfield 
                                    title="Middle name"
                                    prompt={middleNameError || "This field is required!"}
                                    isEmpty={middleNameIsEmpty}
                                    getValue={handleMiddleNameChange}
                                    setValue={middleName}
                                />
                                <Textfield 
                                    title="Last name"
                                    prompt={lastNameError || "This field is required!"}
                                    isEmpty={lastNameIsEmpty}
                                    getValue={handleLastNameChange}
                                    setValue={lastName}
                                />
                            </div>
                        </div>
                    }
                    options={
                        <div className="clinic-name-options-container">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default ClinicName;
