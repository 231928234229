import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import './Topbar.css'

import { icon_default_profile, logo, logo_icon } from "../../../assets";

import { RxHamburgerMenu } from "react-icons/rx";
import { NavLink } from "react-router-dom";

import GlobalVariables from "../../../globalVariables";

const Topbar = ({ isActive, hasNewInbox }) => {

    const isPhone = useMediaQuery({ query: '(max-width: 768px)' });

    const [patientInformation, setPatientInformation] = useState({});

    const getPatientInformation = useCallback(async () => {
        // get user
        let patient;
        try {
            patient = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
        patient = await patient.json();
        const patientID = patient.user;

        // get patient information
        let patientInformation;
        try {
            patientInformation = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=information&id=${patientID}`, {
                    method: 'GET',
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        patientInformation = await patientInformation.json();
        patientInformation = patientInformation[0];
        setPatientInformation(patientInformation);
    }, []);

    const hamburgerClickHandler = () => {
        isActive((prevState) => {
            return !prevState;
        });
    }

    useEffect(() => {
        getPatientInformation();
    }, [hasNewInbox]);

    return (
        <div className="topbar">
            <div className={`topbar-red-circle ${hasNewInbox ? 'active' : ''} ${isPhone ? 'mobile' : ''}`}/>
            <div className="hamburger" onClick={() => hamburgerClickHandler()}>
                <RxHamburgerMenu size={'20px'}/>
            </div>
            <div className="logo">
                <img className="logo-icon" src={logo_icon} alt=""/>
                <img className="logo-img" src={logo} alt=""/>
            </div>
            <div className="name">
                {!isPhone ? `Hello, ${patientInformation.patientgender === "Male" ? "Mr." : "Ms."} ${patientInformation.namelast}` : ""}
            </div>
            <div className="account" onClick={() => isPhone ? hamburgerClickHandler() : ''}>
                {!isPhone ?
                    <NavLink to="account">
                        <img className="account-img" src={patientInformation.profilepicture === null ? icon_default_profile : patientInformation.profilepicture } alt="" style={{ borderRadius: '50%' }}/>
                    </NavLink> :
                    <img className="account-img" src={patientInformation.profilepicture === null ? icon_default_profile : patientInformation.profilepicture } alt="" />
                }
            </div>
        </div>
    );
};

export default Topbar;