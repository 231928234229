import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Textfield.css";

const Textfield = ({
  title,
  prompt,
  style,
  type,
  setValue,
  getValue,
  isEmpty,
  id,
  disabled,
  minLength,
  numberOnly,
  placeholder,
}) => {
  const [fieldTitle, setFieldTitle] = useState("");
  const [fieldPrompt, setFieldPrompt] = useState("");
  const [fieldType, setFieldType] = useState("text");
  const [fieldValue, setFieldValue] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [color, setColor] = useState("#000000");
  const [borderColor, setBorderColor] = useState("#c4c4c4");
  const [outlineColor, setOutlineColor] = useState("#5b346b");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [length, setLength] = useState(999);
  const ref = useRef(null);

  const onChangeHandler = (event) => {
    let inputValue = event.target.value;
    if (numberOnly === true) {
      inputValue = inputValue.replace(/\D/g, "");
    }
    if (getValue) getValue(inputValue);
  };

  const isEmptyHandler = useCallback(() => {
    if (prompt !== undefined) setFieldPrompt(prompt);
    setColor("#ff0000");
    setBorderColor("#ff0000");
    setOutlineColor("#ff0000");
  }, [prompt]);

  const isNotEmptyHandler = () => {
    setFieldPrompt("");
    setColor("#000000");
    setBorderColor("#c4c4c4");
    setOutlineColor("#5b346b");
  };

  useEffect(() => {
    if (title !== undefined) setFieldTitle(title);
    if (type !== undefined) setFieldType(type);
    if (setValue !== undefined) setFieldValue(setValue);
    if (disabled !== undefined) setFieldDisabled(disabled);
    if (style && style.color !== undefined) setColor(style.color);
    if (style && style.borderColor !== undefined)
      setBorderColor(style.borderColor);
    if (style && style.outlineColor !== undefined)
      setOutlineColor(style.outlineColor);
    if (style && style.width !== undefined) setWidth(style.width);
    if (style && style.height !== undefined) setHeight(style.height);
    if (isEmpty) isEmptyHandler();
    if (!isEmpty) isNotEmptyHandler();
    if (minLength !== undefined) setLength(minLength);
  }, [title, style, type, setValue, disabled, isEmpty, isEmptyHandler]);

  return (
    <div className="textfield" style={{ width: width }}>
      <div className="textfield-title" style={{ color: color }}>
        {fieldTitle}
      </div>
      {setValue === undefined ? (
        <input
          className="textfield-box"
          type={fieldType}
          ref={ref}
          maxLength={length}
          id={id}
          style={{
            borderColor: borderColor,
            outlineColor: outlineColor,
            padding: height,
          }}
          defaultValue={fieldValue}
          onChange={(event) => onChangeHandler(event)}
          disabled={fieldDisabled}
        />
      ) : (
        <input
          className="textfield-box"
          type={fieldType}
          ref={ref}
          maxLength={length}
          value={fieldValue}
          id={id}
          style={{
            borderColor: borderColor,
            outlineColor: outlineColor,
            padding: height,
          }}
          defaultValue={fieldValue}
          placeholder={placeholder} // Placeholder added here
          onChange={(event) => onChangeHandler(event)}
          disabled={fieldDisabled}
        />
      )}
      <div className="textfield-prompt" style={{ color: color }}>
        {fieldPrompt}
      </div>
    </div>
  );
};

export default Textfield;
