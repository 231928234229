import { Navigate, Route, Routes } from "react-router-dom";
import OptionsSignUpPage from "./options-signup/OptionsSignUpPage";
import UserDetails from "../patient/signup/user-details/UserDetails";
import Name from "../patient/signup/register/name/Name";
import PersonalDetails from "../patient/signup/register/personal-details/PersonalDetails";
import Address from "../patient/signup/register/address/Address";
import CivilDetails from "../patient/signup/register/civil-details/CivilDetails";
import AuthenticationPage from "./AuthenticationPage";
import DentistDetails from "../clinic/signup/dentist-signup/dentist-details/ClinicDetails";
import DentistName from "../clinic/signup/dentist-signup/dentist-name/ClinicName";
import DentistRegister from "../clinic/signup/dentist-signup/dentist-register/ClinicRegister";
import ClinicDetails from "../clinic/signup/clinic-signup/clinic-details/ClinicDetails";
import { useEffect } from "react";
import ClinicAddress from "../clinic/signup/clinic-signup/clinic-address/ClinicAddress";
import ClinicContact from "../clinic/signup/clinic-signup/clinic-contact/ClinicContact";

function MainAuthenticationPage({ setIsAuthenticated, setUserType }) {

  useEffect(() => {

  }, []);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<AuthenticationPage setAuth={setIsAuthenticated} setType={setUserType} />}
        />
        <Route path="/signup" element={<OptionsSignUpPage/>} />
        <Route path="/patient/signup/user-details" element={<UserDetails/>} />
        <Route path="/patient/signup/register/name" element={<Name/>} />
        <Route path="/patient/signup/register/personal-details" element={<PersonalDetails/>} />
        <Route path="/patient/signup/register/address" element={<Address/>} />
        <Route 
          path="/patient/signup/register/civil-details" 
          element={<CivilDetails setAuth={setIsAuthenticated} setType={setUserType} />}
        />
        <Route path="/clinic/signup/">
          <Route path="user-details" element={<DentistDetails/>}/>
          <Route path="user-name" element={<DentistName/>}/>
          <Route path="user-register" element={<DentistRegister/>}/>
          <Route path="clinic-details" element={<ClinicDetails/>}/>
          <Route path="clinic-address" element={<ClinicAddress/>}/>
          <Route path="clinic-contact" element={<ClinicContact setAuth={setIsAuthenticated} setType={setUserType}/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default MainAuthenticationPage;
