import { Doughnut, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { useEffect, useState } from "react";
import {
  AMALGAM_FILLING,
  CAVITY,
  COLORBLIND,
  COMPOSITE_FILLING,
  CROWN,
  METAL_CROWN,
  NORMAL,
  TEETH_BEIGE,
  TEETH_BLACK,
  TEETH_BLUE,
  TEETH_GRAY,
  TEETH_MAGENTA,
  TEETH_ORANGE,
  TEETH_RED,
  TEETH_TEAL,
  TEETH_WHITE,
} from "./Constant";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
const TeethObject = ({
  topTeeth,
  bottomTeeth,
  leftTeeth,
  rightTeeth,
  middleTeeth,
  teethNumber,
  ...rest
}) => {
  const getColorblind = localStorage.getItem(COLORBLIND);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const numberClass = classNames("absolute z-4", isPhone ? "ml-[13px] text-sm" : "ml-[20px]")
  const swapRed = getColorblind === "1" ? TEETH_ORANGE : TEETH_RED;
  const swapBlue =
    getColorblind === "1"
      ? TEETH_TEAL
      : getColorblind === "3"
      ? TEETH_MAGENTA
      : TEETH_BLUE;

  const data = {
    labels: [],
    datasets: [
      {
        data: [25, 25, 25, 25],
        backgroundColor: [
          rightTeeth === NORMAL
            ? TEETH_WHITE
            : rightTeeth === METAL_CROWN
            ? TEETH_GRAY
            : rightTeeth === CROWN
            ? TEETH_BEIGE
            : rightTeeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : rightTeeth === COMPOSITE_FILLING
            ? swapBlue
            : rightTeeth === CAVITY
            ? swapRed
            : "",
          bottomTeeth === NORMAL
            ? TEETH_WHITE
            : bottomTeeth === METAL_CROWN
            ? TEETH_GRAY
            : bottomTeeth === CROWN
            ? TEETH_BEIGE
            : bottomTeeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : bottomTeeth === COMPOSITE_FILLING
            ? swapBlue
            : bottomTeeth === CAVITY
            ? swapRed
            : "",
          leftTeeth === NORMAL
            ? TEETH_WHITE
            : leftTeeth === METAL_CROWN
            ? TEETH_GRAY
            : leftTeeth === CROWN
            ? TEETH_BEIGE
            : leftTeeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : leftTeeth === COMPOSITE_FILLING
            ? swapBlue
            : leftTeeth === CAVITY
            ? swapRed
            : "",
          topTeeth === NORMAL
            ? TEETH_WHITE
            : topTeeth === METAL_CROWN
            ? TEETH_GRAY
            : topTeeth === CROWN
            ? TEETH_BEIGE
            : topTeeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : topTeeth === COMPOSITE_FILLING
            ? swapBlue
            : topTeeth === CAVITY
            ? swapRed
            : "",
        ],
        borderColor: "#000000",
      },
    ],
  };

  const data2 = {
    labels: [],
    datasets: [
      {
        data: [100],
        backgroundColor: [
          middleTeeth === NORMAL
            ? TEETH_WHITE
            : middleTeeth === METAL_CROWN
            ? TEETH_GRAY
            : middleTeeth === CROWN
            ? TEETH_BEIGE
            : middleTeeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : middleTeeth === COMPOSITE_FILLING
            ? swapBlue
            : middleTeeth === CAVITY
            ? swapRed
            : "",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    rotation: 45,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        enabled: false,
      },
    },

    hover: {
      mode: null,
    },
  }; // small = 48, large = 80 small ? " " : " "

  return (
    <div className="grid grid-col relative">
      <div className="hidden">
        <div
          className="h-[56px] w-1 bg-gray-500 absolute ml-[25px] mt-[22px]"
          style={{ zIndex: "1" }}
        />
        <div
          className="h-[56px] w-1 bg-gray-500 absolute ml-[32px] mt-[22px]"
          style={{ zIndex: "1" }}
        />
      </div>
      <div className={numberClass}>{teethNumber}</div>
      <div className="hover:cursor-pointer  " {...rest}>
        <div className="mt-[20px]">
          <div className={`absolute z-3`}>
            <Pie
              data={data2}
              options={options}
              style={{
                width: isPhone ? "40px" : "60px",
                height: isPhone ? "40px" : "60px",
              }}
            />
          </div>
          <div
            className={`absolute z-2`}
            style={{
              width: isPhone ? "40px" : "60px",
              height: isPhone ? "40px" : "60px",
            }}
          >
            <Doughnut data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeethObject;
