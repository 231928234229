import { Box, Button, Modal } from "@mui/material";
import TeethColumn from "./TeethColumn";
import TeethDropdown from "./TeethDropdown";
import { useEffect, useState } from "react";
import {
  AMALGAM_FILLING,
  CAVITY,
  CHANGE_BOT,
  CHANGE_LEFT,
  CHANGE_MID,
  CHANGE_RIGHT,
  CHANGE_TOP,
  COLORBLIND,
  COMPOSITE_FILLING,
  CROWN,
  METAL_CROWN,
  NORMAL,
  SAVE_VALUE,
  TEETH_BEIGE,
  TEETH_BLACK,
  TEETH_BLUE,
  TEETH_GRAY,
  TEETH_MAGENTA,
  TEETH_ORANGE,
  TEETH_RED,
  TEETH_TEAL,
  TEETH_WHITE,
} from "./Constant";
import { useMediaQuery } from "react-responsive";

const TeethModal = ({ show, handleClose, teeth, dispatch }) => {
  const [currentTeeth, setCurrentTeeth] = useState(teeth);
  const getColorblind = localStorage.getItem(COLORBLIND);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const swapRed = getColorblind === "1" ? TEETH_ORANGE : TEETH_RED;
  const swapBlue =
    getColorblind === "1"
      ? TEETH_TEAL
      : getColorblind === "3"
      ? TEETH_MAGENTA
      : TEETH_BLUE;

  useEffect(() => {
    setCurrentTeeth(teeth);
  }, [teeth]);

  const changeData = () => {
    dispatch({ type: SAVE_VALUE, payload: currentTeeth });
  };

  const handleChange = (event, dispatchType) => {
    switch (dispatchType) {
      case CHANGE_TOP:
        console.log(event.target.value)
        setCurrentTeeth({ ...currentTeeth, topteeth: event.target.value });
        return;
      case CHANGE_BOT:
        setCurrentTeeth({ ...currentTeeth, bottomteeth: event.target.value });
        return;
      case CHANGE_LEFT:
        setCurrentTeeth({ ...currentTeeth, leftteeth: event.target.value });
        return;
      case CHANGE_RIGHT:
        setCurrentTeeth({ ...currentTeeth, rightteeth: event.target.value });
        return;
      case CHANGE_MID:
        setCurrentTeeth({ ...currentTeeth, middleteeth: event.target.value });
        return;
      default:
        throw new Error("unexpected action type" + dispatchType.type);
    }
  };

  return (
    <Modal open={show} onClose={handleClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: "1500px",
          height: isPhone ? "800px" : "700px",
          padding: "16px",
          maxWidth: "80vw",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "1rem",
          }}
        >
          <TeethColumn
            color={
              currentTeeth.topteeth === NORMAL
                ? TEETH_WHITE
                : currentTeeth.topteeth === METAL_CROWN
                ? TEETH_GRAY
                : currentTeeth.topteeth === CROWN
                ? TEETH_BEIGE
                : currentTeeth.topteeth === AMALGAM_FILLING
                ? TEETH_BLACK
                : currentTeeth.topteeth === COMPOSITE_FILLING
                ? swapBlue
                : currentTeeth.topteeth === CAVITY
                ? swapRed
                : ""
            }
            label={"Top Teeth"}
          >
            <TeethDropdown
              teethColor={currentTeeth.topteeth}
              handleChange={handleChange}
              dispatchType={CHANGE_TOP}
            />
          </TeethColumn>
          <TeethColumn
            color={currentTeeth.bottomteeth=== NORMAL
            ? TEETH_WHITE
            : currentTeeth.bottomteeth === METAL_CROWN
            ? TEETH_GRAY
            : currentTeeth.bottomteeth === CROWN
            ? TEETH_BEIGE
            : currentTeeth.bottomteeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : currentTeeth.bottomteeth === COMPOSITE_FILLING
            ? swapBlue
            : currentTeeth.bottomteeth === CAVITY
            ? swapRed
            : ""}
            label={"Bottom Teeth"}
          >
            <TeethDropdown
              teethColor={currentTeeth.bottomteeth}
              handleChange={handleChange}
              dispatchType={CHANGE_BOT}
            />
          </TeethColumn>
          <TeethColumn
            color={currentTeeth.leftteeth === NORMAL
            ? TEETH_WHITE
            : currentTeeth.leftteeth === METAL_CROWN
            ? TEETH_GRAY
            : currentTeeth.leftteeth === CROWN
            ? TEETH_BEIGE
            : currentTeeth.leftteeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : currentTeeth.leftteeth === COMPOSITE_FILLING
            ? swapBlue
            : currentTeeth.leftteeth === CAVITY
            ? swapRed
            : ""}
            label={"Left Teeth"}
          >
            <TeethDropdown
              teethColor={currentTeeth.leftteeth}
              handleChange={handleChange}
              dispatchType={CHANGE_LEFT}
            />
          </TeethColumn>
          <TeethColumn
            color={currentTeeth.rightteeth === NORMAL
            ? TEETH_WHITE
            : currentTeeth.rightteeth === METAL_CROWN
            ? TEETH_GRAY
            : currentTeeth.rightteeth === CROWN
            ? TEETH_BEIGE
            : currentTeeth.rightteeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : currentTeeth.rightteeth === COMPOSITE_FILLING
            ? swapBlue
            : currentTeeth.rightteeth === CAVITY
            ? swapRed
            : ""}
            label={"Right Teeth"}
          >
            <TeethDropdown
              teethColor={currentTeeth.rightteeth}
              handleChange={handleChange}
              dispatchType={CHANGE_RIGHT}
            />
          </TeethColumn>
          <TeethColumn
            color={currentTeeth.middleteeth === NORMAL
            ? TEETH_WHITE
            : currentTeeth.middleteeth === METAL_CROWN
            ? TEETH_GRAY
            : currentTeeth.middleteeth === CROWN
            ? TEETH_BEIGE
            : currentTeeth.middleteeth === AMALGAM_FILLING
            ? TEETH_BLACK
            : currentTeeth.middleteeth === COMPOSITE_FILLING
            ? swapBlue
            : currentTeeth.middleteeth === CAVITY
            ? swapRed
            : ""}
            label={"Middle Teeth"}
          >
            <TeethDropdown
              teethColor={currentTeeth.middleteeth}
              handleChange={handleChange}
              dispatchType={CHANGE_MID}
            />
          </TeethColumn>
        </div>
        <Button onClick={() => changeData()}>Save</Button>
      </Box>
    </Modal>
  );
};

export default TeethModal;
