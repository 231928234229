import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import './Name.css';
import { ColoredButton, RegisterContainer, Textfield } from "../../../../../components";

const Name = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userDetails } = location.state || {};

    // Validation function to check if the input contains only letters or special characters
    const isValidName = (name) => {
        const regex = /^[a-zA-ZÀ-ÿ\s]+$/;
        return regex.test(name);
    };

    // State variables for first, middle, and last names
    const [firstNameIsEmpty, setFirstNameIsEmpty] = useState(false);
    const [firstName, setFirstName] = useState('');

    const [middleNameIsEmpty, setMiddleNameIsEmpty] = useState(false);
    const [middleName, setMiddleName] = useState('');

    const [lastNameIsEmpty, setLastNameIsEmpty] = useState(false);
    const [lastName, setLastName] = useState('');

    // Handler for when next button is pressed
    const next = () => {
        // Check if first name field has an input and if it's valid
        if (!firstName.trim().length || !isValidName(firstName)) {
            setFirstNameIsEmpty(true);
            return;
        } else {
            setFirstNameIsEmpty(false);
        }

        // Check if middle name field has an input and if it's valid
        if (!middleName.trim().length || !isValidName(middleName)) {
            setMiddleNameIsEmpty(true);
            return;
        } else {
            setMiddleNameIsEmpty(false);
        }

        // Check if last name field has an input and if it's valid
        if (!lastName.trim().length || !isValidName(lastName)) {
            setLastNameIsEmpty(true);
            return;
        } else {
            setLastNameIsEmpty(false);
        }

        // If all inputs are valid, proceed to the next step
        const patientInformation = {
            email: userDetails.email,
            numbermobile: userDetails.numbermobile,
            password: userDetails.password,
            namefirst: firstName,
            namemiddle: middleName,
            namelast: lastName
        };

        navigate('/auth/patient/signup/register/personal-details', { state: { patientInformation } });
    };

    return (
        <div className="name-page">
            <div className="name-container"
                onKeyDown={(event) => {
                    if(event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header="And you're...?"
                    subheader="Please enter your name below."
                    fields={
                        <div className="name-container-fields">
                            <Textfield
                                title="First name"
                                prompt="This field is required"
                                isEmpty={firstNameIsEmpty}
                                getValue={setFirstName}
                               
                            />
                            <div className="name-container-fields-middle-and-last-name">
                                <Textfield
                                    title="Middle name"
                                    prompt="This field is required"
                                    isEmpty={middleNameIsEmpty}
                                    getValue={setMiddleName}
                                    
                                />
                                <Textfield
                                    title="Last name"
                                    prompt="This field is required"
                                    isEmpty={lastNameIsEmpty}
                                    getValue={setLastName}
                                    
                                />
                            </div>
                        </div>
                    }
                    options={
                        <div className="name-container-options">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default Name;
