import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SignatureCanvas from "react-signature-canvas";
import {
  CROWN,
  FEMALE,
  MALE,
  recievedToken,
} from "../../../components/clinic/Constant";
import { CLINIC_LOGO } from "../../../components/clinic/ImageSource";
import globalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

function WorkAuthorizationPage() {
  const [form, setForm] = useState(CROWN);
  const [patientName, setPatientName] = useState("");
  const [specialNote, setSpecialNote] = useState("");
  const [recieverName, setRecieverName] = useState("");
  const [dentist, setDentist] = useState("empty");
  const [witness, setWitness] = useState("");
  const [clinicAddress, setClinicAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [labName, setLabName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState(false);
  const [date, setDate] = useState(null);
  const [dateRequired, setDateRequired] = useState(null);
  const [checkedValues, setCheckedValues] = useState(null);
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("empty");
  const [patientPad, setPatientPad] = useState();
  const [dentistPad, setDentistPad] = useState();
  const [loading, setLoading] = useState(true);
  const [dentistInfo, setDentistInfo] = useState([]);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const [newPatient, setNewPatient] = useState(false);
  const [id, setId] = useState(0);
  const [patientData, setPatientData] = useState([]);

  const signatureStyle = classNames(
    "justify-between w-[100%] mt-10",
    isPhone ? "" : "flex"
  );
  const clearSigStyle = classNames("text-blue-500 hover:font-bold");

  const caseDetail = classNames(
    newPatient ? "flex justify-between w-[100%] mb-5 mt-5" : "mb-5 mt-5 "
  );

  const handleCheckedValues = (event) => {
    setCheckedValues(event.target.value);
  };

  const handleChangeWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleSpecialNote = (event) => {
    setSpecialNote(event.target.value);
  };

  const handlePatientName = (event) => {
    setPatientName(event.target.value);
  };

  function medicalInformation() {
    const storedData = localStorage.getItem("currentUser");
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=getdentistprc&id=${storedData}`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setDentistInfo(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  function getClinicInformation() {
    const storedData = localStorage.getItem("currentUser");
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=clinicinformation&id=${storedData}&method=forms`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setLabName(data[0].clinicname);
          setClinicAddress(data[0].clinicaddress);
          setMobileNumber(data[0].clinicnumbermobile);
          setTelephoneNumber(data[0].clinicnumbertelephone);
          setEmailAddress(data[0].clinicemail);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  function patientInformation() {
    try {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&method=documents`,
        {
          method: "GET",
          headers: {
            token: recievedToken,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPatientData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error at: ", error);
    }
  }

  const generatePDF = () => {
    if (patientName === "" || dentist === "") {
      setError(true);
    } else {
      const printClinicAddress = `${clinicAddress}`;
      const printTelephoneNumber = `Tel.No. ${telephoneNumber}`;
      const printCelNumber = `Cel.No. ${mobileNumber}`;
      const printEmailAddress = `Email:${emailAddress}`;
      const printWebsite = `Website: ${website}`;

      const img = new Image();
      const doc = new jsPDF();
      img.src = CLINIC_LOGO;
      doc.addImage(img, "png", 10, 0, 90, 40);

      doc.setFontSize(10);
      doc.text(printClinicAddress, 105, 5, {
        align: "justify",
        display: "flex",
        maxWidth: 90,
      });
      doc.text(printTelephoneNumber, 105, 13, {
        align: "justify",
        display: "flex",
        maxWidth: 90,
      });
      doc.text(printCelNumber, 105, 18, {
        align: "justify",
        display: "flex",
        maxWidth: 90,
      });
      doc.text(printEmailAddress, 105, 23, {
        align: "justify",
        display: "flex",
        maxWidth: 90,
      });
      doc.setFontSize(17);
      doc.setFont(undefined, "bold");
      doc.text("Laboratory", 10, 50);

      doc.setFont(undefined, "normal");
      doc.setFontSize(15);
      doc.text(`Name: ${labName}`, 10, 65);

      doc.setFontSize(17);
      doc.setFont(undefined, "bold");
      doc.text("Case Details", 10, 90);

      doc.setFont(undefined, "normal");
      doc.setFontSize(15);
      doc.text(`Patient Name: ${patientName}`, 10, 107);

      doc.text(`Age: ${age}`, 10, 113);
      doc.text(`Gender: ${gender}`, 10, 119);
      doc.text(`Facial Type: ${checkedValues}`, 110, 113);
      doc.text(
        `Date Required: ${dayjs(dateRequired).format("MM/DD/YYYY")}`,
        110,
        119
      );

      doc.setFontSize(17);
      doc.setFont(undefined, "bold");
      doc.text("Special Notes", 10, 150);
      doc.setFont(undefined, "normal");
      doc.setFontSize(15);
      doc.text(`${specialNote}`, 10, 162);

      const docSig = new Image();
      const patSig = new Image();

      docSig.src = dentistPad.getTrimmedCanvas().toDataURL("image/png");
      patSig.src = patientPad.getTrimmedCanvas().toDataURL("image/png");
      doc.setFontSize(10);
      doc.addImage(patSig, "png", 100, 230, 50, 20);
      doc.addImage(docSig, "png", 10, 230, 50, 20);
      doc.text(`Dentist: ${dentist}`, 22, 273, {
        maxWidth: 54,
        align: "justify",
      });
      doc.text(`Patient: ${patientName}`, 112, 273, {
        maxWidth: 54,
        align: "justify",
      });

      const today = new Date();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();

      doc.save(`${year}_${month}_${day}_${patientName}_work_authorization.pdf`);

      // const docSigToImage = dentistPad.toDataURL();
      // const patSigToImage = patientPad.toDataURL();

      // docSig.src = docSigToImage;
      // patSig.src = patSigToImage;

      // doc.addImage(docSig, "png", 100, 230, 40, 20);
      // doc.addImage(patSig, "png", 10, 230, 40, 20);
    }
  };

  const handleDateRequired = (event) => {
    setDateRequired(event);
  };

  const handleDate = (event) => {
    setDate(event);
  };

  const handleMobileNumber = (event) => {
    setMobileNumber(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleLabName = (event) => {
    setLabName(event.target.value);
  };

  const handleTelephoneNumber = (event) => {
    setTelephoneNumber(event.target.value.replace(/[^0-9]/g, ""));
  };

  const handleClinicAddress = (event) => {
    setClinicAddress(event.target.value);
  };

  const handleCheck = () => {
    setNewPatient(!newPatient);
    setPatientName("");
    setAge("");
    setGender("empty");
    setId(0);
  };

  useEffect(() => {
    getClinicInformation();
    medicalInformation();
    patientInformation();
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="">
      <div className="flex justify-end mb-5">
        <FormControl>
          <InputLabel>Change</InputLabel>
          <Select
            id="consent-form-prefix"
            value={form}
            label="Change"
            onChange={setForm}
          >
            <MenuItem value={CROWN}>Crown</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="mb-5">
        New Patient?
        <Checkbox onChange={(event) => handleCheck()} />
      </div>

      <div className="grid grid-flow-cols-1 gap-4">
        <TextField
          value={labName}
          onChange={handleLabName}
          label={"Laboratory Name"}
        />
        <TextField
          value={clinicAddress}
          onChange={handleClinicAddress}
          label={"Clinic Address*"}
        />
      </div>
      <div className="flex justify-between h-[40%] mb-5 mt-5">
        <TextField
          value={mobileNumber}
          onChange={handleMobileNumber}
          label={"Mobile Number"}
          placeholder={"09xxxxxxxxx"}

          className={"w-[48%]"}
        />

        <TextField
          value={telephoneNumber}
          onChange={handleTelephoneNumber}
          label={"Telephone Number"}
          placeholder={"xxxxxxxx"}
          className={"w-[48%]"}
        />
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={date}
          onChange={handleDate}
          label={"Date*"}
          className={"w-[100%] mb-5 mt-5"}
        />
      </LocalizationProvider>

      <h1 className="flex justify-center">Case Details</h1>

      {newPatient ? (
        <TextField
          error={error}
          value={patientName}
          onChange={handlePatientName}
          label={"Patient's Name*"}
          className={"w-full"}
        />
      ) : (
        ""
      )}

      <div className={caseDetail}>
        {newPatient ? (
          <TextField value={age} onChange={handleAgeChange} label={"Age"} />
        ) : (
          ""
        )}
        {newPatient ? (
          <Select
            id="dropdown-gender"
            value={gender}
            label="Male/Female"
            onChange={handleGenderChange}
            className={"w-[200px]"}
          >
            <MenuItem value={"empty"}>Gender</MenuItem>
            <MenuItem value={MALE}>Male</MenuItem>
            <MenuItem value={FEMALE}>Female</MenuItem>
          </Select>
        ) : (
          ""
        )}
        {newPatient ? (
          ""
        ) : (
          <div className={isPhone ? "w-[420px] mb-5" : "w-[490px] mb-5"}>
            <Select
              value={`${patientName}+${age}+${gender}+${id}`}
              label={"Prefix"}
              defaultValue="empty"
              style={{ width: "50%" }}
              onChange={(event) => {
                const [selectedName, selectedAge, selectedGender, selectId] =
                  event.target.value.split("+");
                setPatientName(selectedName);
                setAge(selectedAge);
                setGender(selectedGender);
                setId(selectId);
              }}
            >
              <MenuItem value={"++empty+0"}>Select Patient</MenuItem>
              {patientData.map((dentist) => {
                const fullName = `${dentist.namefirst} ${dentist.namemiddle} ${dentist.namelast}`;
                const birthYear = new Date(dentist.dateofbirth).getFullYear();
                const currentYear = new Date().getFullYear();
                const age = currentYear - birthYear;
                return (
                  <MenuItem
                    key={`${fullName}+${age}+${dentist.patientgender}+${dentist.id}`}
                    value={`${fullName}+${age}+${dentist.patientgender}+${dentist.id}`}
                  >
                    {fullName}
                  </MenuItem>
                );
              })}
            </Select>{" "}
          </div>
        )}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={dateRequired}
            onChange={handleDateRequired}
            label={"Date Required*"}
            disablePast={true}
          />
        </LocalizationProvider>
      </div>

      <FormControl component="fieldset">
        <FormLabel component="legend">Facial Type:</FormLabel>
        <RadioGroup
          row
          aria-label="facial-type"
          value={checkedValues}
          onChange={handleCheckedValues}
        >
          <FormControlLabel value="Square" control={<Radio />} label="Square" />
          <FormControlLabel
            value="Triangular"
            control={<Radio />}
            label="Triangular"
          />
          <FormControlLabel
            value="Rectangular"
            control={<Radio />}
            label="Rectangular"
          />
          <FormControlLabel value="Ovoid" control={<Radio />} label="Ovoid" />
        </RadioGroup>
      </FormControl>

      <div className="flex justify-center mt-5"></div>

      <div>
        <TextField
          value={specialNote}
          onChange={handleSpecialNote}
          label={"Note"}
          className={"w-full"}
        />
      </div>

      <div className={signatureStyle}>
        <div className="flex flex-col">
          <SignatureCanvas
            ref={(data) => setDentistPad(data)}
            canvasProps={{ width: 300, height: 150, className: "shadow-lg" }}
          />
          <p className="mb-5">Dentist's Signature</p>
          <Select
            value={dentist}
            label={"Prefix"}
            defaultValue="empty"
            onChange={(event) => {
              setDentist(event.target.value);
            }}
          >
            <MenuItem value={"empty"}>Select Doctor</MenuItem>
            {dentistInfo.map((name) => (
              <MenuItem key={name.name} value={name.name}>
                {name.name}
              </MenuItem>
            ))}
          </Select>
          <button onClick={() => dentistPad.clear()} className={clearSigStyle}>
            Clear Signature
          </button>
        </div>
        <div className="flex flex-col">
          <SignatureCanvas
            ref={(data) => setPatientPad(data)}
            canvasProps={{ width: 300, height: 150, className: "shadow-lg" }}
          />
          <p className="mb-5">Patient's Signature</p>
          <TextField
            value={patientName}
            onChange={handlePatientName}
            label={"Patients Name"}
            error={error}
            disabled={newPatient ? false : true}
          />
          <button onClick={() => patientPad.clear()} className={clearSigStyle}>
            Clear Signature
          </button>
        </div>
      </div>

      <div className="flex flex-col items-end mt-5 space-y-5">
        <Button onClick={generatePDF}>Export PDF</Button>
      </div>
    </div>
  );
}

export default WorkAuthorizationPage;
