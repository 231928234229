import { Box } from "@mui/material";
import classNames from "classnames";
import { TEETH_WHITE } from "./Constant";

function TeethColumn({children, color, label}) {
  const teethClassName = classNames("text-center font-bold", color === TEETH_WHITE ? "text-black" : "text-white")


  return (
    <div className="h-10vh">
      <Box
        className={`border-solid border-2 border-black`}
        style={{ backgroundColor: color}}
      >
        <p className={teethClassName}> {label} </p>
      </Box>
      <div className="mt-5"> {children} </div>
    </div>
  );
}

export default TeethColumn;
