import { useReducer, Fragment, useEffect, useState } from "react";
import { map } from "lodash";
import { produce } from "immer";
import { Alert, Box, Button, Collapse } from "@mui/material";
import TeethObject from "../../../components/clinic/TeethObject";
import TeethModal from "../../../components/clinic/TeethModal";
import {
  SET_DATA,
  JSON_NAME,
  SAVE_VALUE,
  UPLOAD_VALUE,
  SET_FALSE,
  TEETH_RED,
  CAVITY,
  COMPOSITE_FILLING,
  TEETH_ORANGE,
  TEETH_TEAL,
  TEETH_MAGENTA,
  TEETH_BLUE,
  COLORBLIND,
  TEETH_BLACK,
  TEETH_BEIGE,
  TEETH_WHITE,
  TEETH_GRAY,
  TOKEN_NAME,
} from "../../../components/clinic/Constant";
import TeethColumn from "../../../components/clinic/TeethColumn";
import globalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const SET_MODAL_ON = "set-modal-on";
const SET_MODAL_OFF = "set-modal-off";
const MODAL_VALUE = "modal-value";
const EMPTY_VALUE = "empty-value";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MODAL_ON:
      state.showModal = true;
      return;
    case SET_MODAL_OFF:
      state.showModal = false;
      return;
    case SET_FALSE:
      state.showAlert = !state.showAlert;
      return;
    case UPLOAD_VALUE:
      const recievedToken = localStorage.getItem(TOKEN_NAME);
      if (recievedToken) {
        const storedData = localStorage.getItem(JSON_NAME);
        try {
          fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=putimage`, {
            method: "PATCH",
            headers: {
              token: recievedToken,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              initialTeethStates: [
                state.initialTeethState1,
                state.initialTeethState2,
                state.initialTeethState3,
                state.initialTeethState4,
                state.initialTeethState5,
                state.initialTeethState6,
                state.initialTeethState7,
                state.initialTeethState8,
              ],
              patientid: storedData,
            }),
          });
        } catch (error) {
          console.error("Error at: ", error);
        }
      }
      return;
    case SAVE_VALUE:
      state.showModal = false;
      for (let i = 1; i <= 8; i++) {
        const teethSatetName = `initialTeethState${i}`;
        const index = state[teethSatetName].findIndex(
          (teeth) => teeth.teethnumber === action.payload.teethnumber
        );

        if (
          state[teethSatetName].findIndex(
            (teeth) => teeth.teethnumber === action.payload.teethnumber
          ) !== -1
        ) {
          state[teethSatetName][index] = {
            ...state[teethSatetName][index],
            topteeth: action.payload.topteeth,
            bottomteeth: action.payload.bottomteeth,
            leftteeth: action.payload.leftteeth,
            rightteeth: action.payload.rightteeth,
            middleteeth: action.payload.middleteeth,
          };
        }
      }

      return;
    case MODAL_VALUE:
      state.modalTeeth = action.payload;
      return;
    case EMPTY_VALUE:
      state.modalTeeth = 0;
      return;
    case SET_DATA:
      state.initialTeethState1 = action.payload[0].initialteethstate1;
      state.initialTeethState2 = action.payload[0].initialteethstate2;
      state.initialTeethState3 = action.payload[0].initialteethstate3;
      state.initialTeethState4 = action.payload[0].initialteethstate4;
      state.initialTeethState5 = action.payload[0].initialteethstate5;
      state.initialTeethState6 = action.payload[0].initialteethstate6;
      state.initialTeethState7 = action.payload[0].initialteethstate7;
      state.initialTeethState8 = action.payload[0].initialteethstate8;
      return;
    default:
      throw new Error(
        "unexpected action type" + action.type + " At TeethImage"
      );
  }
};

function TeethImage({ userType }) {
  const [state, dispatch] = useReducer(produce(reducer), {
    showModal: false,
    modalTeeth: {},
    initialTeethState1: [],
    initialTeethState2: [],
    initialTeethState3: [],
    initialTeethState4: [],
    initialTeethState5: [],
    initialTeethState6: [],
    initialTeethState7: [],
    initialTeethState8: [], 
    showAlert: false,
  });
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const initialRowClass = classNames(
    isPhone ? "flex flex-col ml-[-25px]" : "flex flex-row gap-5 "
  );
  const rowClass = classNames(
    isPhone ? "flex flex-col ml-[35px]" : "flex flex-row gap-5 ml-[180px]"
  );
  const teethClass = classNames(isPhone ? "pb-[40px] ml-[40px]" : "pb-[90px] ml-[60px]")
  const recievedToken = localStorage.getItem(TOKEN_NAME);
  const [loading, setLoading] = useState(true);
  const getColorblind = localStorage.getItem(COLORBLIND);
  const swapRed = getColorblind === "1" ? TEETH_ORANGE : TEETH_RED;
  const swapBlue =
    getColorblind === "1"
      ? TEETH_TEAL
      : getColorblind === "3"
      ? TEETH_MAGENTA
      : TEETH_BLUE;

  // function createData(){
  //   const storedData = localStorage.getItem(JSON_NAME);
  //   try{
  //     fetch(`${BACKENDFULLURL}/?type=teethchart`, {
  //       method: "POST",
  //       headers: { "ngrok-skip-browser-warning": true, token: recievedToken },
  //       body: JSON.stringify({
  //         patientid: storedData
  //       })
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.json();
  //       })
  //       .then()
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error at: ", error);
  //   }
  // }

  async function getData() {
    const storedData = localStorage.getItem(JSON_NAME);
    if (userType === "clinic") {
      fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/?type=getimage&patientid=${storedData}`,
        {
          method: "GET",
          headers: { "ngrok-skip-browser-warning": true, token: recievedToken },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (data.length !== 0) {
            dispatch({ type: SET_DATA, payload: data }, setLoading(false));
            return Promise.resolve();
          }

          return fetch(
            `${globalVariables.WEB_ADDRESS_PORT}/?type=teethchart&id=${storedData}`,
            {
              method: "POST",
              headers: {
                "ngrok-skip-browser-warning": true,
                token: recievedToken,
              },
            }
          );
        })
        .then((createResponse) => {
          if (createResponse) {
            if (!createResponse.ok) {
              throw new Error("Network response was not ok");
            }
            return fetch(
              `${globalVariables.WEB_ADDRESS_PORT}/?type=getimage&patientid=${storedData}`,
              {
                method: "GET",
                headers: {
                  "ngrok-skip-browser-warning": true,
                  token: recievedToken,
                },
              }
            );
          }
        })
        .then((secondResponse) => {
          if (secondResponse) {
            if (!secondResponse.ok) {
              throw new Error("Network response was not ok");
            }
            return secondResponse.json();
          }
        })
        .then((secondData) => {
          if (secondData) {
            dispatch(
              { type: SET_DATA, payload: secondData },
              setLoading(false)
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  useEffect(() => {
    getData();
  }, [userType]);

  useEffect(() => {
    console.log(state.showAlert);
  }, [state.showAlert]);

  function onClick(th) {
    dispatch({ type: MODAL_VALUE, payload: th });
    dispatch({ type: SET_MODAL_ON });
  }

  const handleClose = () => {
    dispatch({ type: SET_MODAL_OFF });
    dispatch({ type: EMPTY_VALUE });
  };

  const teethLayout = (teethGroup) => {
    return (
      <div className="flex">
        {map(teethGroup, (teeth, index) => (
          <Fragment key={`qwe-${index}`}>
            <div>
              <div className={teethClass}>
                <TeethObject
                  topTeeth={teeth.topteeth}
                  bottomTeeth={teeth.bottomteeth}
                  leftTeeth={teeth.leftteeth}
                  rightTeeth={teeth.rightteeth}
                  middleTeeth={teeth.middleteeth}
                  teethNumber={teeth.teethnumber}
                  onClick={() => onClick(teeth, teethGroup)}
                />
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-[110%]">
      <div className="whitespace-nowrap">
        <Box
          style={{
            width: "100%",
            height: "830px",
          }}
        >
          <div className="">
            <div className={initialRowClass}>
              {teethLayout(state.initialTeethState2)}
              {teethLayout(state.initialTeethState6)}
            </div>
            <div className={rowClass}>
              {teethLayout(state.initialTeethState1)}
              {teethLayout(state.initialTeethState5)}
            </div>
            <div className={rowClass}>
              {teethLayout(state.initialTeethState4)}
              {teethLayout(state.initialTeethState8)}
            </div>
            <div className={initialRowClass}>
              {teethLayout(state.initialTeethState3)}
              {teethLayout(state.initialTeethState7)}
            </div>
            <Button
              sx={{marginTop: "30px"}}
              onClick={() => dispatch({ type: UPLOAD_VALUE })}
              variant="contained"
            >
              Save
            </Button>
            <div className="my-5 flex justify-center text-xl font-bold">
              Legend
            </div>
            <div className="bg-gray-200 h-[18rem]">
              <TeethColumn color={TEETH_BEIGE} label={"Crown"} />
              <TeethColumn color={TEETH_GRAY} label={"Metal Crown"} />
              <TeethColumn color={swapBlue} label={"Composite Filling"} />
              <TeethColumn color={TEETH_BLACK} label={"Amalgam Filling"} />
              <TeethColumn color={swapRed} label={"Cavity"} />
              <TeethColumn color={TEETH_WHITE} label={"Normal"} />
            </div>
          </div>
          <TeethModal
            show={state.showModal}
            handleClose={handleClose}
            teeth={state.modalTeeth}
            dispatch={dispatch}
          />
        </Box>
      </div>
    </div>
  );
}

export default TeethImage;
