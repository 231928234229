import { Route, Routes } from "react-router-dom";
import ExpensePage from "./sub-pages/ExpensePage";
import BillsPage from "./sub-pages/BillsPage";
import { useEffect } from "react";

function AccountingPage({userType}) {
  return (
    <div>
      <Routes>
        <Route path="/expenseselect" element={<ExpensePage userType={userType}/>} />
        <Route path="/billsselect" element={<BillsPage userType={userType}/>} />
      </Routes>
    </div>
  );
}

export default AccountingPage;
