import { Box, Button, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import globalVariables from "../../globalVariables";
import { AiOutlineFile, AiOutlineHome } from "react-icons/ai";
import { SlNotebook } from "react-icons/sl";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { PiBooksLight } from "react-icons/pi";
import Link from "./Link";

function SidebarModal({ open, onClose, closeModal}) {
  const [dentistID, setDentistID] = useState(0);
  const ICON_SIZE = "mr-1 text-4xl";

  const getDentistID = async () => {
    let request;
    // get dentist id through token
    try {
      request = await fetch(
        `${globalVariables.WEB_ADDRESS_PORT}/auth/verification`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    setDentistID(request.user);
  };

  const links =
    parseInt(localStorage.getItem("currentUser"), 10) === dentistID
      ? [
          {
            label: "Home",
            path: "/clinic/home",
            icon: <AiOutlineHome className={ICON_SIZE} />,
            id: "home-id",
          },
          {
            label: "Document",
            path: "/document",
            icon: <SlNotebook className={ICON_SIZE} />,
            id: "document-id",
          },
          {
            label: "Invoice",
            path: "/clinic/invoice",
            icon: <LiaFileInvoiceDollarSolid className={ICON_SIZE} />,
            id: "invoice-id",
          },
          {
            label: "Records",
            path: "/clinic/records",
            icon: <PiBooksLight className={ICON_SIZE} />,
            id: "records-id",
          },
          {
            label: "Accounting",
            path: "/accounting",
            icon: <AiOutlineFile className={ICON_SIZE} />,
            id: "accounting-id",
          },
        ]
      : [
          {
            label: "Home",
            path: "/clinic/home",
            icon: <AiOutlineHome className={ICON_SIZE} />,
          },
          {
            label: "Document",
            path: "/document",
            icon: <SlNotebook className={ICON_SIZE} />,
          },
          {
            label: "Invoice",
            path: "/clinic/invoice",
            icon: <LiaFileInvoiceDollarSolid className={ICON_SIZE} />,
          },
          {
            label: "Records",
            path: "/clinic/records",
            icon: <PiBooksLight className={ICON_SIZE} />,
          },
        ];

  const renderedLinks = links.map((link) => {
    return (
      <Link
        className="mb-3 flex flex-col items-center text-xs"
        key={link.label}
        to={link.path}
        id={link.id}
        closeModal={closeModal}
      >
        {link.icon}
        {link.label}
      </Link>
    );
  });

  useEffect(() => {
    getDentistID();
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "0", // Align to the left
          transform: "translateY(-50%)", // Only translate vertically to center
          backgroundColor: "white",
          width: "110px",
          height: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="bg-white flex flex-col items-center text-2xl"
          style={{ position: "absolute", zIndex: "1" }}
        >
          <div className="mt-5">{renderedLinks}</div>
        </div>
      </Box>
    </Modal>
  );
}

export default SidebarModal;
