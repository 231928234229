import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import globalVariables from "../../../../../globalVariables";
import { useNavigate } from "react-router-dom";

const WeekView = ({ weekViewDate, toDate, calendarView, highlightCard }) => {
    const currentDate = useMemo(() => {
        let week = dayjs(weekViewDate);
        week = dayjs(week).week(week.week()).day(0);
        return week;
    }, [weekViewDate]);

    const navigate = useNavigate();

    const [chairs, setChairs] = useState(3);

    const times = ["", "8:00 AM", "8:30 AM", "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM" ];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    const [appointmentList, setAppointmentList] = useState([]);
    const getAppointmentList = useCallback( async () => {
        let appointments;
        // get all appointment
        try {
            appointments = await fetch(
                `${globalVariables.WEB_ADDRESS_PORT}/?type=appointment`, {
                    method: 'GET',
                    headers: {
                        "ngrok-skip-browser-warning": true,
                        token: localStorage.token,
                        'Content-Type' : 'application/json'
                    }
                }
            );
        } catch (error) {
            console.log("...");
        }
        appointments = await appointments.json();

        appointments = appointments.map(async (appointment) => {
            const appointmentDate = dayjs(appointment.timestart);
            if (currentDate.week() === appointmentDate.week() && currentDate.year() === appointmentDate.year()) {
                // get patient
                let patient;
                try {
                    patient = await fetch(
                        `${globalVariables.WEB_ADDRESS_PORT}/?type=information&id=${appointment.patientid}`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": true,
                            token: localStorage.token,
                            'Content-Type': 'application/json'
                        }
                    }
                    );
                } catch (error) {
                    console.log("...");
                }
                patient = await patient.json();
                patient = patient[0];

                // get dentist
                let dentist;
                try {
                    dentist = await fetch(
                        `${globalVariables.WEB_ADDRESS_PORT}/?type=dentistinformation&id=${appointment.dentistid}`, {
                        method: 'GET',
                        headers: {
                            "ngrok-skip-browser-warning": true,
                            token: localStorage.token,
                            'Content-Type': 'application/json'
                        }
                    }
                    );
                } catch (error) {
                    console.log("...");
                }
                dentist = await dentist.json();
                dentist = dentist[0];

                // convert starting time
                let timestart = new Date(appointment.timestart);
                // convert starting time to row start
                let rowStart = timestart.getHours() - 7;
                rowStart = (rowStart * 2) - 1;
                rowStart = rowStart + (timestart.getMinutes() / 30);
                rowStart = rowStart + 1;

                // convert ending time
                let timeend = new Date(appointment.timeend);
                // convert ending time to row end
                let rowEnd = timeend.getHours() - 7;
                rowEnd = (rowEnd * 2) - 1;
                rowEnd = rowEnd + (timeend.getMinutes() / 30);
                rowEnd = rowEnd + 1;

                // convert starting time to column start
                let columnStart = dayjs(timestart);
                columnStart = columnStart.day();

                return {
                    id: appointment.id,
                    patient: patient,
                    dentist: dentist,
                    procedure: appointment.title,
                    color: appointment.color,
                    chair: appointment.chair,
                    status: appointment.status,

                    rowStart: rowStart,
                    rowEnd: rowEnd,
                    columnStart: columnStart,
                    timestart: dayjs(timestart),
                    timeend: dayjs(timeend)
                }
            }
        })
        appointments = await Promise.all(appointments);
        appointments = appointments.filter(appointment => appointment !== undefined);

        setAppointmentList(appointments);
    }, [currentDate]);

    useEffect(() => {
        calendarView('week');
        highlightCard({id: 0});
        getAppointmentList();
    }, [weekViewDate]);

    return (
        <div 
            class="relative grid grid-flow-row max-h-[74vh] overflow-y-auto pr-[10px]"
            style={{gridTemplateColumns: `100px repeat(7,minmax(0,1fr))`}}
        >
            {times.map((time, i) => (
                <Fragment>
                    <div
                        key={i}
                        className="grid col-start-1 text-right h-[50px] pr-[10px]"
                        style={{ borderBottomWidth: i !== 20 ? `2px` : `0px` }}
                    >
                        <div
                            className={`-translate-y-[13px] bg-white pr-[5px]`}
                        >
                            {time}
                        </div>
                    </div>
                    {days.map((day, j) => (
                        <div
                            key={j}
                            className="grid text-center h-[50px] content-center hover:bg-gray-100 active:bg-gray-50 z-0"
                            style={{
                                gridColumnStart: j + 2,
                                borderBottomWidth: i !== 20 ? `2px` : `0px`,
                                borderLeftWidth: i !== 20 ? `2px` : `2px`,
                                pointerEvents: (i === 0 || i === 20) ? `none` : ``
                            }}
                            onClick={() => {
                                toDate(dayjs(currentDate).date(dayjs(currentDate).date() + j));
                                return navigate('/clinic/home/day-view');
                            }}
                        >
                            {
                                i === 0 ?
                                    <Fragment>
                                        <div class="text-[15px] font-bold">
                                            {day}
                                        </div>
                                        <div class="text-[#ac7de8]">
                                            {(dayjs(currentDate).date(dayjs(currentDate).date() + j)).format("MMM D, YYYY")}
                                        </div>
                                    </Fragment> : ''
                            }
                        </div>
                    ))}
                </Fragment>
            ))}
            {appointmentList.map((appointment, i) => (
                <Fragment>
                    <div
                        key={i}
                        className="appointment-card"
                        class={`absolute flex flex-row rounded-md p-[5px] z-10 translate-x-[2px]`}
                        style={{
                            height: `calc((48px * (${appointment.rowEnd} - ${appointment.rowStart})) + (2px * (${appointment.rowEnd} - ${appointment.rowStart})))`,
                            width: `calc(((100% / (7 - ${appointment.columnStart})) - (30px / (7 - ${appointment.columnStart}))) / ${chairs})`,
                            left: `calc((((100% / (7 - ${appointment.columnStart})) - (30px / (7 - ${appointment.columnStart}))) / ${chairs}) * (${appointment.chair - 1}))`,
                            backgroundColor: appointment.color,
                            filter: appointment.status === "requesting cancellation" ? "brightness(30%)" : "",
                            gridColumnStart: `${appointment.columnStart + 2}`,
                            gridRow: `${appointment.rowStart} / ${appointment.rowEnd}`
                        }}
                        onClick={() => {
                            toDate(dayjs(appointment.timestart));
                            highlightCard({id: appointment.id});
                            return navigate('/clinic/home/day-view');
                        }}
                    >

                    </div>
                </Fragment>
            ))}

        </div>
    );
};

export default WeekView;