import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import './PersonalDetails.css';

import { ColoredButton, Dropdown, RegisterContainer, Textfield } from "../../../../../components";
import { monthToNumber } from "../../../../../utils";
import validator from "validator";

const PersonalDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientInformation } = location.state || {};

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const sexes = ["Male", "Female"];

    const [details, setDetails] = useState({
        year: '',
        month: '',
        day: '',
        sex: '',
        weight: '',
        height: ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            month: details.month,
            sex: details.sex
        }));
    }, [details.month, details.sex]);

    const validateDateOfBirth = () => {
        const dateOfBirth = `${details.year}-${monthToNumber(details.month)}-${details.day}`;
        if (!validator.isDate(dateOfBirth, { format: 'YYYY-MM-DD', strictMode: true })) {
            return false;
        }

        const birthdate = new Date(dateOfBirth);
        const today = new Date();
        const minBirthdate = new Date(today);
        minBirthdate.setFullYear(today.getFullYear() - 1);
        const maxBirthdate = new Date(today);
        maxBirthdate.setFullYear(today.getFullYear() - 101);

        return !(birthdate > today || birthdate > minBirthdate || birthdate < maxBirthdate);
    };

    const validateDetails = () => {
        const { year, month, day, sex, weight, height } = details;
        let newErrors = {};

        if (!year || !month || !day || !validateDateOfBirth()) {
            newErrors.dateOfBirth = "Invalid Date of Birth";
        }

        if (!sex) {
            newErrors.sex = "Please select your sex";
        }

        const weightValue = parseFloat(weight);
        if (isNaN(weightValue) || weightValue <= 0 || weightValue > 500) {
            newErrors.weight = "Invalid weight (max 500 kg)";
        }

        const heightValue = parseFloat(height);
        if (isNaN(heightValue) || heightValue <= 0 || heightValue > 300) {
            newErrors.height = "Invalid height (max 300 cm)";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (field, value) => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            [field]: value
        }));
    };

    const next = () => {
        if (validateDetails()) {
            const { year, month, day, sex, weight, height } = details;
            const personalDetails = {
                dateofbirth: `${year}-${monthToNumber(month)}-${day}`,
                patientgender: sex,
                patientweight: weight,
                patientheight: height
            };

            const updatedPatientInfo = {
                ...patientInformation,
                ...personalDetails
            };

            navigate('/auth/patient/signup/register/address', { state: { patientInformation: updatedPatientInfo } });
        }
    };

    return (
        <div className="personal-details">
            <div className="personal-details-container" onKeyDown={(event) => { if (event.key === "Enter") next(); }}>
                <RegisterContainer
                    header={`So you're ${patientInformation?.namefirst}?`}
                    subheader="Please, tell us more about you!"
                    fields={
                        <div className="personal-details-fields">
                            <div className="personal-details-fields-top">
                                <Dropdown
                                    items={months}
                                    title="Month"
                                    prompt={errors.dateOfBirth || "These fields are required."}
                                    isEmpty={!!errors.dateOfBirth}
                                    getValue={(value) => handleInputChange('month', value)}
                                />
                                <Textfield
                                    title="Day"
                                    prompt={errors.dateOfBirth || ""}
                                    isEmpty={!!errors.dateOfBirth}
                                    getValue={(value) => handleInputChange('day', value)}
                                    setValue={details.day}
                                    style={{ width: '50%' }}
                                    minLength={2}
                                    numberOnly={true}
                                />
                                <Textfield
                                    title="Year"
                                    prompt={errors.dateOfBirth || "minimum=1yr maximum=100yr"}
                                    isEmpty={!!errors.dateOfBirth}
                                    getValue={(value) => handleInputChange('year', value)}
                                    setValue={details.year}
                                    minLength={4}
                                    numberOnly={true}
                                />
                            </div>
                            <div className="personal-details-fields-bottom">
                                <Dropdown
                                    items={sexes}
                                    title="Sex"
                                    prompt={errors.sex || "Please Select"}
                                    isEmpty={!!errors.sex}
                                    getValue={(value) => handleInputChange('sex', value)}
                                />
                                <Textfield
                                    title="Weight (kg)"
                                    prompt={errors.weight || "Weight should not exceed 500 kgs"}
                                    isEmpty={!!errors.weight}
                                    getValue={(value) => handleInputChange('weight', value)}
                                    setValue={details.weight}
                                />
                                <Textfield
                                    title="Height (cm)"
                                    prompt={errors.height || "Height should not exceed 300 cm"}
                                    isEmpty={!!errors.height}
                                    getValue={(value) => handleInputChange('height', value)}
                                    setValue={details.height}
                                />
                            </div>
                        </div>
                    }
                    options={
                        <div className="personal-details-container-options">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default PersonalDetails;
