export const COLORBLIND = "colorblind"

// Teeth Color Constants
export const TEETH_GRAY = "#bfbfbf";
export const TEETH_RED =  "#f9425a";
export const TEETH_GREEN = "#42f548";
export const TEETH_BLUE = "#4287f5";
export const TEETH_YELLOW = "#f5bc42";
export const TEETH_BLACK = "#2F2F2F";
export const TEETH_BEIGE = "#e8c39e";
export const TEETH_WHITE = "#ffffff";
export const TEETH_ORANGE = "#E3963E"
export const TEETH_TEAL = "#008080";
export const TEETH_MAGENTA = "#4e004e"
export const NORMAL = "normal";
export const METAL_CROWN = "metal-crown";
export const AMALGAM_FILLING = "amalgam-filling";
export const COMPOSITE_FILLING = "composite-filling";
export const CAVITY = "cavity";

// TeethImage Constants
export const CHANGE_TOP = "change-top";
export const CHANGE_BOT = "change-bot";
export const CHANGE_LEFT = "change-left";
export const CHANGE_RIGHT = "change-right";
export const CHANGE_MID = "change-mid";
export const SAVE_VALUE = "save-value"
export const UPLOAD_VALUE = "upload-value"

//DocumentPage Constants
export const CERTFICATE_SELECT = "certificates";
export const WORK_SELECT = "work";
export const DOSAGE_SELECT = "dosage";
export const RTX_SELECT = "rtx";
export const CONSENT_SELECT = "consent";

export const db = [
    {id: 0, name: "Lights?", cost: "20"},
]

//ExpensePage Constants
export const CHANGE_NAME = "change-name"
export const CHANGE_EXPENSE = "change-expense"
export const IS_PAID = "is-paid"
export const CHANGE_QUANTITY= "change-quantity"
export const CHANGE_CPU = "change-cpu"
export const PUSH_DATA = "push-data" 
export const CHANGE_TOTAL = "change-total"
export const CLEAR_DATA = "clear-data"
export const SET_PAID = "set-paid"
export const SET_EDIT = "set-edit"
export const LOADING = "loading"
export const EDIT_DATA = "edit-data"
export const SET_ID = "set-id"
export const DELETE_ID = "delete-id"
export const SET_ROW = "set-row"
export const EXPENSE_SELECT = "select-expense"
export const BILLS_SELECT = "bills-expense"

//PedoDosage constants
export const FIFTEEN = 15
export const TWENTY = 20
export const FIFTY = 50
export const ONETWOFIVE = 125
export const TWOFIFTY = 250
export const FIVEZEROZERO = 500

//BillsPage Constants
export const SET_NAME = "set-name"
export const AMOUNT_DUE = "amount-due"
export const AMOUNT_AFTER_DUE = "amount-after-due"
export const DUE_DATE = "due-date"
export const DATE_PAID = "date-paid"
export const AMOUNT_PAID = "amount-paid"
export const CLEAR_ERROR = "clear-error"
export const DELETE_DATA = "delete-data"

//CertificateText Constants
export const MR = "Mr."
export const MS = "Ms."
export const MRS = "Mrs."
export const MALE = "Male"
export const FEMALE = "Female"

//Consent Constants
export const INFORMED_CONSENT = "informed-consent"

//Work Auth Constants
export const CROWN = "crown"

//App Constants
export const SWAP_HOME = "change-tab"
export const SWAP_DOC = "change-doc"
export const SWAP_INV = "change-inv"
export const SWAP_REC = "change-rec"
export const SWAP_ACC = "change-acc"
export const SWAP_NULL = "change-null"
export const CHANGE_PATIENT = 'change-patient'
export const LOGIN = "login"
export const PULLED_DATA = "pulled-data"

//Mobile Checker
export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const ALWAYS = "Always"
export const SELDOM = "Seldom"
export const NEVER = "Never"

//Color
export const DEFAULT_PURPLE = "#ac7de8"
export const LIGHT_PURPLE = "#e3d4f7"
export const DEFAULT_BLUE = "#8eccff"

export const BACKENDURL = "192.168.1.18"
export const BACKENDPORT = "3500"

export const BACKENDFULLURL = `http://${BACKENDURL}:${BACKENDPORT}`

export const SET_TRUE = "set_true"
export const SET_FALSE = "set_false"
export const SET_DATA = "set-data"

//Links
export const PATIENT_NAME = "fa9b22c8-6a59-4478-a9af-2c8759888534"

export const JSON_NAME = "fffb58176ea9"
export const TOKEN_NAME  = "token"

export const recievedToken = localStorage.getItem(TOKEN_NAME)

export const GET_TOKEN = "get-token"
export const SET_RELOAD = "set-reload"
