const MonthToNumber = (monthString) => {
    monthString = monthString.toLowerCase();
    switch(monthString) {
        case 'january':
            return '01';
        case 'february':
            return '02';
        case 'march':
            return '03';
        case 'april':
            return '04';
        case 'may':
            return '05';
        case 'june':
            return '06';
        case 'july':
            return '07';
        case 'august':
            return '08';
        case 'september':
            return '09';
        case 'october':
            return '10';
        case 'november':
            return '11';
        case 'december':
            return '12';
        default:
            return false;
    }
}

export default MonthToNumber;