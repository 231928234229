import React, { useState } from "react";
import './QuestionModal.css'
import { ColoredButton, Textfield } from "../../../../components";

const QuestionModal = ({ setIsOpen, getValue, setValue, options, optionsWithFields }) => {

    const [selectedOptions, setSelectedOptions] = useState(() => {
        return setValue && setValue;
    });

    const handleOptionSelect = (option, value) => {
        if (selectedOptions.some((item) => item.label === option)) {
            setSelectedOptions(selectedOptions.filter(item => item.label !== option));
        } else {
            setSelectedOptions([...selectedOptions, {label: option, value: value}]);
        }
    };

    const submit = () => {
        getValue(selectedOptions);
        setIsOpen(false);
    };

    return (
        <div className="question-modal-container">
            <div className="question-modal-header">
                <div className="question-modal-header-text">
                    Choose which applies:
                </div>
            </div>
            <div className="question-modal-body">
                {options && options.map((option) => {
                    return (
                        <div key={option} className="question-modal-option">
                            <div className="question-modal-option-radio">
                                <input 
                                    type="checkbox"
                                    checked={selectedOptions.some((item) => item.label === option)}
                                    onChange={() => handleOptionSelect(option, option)}
                                />
                                {option}                                
                            </div>
                        </div>
                    )
                })}
                {optionsWithFields && Array.isArray(optionsWithFields) && optionsWithFields.map((option) => {
                    return (
                        <div key={option} className="question-modal-option" style={{paddingBottom: "10px"}}>
                            <div className="question-modal-option-radio">
                                <input 
                                    type="checkbox"
                                    checked={selectedOptions.some((item) => item.label === option)}
                                    onChange={() => handleOptionSelect(option, option)}
                                />
                                {option}                                                            
                            </div>
                            <Textfield
                                setValue={() => {
                                    const temporaryValue = setValue.filter((item) => item.label === option);
                                    return temporaryValue[0] ? temporaryValue[0].value : '';
                                }}
                                getValue={(value) => {
                                    setSelectedOptions(selectedOptions.map((item) => {
                                        if(item.label === option) {
                                            return {
                                                label: option,
                                                value: value
                                            };
                                        }
                                        return item;
                                    }));
                                }}
                                disabled={!selectedOptions.some((item) => item.label === option)}
                                style={{
                                    height: "10px"
                                }}
                            /> 
                        </div>
                    )
                })}
            </div>
            <div className="question-modal-footer">
                <ColoredButton
                    text="Submit"
                    color="#ffffff"
                    backgroundColor="#5b346b"
                    backgroundColorOnHover="#6d3f80"
                    onClick={submit}
                />
            </div>
        </div>
    );
};

export default QuestionModal;
