import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, Modal, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { EDIT_DATA, PUSH_DATA } from "../../components/clinic/Constant";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

function BillsModal({ open, onClose, state, dispatch, editMode, setError }) {
  const [selectState, setSelectState] = useState(state);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" }); 
  useEffect(() => {
    setSelectState(state);
  }, [state]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          width: isPhone ? "80%" : "60%",
          height: isPhone ? "80%" : "60%",
          padding: "32px",
          overflowY: "auto",
        }}
      >
        <div>
          <div className="mb-5">
            <TextField
              required
              id="set-name-field"
              label="Name"
              helperText="Name"
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  item: event.target.value,
                }))
              }
              value={selectState.item}
              error={setError}
            />
          </div>
          <div className="mb-5 ">
          <TextField
            required
            id="set-amountdue-field"
            label="Amount Due"
            helperText="Amount Due"
            className={"w-full"}
            onChange={(event) =>
              setSelectState((prevState) => ({
                ...prevState,
                amountdue: event.target.value.replace(/[^0-9]/g, ""),
              }))
            }
            value={selectState.amountdue}
            error={setError}
          />
          </div>
          <div className="mb-5">
          <TextField
            id="set-amountafterdue-field"
            label="Amount After Due"
            helperText="Amount After Due"
            className={"w-full"}
            onChange={(event) =>
              setSelectState((prevState) => ({
                ...prevState,
                amountafterdue: event.target.value.replace(/[^0-9]/g, ""),
              }))
            }
            value={selectState.amountafterdue}
          />
          </div >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
           <div className="mb-5">
           <DatePicker
              required
              label="Due Date"
              id={"set-duedate-field"}
              value={selectState.duedate}
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  duedate: event,
                }))
              }
            />
           </div>
          <div className="mb-5">
          <DatePicker
              required
              label="Date Paid"
              id="set-datepaid-field"
              value={selectState.datepaid}
              className={"w-full"}
              onChange={(event) =>
                setSelectState((prevState) => ({
                  ...prevState,
                  datepaid: event,
                }))
              }
            />
          </div>
          </LocalizationProvider>
         <div>
         <TextField
            id="set-amountpaid-field"
            label="Amount Paid"
            helperText="Amount Paid"
            className={"w-full"}
            onChange={(event) =>
              setSelectState((prevState) => ({
                ...prevState,
                amountpaid: event.target.value.replace(/[^0-9]/g, ""),
              }))
            }
            value={selectState.amountpaid}
          />
         </div>
          <Button
          id={"save-bill-button"}
            onClick={() =>
              dispatch({
                type: editMode === true ? EDIT_DATA : PUSH_DATA,
                payload: selectState,
              })
            }
          >
            Save
          </Button>
          {/* {state.date ? state.date.toISOString().slice(0, 10) : " "} */}
        </div>
      </Box>
    </Modal>
  );
}

export default BillsModal;
