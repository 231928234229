import Link from "./Link";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { SlNotebook } from "react-icons/sl";
import { PiBooksLight } from "react-icons/pi";
import { AiOutlineHome, AiOutlineFile } from "react-icons/ai";
import { useEffect, useState } from "react";
import globalVariables from "../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const ICON_SIZE = "mr-1 text-4xl";
function Sidebar() {
  const isPhone = useMediaQuery({ query: '(max-width: 768px)' });
  const [dentistID, setDentistID] = useState(0);

  const sidebarClass = classNames(isPhone ? "hidden" : "mr-5 h-full shadow-lg")



  const getDentistID = () => {
    // get dentist id through token
    fetch(`${globalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
      method: 'GET',
      headers: {
        "ngrok-skip-browser-warning": true,
        token: localStorage.token
      }
    })
    .then(response => response.json())
    .then(data => {
      setDentistID(data.user);
    })
    .catch(error => {
      console.log("...");
    });
  };

  const links = localStorage.getItem("currentUserRole") === "dentist" ? [ 
    {
      label: "Home",
      path: "/clinic/home",
      icon: <AiOutlineHome className={ICON_SIZE}  />,
      id: "home-id"
    },
    {
      label: "Document",
      path: "/document",
      icon: <SlNotebook className={ICON_SIZE}  />,
      id: "document-id"
    },
    {
      label: "Invoice",
      path: "/clinic/invoice",
      icon: <LiaFileInvoiceDollarSolid className={ICON_SIZE}  />,
      id: "invoice-id"
    },
    {
      label: "Records",
      path: "/clinic/records",
      icon: <PiBooksLight className={ICON_SIZE}  />,
      id: "records-id"
    },
    {
      label: "Accounting",
      path: "/accounting",
      icon: <AiOutlineFile className={ICON_SIZE} />,
      id: "accounting-id"
    },
  ] : [ 
    {
      label: "Home",
      path: "/clinic/home",
      icon: <AiOutlineHome className={ICON_SIZE}  />,
    },
    {
      label: "Document",
      path: "/document",
      icon: <SlNotebook className={ICON_SIZE}  />,
    },
    {
      label: "Invoice",
      path: "/clinic/invoice",
      icon: <LiaFileInvoiceDollarSolid className={ICON_SIZE}  />,
    },
    {
      label: "Records",
      path: "/clinic/records",
      icon: <PiBooksLight className={ICON_SIZE}  />,
    }
  ];

  const renderedLinks = links.map((link) => {
    return (
      <Link
        className="mb-3 flex flex-col items-center text-xs"
        key={link.label}
        to={link.path}
        id={link.id}
      >
        {link.icon}
        {link.label}
      </Link>
    );
  });

  useEffect(() => {
    getDentistID();
  }, [])

  return (
    <div className={sidebarClass}>
      <div
        className="bg-white pr-7 flex flex-col items-center text-2xl w-[150px]"
        style={{ position: "absolute", zIndex: "1" }}
      >
        <div className="mt-5">{renderedLinks}</div>
      </div>
      <div className="h-[90%] w-1 bg-gray-500 absolute ml-[150px] mt-[20px]" />
    </div>
  );
}

export default Sidebar;
