import React, { useState } from "react";
import './ClinicRegister.css';

import { RegisterContainer, Textfield, ColoredButton } from "../../../../../components";
import { useLocation, useNavigate } from "react-router-dom";

const ClinicRegister = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { clinicNoPRC } = location.state || {};

    const [prcNumberPrompt, setPRCNumberPrompt] = useState('');
    const [prcNumberIsEmpty, setPRCNumberIsEmpty] = useState(false);
    const [prcNumber, setPRCNumber] = useState('');
    
    const next = async () => {
        // check if PRC Number is empty
        if (!prcNumber.trim().length) {
            setPRCNumberPrompt("This field is required!");
            return setPRCNumberIsEmpty(true);
        }

        // check if PRC Number is valid (only digits and exactly 7 characters)
        const prcNumberRegex = /^[0-9]{7}$/;
        if (!prcNumberRegex.test(prcNumber.trim())) {
            setPRCNumberPrompt("Enter a valid 7-digit PRC number.");
            return setPRCNumberIsEmpty(true);
        } else {
            setPRCNumberPrompt('');
            setPRCNumberIsEmpty(false);
        }

        // create clinic data
        const dentistInformation = {
            email: clinicNoPRC.email,
            numbermobile: clinicNoPRC.numbermobile,
            password: clinicNoPRC.password,
            namefirst: clinicNoPRC.namefirst,
            namemiddle: clinicNoPRC.namemiddle,
            namelast: clinicNoPRC.namelast,
            numberprc: prcNumber,
            color: "#e3d4f7"
        };

        navigate('/auth/clinic/signup/clinic-details', { state: { dentistInformation } });
    };

    return (
        <div className="clinic-register">
            <div className="clinic-register-container"
                onKeyDown={(event) => {
                    if (event.key === "Enter") next();
                }}
            >
                <RegisterContainer
                    header={`So, Dr. ${clinicNoPRC.namelast}`}
                    subheader="Please provide your PRC number."
                    fields={
                        <div className="clinic-register-field-container">
                            <Textfield
                            
                                title="PRC number"
                                prompt={prcNumberPrompt}
                                isEmpty={prcNumberIsEmpty}
                                getValue={setPRCNumber}
                                setValue={prcNumber}
                                
                            />
                        </div>
                    }
                    options={
                        <div className="clinic-register-options-container">
                            <ColoredButton
                                text="Next"
                                color="#ffffff"
                                backgroundColor="#5b346b"
                                backgroundColorOnHover="#6d3f80"
                                onClick={next}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default ClinicRegister;
