import React from "react";
import { useNavigate } from 'react-router-dom';
import './OptionsSignUpPage.css';

import { option_image_clinic_personnel, option_image_patient } from '../../../assets';
import { RegisterContainer } from "../../../components";

const OptionsSignUpPage = () => {
    const navigate = useNavigate();

    const toPatientSignup = () => {
        localStorage.setItem("userType", "patient");
        navigate("/auth/patient/signup/user-details");
    };

    const toClinicSignup = () => {
        localStorage.setItem("userType", "clinic");
        navigate("/auth/clinic/signup/user-details");
    };

    return (
        <div className="signup">
            <RegisterContainer
                header="Let's Get Started"
                subheader="Are you a ..."
                options={
                    <div className="os-option-container">
                        <div className="os-option-button" onClick={toClinicSignup}>
                            <img className="os-option-image" src={option_image_clinic_personnel} alt=""/>
                            <div className="os-option-text">Clinic Personnel</div>
                        </div>
                        <div className="os-option-button" onClick={toPatientSignup}>
                            <img className="os-option-image" src={option_image_patient} alt=""/>
                            <div className="os-option-text">Patient</div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default OptionsSignUpPage;