import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TOKEN_NAME } from "../../components/clinic/Constant";
import globalVariables from "../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

function InvoicePage({ userType }) {
  const [mergedData, setMergedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const textClass = classNames("font-bold", isPhone ? "text-l" : "text-xl");

  const tableStyles = {
    minWidth: isPhone ? '100%' : 650,
    '& .MuiTableCell-root': {
      padding: isPhone ? '8px' : 'default',
      fontSize: isPhone ? '12px' : 'default', // Adjust font size for smaller screens
    },
  };

  function fetchPatientInfo() {
    const token = localStorage.getItem(TOKEN_NAME);
    if (token && userType === "clinic") {
      try {
        Promise.all([
          fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=informationname`, {
            method: "GET",
            headers: {
              token: token,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }).then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          }),

          fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=getinvoice`, {
            method: "GET",
            headers: {
              token: token,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }).then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          }),
        ])
          .then(([data1, data2]) => {
            const combinedArray = [];
            data1.forEach((patient) => {
              const costInfo = data2.find(
                (cost) => cost.patientid === patient.id.toString()
              ) || { totalcost: 0, completedcost: 0 };
              combinedArray.push({
                ...patient,
                totalcost: costInfo.totalcost,
                completedcost: costInfo.completedcost,
              });
            });
            setMergedData(combinedArray);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error at: ", error);
      }
    }
  }
  useEffect(() => {
    fetchPatientInfo();
  }, [userType]);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="mt-20 ml-5">
      <div className="w-[95%]">
        <TableContainer component={Paper}>
          <Table
            sx={tableStyles}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow className="bg-[#ac7de8]">
                <TableCell>
                  <p className={textClass}>Patient Name</p>
                </TableCell>
                <TableCell>
                  <p className={textClass}>Billed </p>
                </TableCell>
                <TableCell>
                  <p className={textClass}> Paid</p>
                </TableCell>
                <TableCell>
                  <p className={textClass}> Balance</p>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergedData.map((row) => (
                <TableRow key={row.id + "-qwer"} className={""}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell> ₱{row.totalcost ?? 0} </TableCell>
                  <TableCell> ₱{row.completedcost ?? 0} </TableCell>
                  <TableCell>
                    ₱
                    {isNaN(-(row.completedcost - row.totalcost))
                      ? 0
                      : -(row.completedcost - row.totalcost)}{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default InvoicePage;
