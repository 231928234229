import React, { useEffect, useState } from "react";
import './ColoredButton.css';

const ColoredButton = ({text, color, colorOnHover, colorOnActive, backgroundColor, backgroundColorOnHover, backgroundColorOnActive, onClick}) => {

    const [buttonText, setButtonText] = useState('');
    const [buttonColor, setButtonColor] = useState('');
    const [buttonColorOnHover, setButtonColorOnHover] = useState('');
    const [buttonColorOnActive, setButtonColorOnActive] = useState('');
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState('');
    const [buttonBackgroundColorOnHover, setButtonBackgroundColorOnHover] = useState('');
    const [buttonBackgroundColorOnActive, setButtonBackgroundColorOnActive] = useState('');

    const [isActive, setIsActive] = useState(false);
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        if(text !== undefined) setButtonText(text);
        if(color !== undefined) setButtonColor(color);
        if(colorOnHover !== undefined) setButtonColorOnHover(colorOnHover); else setButtonColorOnHover(color);
        if(colorOnActive !== undefined) setButtonColorOnActive(colorOnActive); else setButtonColorOnActive(color);
        if(backgroundColor !== undefined) setButtonBackgroundColor(backgroundColor); else setButtonBackgroundColor(backgroundColor);
        if(backgroundColorOnHover !== undefined) setButtonBackgroundColorOnHover(backgroundColorOnHover); else setButtonBackgroundColorOnHover(backgroundColor);
        if(backgroundColorOnActive !== undefined) setButtonBackgroundColorOnActive(backgroundColorOnActive); else setButtonBackgroundColorOnActive(backgroundColor);
    }, [text, color, colorOnHover, colorOnActive, backgroundColor, backgroundColorOnHover, backgroundColorOnActive]);

    return (
        <div className="colored-button"
            style={{
                backgroundColor: (isHover) ? buttonBackgroundColorOnHover : (isActive) ? buttonBackgroundColorOnActive : buttonBackgroundColor
            }}
            onMouseOver={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
            onMouseDown={() => {
                setIsHover(false);
                setIsActive(true);
            }}
            onMouseUp={() => {
                setIsHover(true);
                setIsActive(false);
            }}
            onClick={(event) => onClick && onClick(event)}
        >
            <div className="colored-button-text"
                style={{
                    color: (isHover) ? buttonColorOnHover : (isActive) ? buttonColorOnActive : buttonColor
                }}
            >
                {buttonText}
            </div>
        </div>
    );
};

export default ColoredButton;