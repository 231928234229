import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Dashboard.css";

import GlobalVariables from "../../../globalVariables";

import { AppointmentCard, ColoredButton, Textfield } from "../../../components";

import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaHistory } from "react-icons/fa";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";

const Dashboard = () => {
  const navigate = useNavigate();
  const [patientID, setPatientID] = useState("");
  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });

  const [appointments, setAppointments] = useState([]);
  const [onUpdate, setOnUpdate] = useState(false);
  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false); // Fixed naming issue

  const [purpose, setPurpose] = useState("");
  const [purposeIsEmpty, setPurposeIsEmpty] = useState(false);
  const [balance, setBalance] = useState((Math.round(0 * 100) / 100).toFixed(2));
  const [recentPayable, setRecentPayable] = useState((Math.round(0 * 100) / 100).toFixed(2));
  const [startingTime, setStartingTime] = useState(null);
  const [timeError, setTimeError] = useState(false);
  const [dateError, setDateError] = useState(""); // New state for date error message

  const statusOrder = useMemo(() => {
    return ["pending", "active", "requesting cancellation", "requesting appointment", "done"];
  }, []);

  const userHasAppointmentRequest = async () => {
    let value = false;
    let appointmentRequest = await fetch(
      `${GlobalVariables.WEB_ADDRESS_PORT}/?type=appointment&patientid=${patientID}`,
      {
        method: "GET",
        headers: {
          token: localStorage.token,
          "ngrok-skip-browser-warning": true,
          "Content-Type": "application/json",
        },
      }
    );
    if (!appointmentRequest.ok)
      return console.log({ message: "error", error: appointmentRequest });
    appointmentRequest = await appointmentRequest.json();

    appointmentRequest.map(async (appointment) => {
      if (appointment.status === "requesting appointment") {
        value = true;
      }
      return value;
    });
    console.log(`user has requested an appointment: ${value}`);
    return value;
  };

  const makeRequestHandler = async () => {
    if (await userHasAppointmentRequest()) return setRequestModalIsOpen(false);
    else return setRequestModalIsOpen(true);
  };

  const createRequestHandler = async () => {
    if (!purpose.trim().length) {
      setPurposeIsEmpty(true);
      return;
    } else {
      setPurposeIsEmpty(false);
    }
  
    if (!startingTime) {
      setTimeError(true);
      return;
    } else {
      setTimeError(false);
    }
  
    const selectedDate = new Date(startingTime);
    const currentDate = new Date();
  
    if (isNaN(selectedDate.getTime())) {
      setDateError("Invalid date and time selected. Please choose a valid date and time.");
      return;
    }
  
    if (selectedDate < currentDate) {
      setDateError("The selected date and time is in the past. Please choose a future date and time.");
      return;
    } else {
      setDateError("");
    }
  
    if (await userHasAppointmentRequest()) {
      setRequestModalIsOpen(false);
      return;
    }
  
    const newAppointment = {
      patientid: patientID,
      dentistid: null,
      timestart: startingTime,
      timeend: startingTime,
      title: purpose,
      status: "requesting appointment",
      chair: 0,
      color: "#000000",
    };
  
    let request;
    try {
      request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=postappointment`,
        {
          method: "POST",
          body: JSON.stringify(newAppointment),
          headers: {
            "ngrok-skip-browser-warning": true,
            token: localStorage.token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    request = await request.json();
    console.log(request);
  
    setOnUpdate((prevState) => !prevState);
    setRequestModalIsOpen(false);
  };
  

  const getAppointment = useCallback(async () => {
    let appointmentRequest;
    try {
      appointmentRequest = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=appointment&patientid=${patientID}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    if (!appointmentRequest.ok)
      return console.log({ message: "error", error: appointmentRequest });
    appointmentRequest = await appointmentRequest.json();

    let appointmentFormatted = appointmentRequest.map(async (appointment) => {
      let dentistName = "";
      let formatteddate = new Date();
      if (appointment.dentistid !== null) {
        dentistName = await fetch(
          `${GlobalVariables.WEB_ADDRESS_PORT}/?type=dentist&id=${appointment.dentistid}`,
          {
            method: "GET",
            headers: {
              token: localStorage.token,
              "ngrok-skip-browser-warning": true,
              "Content-Type": "application/json",
            },
          }
        );
        if (!dentistName.ok)
          return console.log({ message: "error", error: dentistName });
        dentistName = await dentistName.json();
        dentistName = `${dentistName[0].namefirst} ${dentistName[0].namelast}`;
      }
      if (appointment.date !== null) {
        formatteddate = new Date(appointment.timestart);
        formatteddate = formatteddate.toLocaleString();
      }

      return {
        status: appointment.status,
        date: formatteddate,
        purpose: appointment.title,
        dentist: dentistName,
        id: appointment.id,
      };
    });

    appointmentFormatted = await Promise.all(appointmentFormatted);

    appointmentFormatted.sort((a, b) => {
      const statusAIndex = statusOrder.indexOf(a.status);
      const statusBIndex = statusOrder.indexOf(b.status);

      if (statusAIndex < statusBIndex) return -1;
      if (statusAIndex > statusBIndex) return 1;

      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setAppointments(appointmentFormatted);
  }, [statusOrder, patientID]);

  const getInvoice = useCallback(async () => {
    let invoice;
    try {
      invoice = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=getprocedure&patientid=${patientID}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return setBalance((Math.round(0 * 100) / 100).toFixed(2));
    }
    if (!invoice.ok) return console.log({ message: "error", error: invoice });
    invoice = await invoice.json();
    if (!Object.keys(invoice).length)
      return setBalance((Math.round(0 * 100) / 100).toFixed(2));

    invoice = invoice.filter((invoice) => !invoice.iscompleted);
    invoice = invoice.map((inv) => {
      return Number(inv.cost);
    });
    invoice = invoice.reduce(
      (accumulator, currentvalue) => accumulator + currentvalue,
      0
    );

    setBalance((Math.round(invoice * 100) / 100).toFixed(2));
  }, [patientID]);

  const getRecentPayable = useCallback(async () => {
    let procedures;
    try {
      procedures = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=&patientid=${patientID}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return setRecentPayable((Math.round(0 * 100) / 100).toFixed(2));
    }
    if (!procedures.ok)
      return console.log({ message: "error", error: procedures });
    procedures = await procedures.json();
    if (!Object.keys(procedures).length)
      return setRecentPayable((Math.round(0 * 100) / 100).toFixed(2));

    let prevID = 0;
    let currentID;
    let recent;
    procedures.map((procedure) => {
      currentID = procedure.id;
      if (prevID < currentID) {
        prevID = currentID;
        recent = procedure;
      }
      return 0;
    });

    setRecentPayable((Math.round(Number(recent.cost) * 100) / 100).toFixed(2));
  }, [patientID]);

  const getPatientID = useCallback(async () => {
    let patient = await fetch(
      `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`,
      {
        method: "GET",
        headers: {
          token: localStorage.token,
          "ngrok-skip-browser-warning": true,
          "Content-Type": "application/json",
        },
      }
    );
    if (!patient.ok) return console.log({ message: "error", error: patient });
    patient = await patient.json();
    setPatientID(patient.user);
  }, []);

  const toBilling = () => {
    navigate("/patient/billing");
  };

  useEffect(() => {
    getPatientID();
    if (patientID) {
      getAppointment();
      getInvoice();
    }
  }, [onUpdate, patientID, getPatientID, getAppointment, getInvoice, getRecentPayable, balance, recentPayable]);

  return (
    <div className="dashboard-container">
      <Modal className="request-modal" isOpen={requestModalIsOpen}>
        <div className="request-modal-header">Create Appointment Request</div>
        <div
          className="request-modal-fields"
          onKeyDown={(event) => {
            if (event.key === "Enter") createRequestHandler();
          }}
        >
          <Textfield
            title="Purpose"
            prompt="Please enter your purpose for appointment."
            getValue={setPurpose}
            setValue={purpose}
            isEmpty={purposeIsEmpty}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              minDate={dayjs()}
              minTime={dayjs().hour(8).minute(0)}
              maxTime={dayjs().hour(18).minute(0)}
              minutesStep={30}
              sx={{
                width: `100%`,
                marginTop: "10px",
              }}
              id="select-starting-time"
              label="Proposed Time"
              onChange={(event) => {
                setStartingTime(event);
              }}
            />
          </LocalizationProvider>
          {timeError && <div className="error-message">Please select a proposed time.</div>} {/* Time error message */}
          {dateError && <div className="error-message">{dateError}</div>} {/* Date error message */}
        </div>
        <div className="request-modal-options">
          <ColoredButton
            text="Create Appointment"
            color="#ffffff"
            backgroundColor="#5b346b"
            backgroundColorOnHover="#6d3f80"
            onClick={createRequestHandler}
          />
          <ColoredButton
            text="Cancel"
            color="#ffffff"
            backgroundColor="#5b346b"
            backgroundColorOnHover="#6d3f80"
            onClick={() => {
              setRequestModalIsOpen(false);
              setPurposeIsEmpty(false);
            }}
          />
        </div>
      </Modal>
      <Modal className={isPhone ? "" : "history-modal"} isOpen={historyModalIsOpen}>
        <div className="appointment-container-header-name">
          Appointment History
        </div>
        <div className={isPhone ? "" : "content"}>
          {appointments &&
            appointments
              .filter((appointment) => appointment.status === "done")
              .map((appointment) => (
                <AppointmentCard
                  status={appointment.status}
                  date={appointment.date}
                  purpose={appointment.purpose}
                  dentist={
                    appointment.status === "requesting appointment"
                      ? undefined
                      : appointment.dentist
                  }
                  id={appointment.id}
                  key={appointment.id}
                  onUpdate={setOnUpdate}
                />
              ))}
        </div>
        <ColoredButton
          text="Close"
          color="#ffffff"
          backgroundColor="#5b346b"
          backgroundColorOnHover="#6d3f80"
          onClick={() => setHistoryModalIsOpen(false)} // Fixed naming issue
        />
      </Modal>
      <div className="appointment-container">
        <div className="appointment-container-header-container">
          <div className="flex">
            <div className="appointment-container-header-name">
              Appointments
            </div>
            <FaHistory
              className="mt-3 text-xl ml-2 hover:cursor-pointer"
              onClick={() => setHistoryModalIsOpen(true)} // Fixed naming issue
            />
          </div>

          <div
            className="appointment-container-header-request-button"
            onClick={makeRequestHandler}
          >
            Make a Request
          </div>
        </div>
        {appointments &&
          appointments
            .filter((appointment) => appointment.status !== "done")
            .map((appointment) => (
              <AppointmentCard
                status={appointment.status}
                date={appointment.date}
                purpose={appointment.purpose}
                dentist={
                  appointment.status === "requesting appointment"
                    ? undefined
                    : appointment.dentist
                }
                id={appointment.id}
                key={appointment.id}
                onUpdate={setOnUpdate}
              />
            ))}
      </div>
      <div className="balance-container">
        <div className="balance-box">
          <div className="balance-box-header-container">
            <div className="balance-box-header">Balance</div>
          </div>
          <div className="balance-box-contents-container">
            <div className="balance-box-contents-recent-payable">
              <div className="balance-box-contents-recent-payable-title">
                Recent Payable
              </div>
              <div className="balance-box-contents-recent-payable-amount">
                PHP {recentPayable}
              </div>
            </div>
            <div className="balance-box-contents-previous-balance">
              <div className="balance-box-contents-previous-balance-title">
                Previous Balance
              </div>
              <div className="balance-box-contents-previous-balance-amount">
                PHP{" "}
                {Math.round(((balance - recentPayable) * 100) / 100).toFixed(2)}
              </div>
            </div>
            <div className="balance-box-contents-balance">
              <div className="balance-box-contents-balance-title">
                Total Balance
              </div>
              <div className="balance-box-contents-balance-amount">
                PHP {balance}
              </div>
            </div>
          </div>
          <div className="balance-box-options-container">
            <ColoredButton
              text="See List of Procedures"
              color="#ffffff"
              backgroundColor="#5b346b"
              backgroundColorOnHover="#6d3f80"
              onClick={toBilling}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
