import { Navigate, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../../components/clinic/Header";
import Sidebar from "../../components/clinic/Sidebar";
import HomePage from "./sub-pages/HomePage";
import DocumentPage from "./DocumentPage";
import AccountingPage from "./AccountingPage";
import MainRecordsPage from "./MainRecordsPage";
import InvoicePage from "./InvoicePage";
import SettingsPage from "./sub-pages/SettingsPage";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

function ClinicHomePage({ setIsAuthenticated, setUserType, userType }) {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const isPhone = useMediaQuery({ query: '(max-width: 768px)' });
  const mainClass = classNames("mt-[20px] shadow-lg rounded-lg overflow-y-auto p-5", isPhone ? "w-full h-full" : "ml-[200px] w-[80vw] h-[85%]")

  useEffect(() => {
    console.log(`isLoggedOut: ${isLoggedOut}`);
    if(isLoggedOut) {
      setIsAuthenticated(false);
      setUserType('');
    }
  }, [isLoggedOut, setIsAuthenticated, setUserType]);
  return (
    <div>
      <Header 
        setIsLoggedOut={setIsLoggedOut}
        userType={userType}
      />
      <Sidebar />
      <Box className={mainClass} style={{position: "absolute", zIndex: "1"}}>
        <Routes>
          <Route path="/" element={<Navigate to="home"/>} />
          <Route path="/home/*" element={<HomePage userType={userType}/>} />
          <Route path="/document/*" element={<DocumentPage />} />
          <Route path="/accounting/*" element={<AccountingPage userType={userType}/>} />
          <Route path="/records/*" element={<MainRecordsPage userType={userType} />} />
          <Route path="/invoice/*" element={<InvoicePage userType={userType} />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Routes>
      </Box>
    </div>
  );
}

export default ClinicHomePage;
