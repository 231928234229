  import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useEffect, useReducer, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { produce } from "immer";
import ExpenseModal from "../../../components/clinic/ExpenseModal";
import {
  CHANGE_NAME,
  PUSH_DATA,
  CLEAR_DATA,
  EDIT_DATA,
  SET_EDIT,
  SET_ID,
  SET_ROW,
  CHANGE_EXPENSE,
  CHANGE_QUANTITY,
  CHANGE_CPU,
  CHANGE_TOTAL,
  DELETE_DATA,
  PULLED_DATA,
  GET_TOKEN,
  TOKEN_NAME,
  SET_RELOAD,
  CHANGE_MID,
} from "../../../components/clinic/Constant";
import DeleteModal from "../../../components/clinic/DeleteModal";
import globalVariables from "../../../globalVariables";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

const SET_MODAL_ON = "set-modal-on";
const SET_MODAL_OFF = "set-modal-off";
const SET_EXTT_MODAL_ON = "set-exit-modal-on";
const SET_EXTT_MODAL_OFF = "set-exit-modal-off";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_MODAL_ON:
      state.showModal = true;
      return;

    case SET_MODAL_OFF:
      state.showModal = false;
      return;

    case SET_EXTT_MODAL_ON:
      state.showExitModal = true;
      return;

    case SET_EXTT_MODAL_OFF:
      state.showExitModal = false;
      return;

    case CHANGE_NAME:
      state.item = action.payload;
      return;

    case SET_ROW:
      state.rows.push(action.payload);
      return;

    case CHANGE_EXPENSE:
      state.expenseType = action.payload;
      return;

    case CHANGE_QUANTITY:
      state.quantity = action.payload;
      return;

    case CHANGE_CPU:
      state.baseCost = action.payload;
      return;

    case CHANGE_TOTAL:
      state.total = action.payload;
      return;

    case PUSH_DATA:
      const recievedToken = localStorage.getItem(TOKEN_NAME)
      if (state.token) {
        if (
          action.payload.item == null ||
          action.payload.basecost == null ||
          action.payload.expense == null
        ) {
          state.setError = true;
          return;
        }
        try {
          fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=addexpense`, {
            method: "POST",
            headers: {
              token: recievedToken,
              "ngrok-skip-browser-warning": true, 
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              item: action.payload.item,
              quantity: action.payload.quantity,
              basecost: action.payload.basecost,
              expense: action.payload.expense,
              expensetype: action.payload.expensetype,
            }),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then(() => {
              console.log("Added");
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (error) {
          console.error("Error at: ", error);
        }

        state.showModal = false;
        state.reload = true
      }
      return;

    case CLEAR_DATA:
      state.beingEdited.item = "";
      state.beingEdited.quantity = "";
      state.beingEdited.basecost = "";
      state.beingEdited.expense = "";
      state.beingEdited.expensetype = "";
      state.beingEdited.id = "";
      return;

    case SET_ID:
      state.id = action.payload;
      return;

    case GET_TOKEN:
    state.token = action.payload;
    return;

    case SET_EDIT:
      state.editMode = !state.editMode;
      return;
    
    case SET_RELOAD:
    state.reload = action.payload;
    return;

    case CHANGE_MID:
      state.beingEdited = action.payload;
      return;

    case EDIT_DATA:
      try {
        const recievedToken = localStorage.getItem(TOKEN_NAME)
        fetch(
          `${globalVariables.WEB_ADDRESS_PORT}/?type=updateexpense`,
          {
            method: "PATCH",
            headers: {
              token: recievedToken,
              "ngrok-skip-browser-warning": true, 
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              item: action.payload.item,
              quantity: action.payload.quantity,
              basecost: action.payload.basecost,
              expense: action.payload.expense,
              expensetype: action.payload.expensetype,
              id: action.payload.id,
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then(() => {
            console.log("Updated");
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error at: ", error);
      }

      state.showModal = false;
      state.reload = true
      return;

    case DELETE_DATA:
      if (state.token) {
        const recievedToken = localStorage.getItem(TOKEN_NAME)
        try {
          fetch(
            `${globalVariables.WEB_ADDRESS_PORT}/?type=removeexpense&id=${state.id}`,
            {
              method: "DELETE",
              headers: { "ngrok-skip-browser-warning": true, token: recievedToken },
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then(() => {
              console.log("Deleted");
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (error) {
          console.error("Error at: ", error);
        }

        state.showModal = false;
        state.reload = true
      }
      return;

    case PULLED_DATA:
      state.rows = action.payload;
      return;

    default:
      throw new Error(
        "unexpected action type" + action.type + " At ExpensePage"
      );
  }
};

function ExpensePage({ userType }) {
  const [state, dispatch] = useReducer(produce(reducer), {
    showModal: false,
    showExitModal: false,
    rows: [],
    token: '',
    reload: false, 
    beingEdited: {
      expensetype: "",
      item: "",
      quantity: "",
      basecost: "",
      expense: "",
      id: "",
    },
  });
  const [loading, setLoading] = useState(true);
  const recievedToken = localStorage.getItem(TOKEN_NAME)

  const isPhone = useMediaQuery({ query: "(max-width: 768px)" });
  const textClass = classNames("font-bold", isPhone ? "text-l" : "text-xl");

  const tableStyles = {
    minWidth: isPhone ? '100%' : 650,
    '& .MuiTableCell-root': {
      padding: isPhone ? '8px' : 'default',
      fontSize: isPhone ? '12px' : 'default', // Adjust font size for smaller screens
    },
  };


  function reloadData() {
    const recievedToken = localStorage.getItem(TOKEN_NAME)
    dispatch({ type: GET_TOKEN, payload: recievedToken});
    setLoading(true)
    if ((recievedToken && userType === "clinic") || state.reload === true) {
      try {
        fetch(`${globalVariables.WEB_ADDRESS_PORT}/?type=getexpense`, {
          method: "GET",
          headers: { token: recievedToken, "ngrok-skip-browser-warning": true, "Content-Type": "application/json" },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            dispatch({ type: PULLED_DATA, payload: data });
            setLoading(false);
            dispatch({ type: SET_RELOAD, payload: false });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error at: ", error);
      }
    }
  }

  useEffect(() => {
    reloadData()
  }, [userType, state.reload]);

  function handleEdit(object) {
    dispatch({ type: SET_EDIT });
    dispatch({ type: CHANGE_MID, payload: object });
    dispatch({ type: SET_MODAL_ON });
  }

  const setModal = () => {
    dispatch({ type: SET_MODAL_ON });
  };

  function handleExitModal(object) {
    dispatch({ type: SET_EXTT_MODAL_ON });
    dispatch({ type: SET_ID, payload: object });
  }

  const closeExitModal = () => {
    dispatch({ type: SET_EXTT_MODAL_OFF });
  };

  const closeModal = () => {
    dispatch({ type: SET_MODAL_OFF });
    if (state.editMode) {
      dispatch({ type: SET_EDIT });
    }
    dispatch({ type: CLEAR_DATA });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <Button id={"add-expense"} color="secondary" onClick={setModal}>
          Add
        </Button>
        <ExpenseModal
          open={state.showModal}
          onClose={closeModal}
          state={state.beingEdited}
          dispatch={dispatch}
          editMode={state.editMode}
          setError={state.setError}
        />
        <DeleteModal
          open={state.showExitModal}
          onClose={closeExitModal}
          dispatch={dispatch}
          id={state.id}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={tableStyles} aria-label="simple table">
          <TableHead>
            <TableRow className="bg-[#ac7de8]">
              <TableCell>
                <p className={textClass}>Type of expense</p>
              </TableCell>
              <TableCell align="right">
                <p className={textClass}>Name</p>
              </TableCell>
              <TableCell align="right">
                <p className={textClass}>Quantity</p>
              </TableCell>
              <TableCell align="right">
                <p className={textClass}>Cost per unit</p>
              </TableCell>
              <TableCell align="right">
                <p className={textClass}>Expense</p>
              </TableCell>
              <TableCell align="right"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.rows.map((row) => (
              <TableRow
                key={`key-${row.id}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.expensetype && row.expensetype}
                </TableCell>
                <TableCell align="right">{row.item && row.item}</TableCell>
                <TableCell align="right">
                  {row.quantity && row.quantity}
                </TableCell>
                <TableCell align="right"> {row.basecost} </TableCell>
                <TableCell align="right">
                  {row.expense && row.expense}
                </TableCell>
                <TableCell align="left">
                  <div className="flex">
                    <Button onClick={() => handleEdit(row)}> Edit </Button>
                    <MdOutlineDeleteOutline
                      className={
                        "hover:cursor-pointer hover:bg-gray-300 w-7 h-7 ml-2"
                      }
                      onClick={() => handleExitModal(row.id)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ExpensePage;
