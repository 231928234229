import React, { useCallback, useEffect, useState } from "react";
import './MedicalHistory.css'

import { Dropdown, Textfield, MedicalHistoryItem, ColoredButton } from "../../../components";
import { QuestionModal } from "../../../pages";

import Modal from "react-modal";

import GlobalVariables from "../../../globalVariables";

const MedicalHistory = ({ onSubmit }) => {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const questionSixOptionsArray = [
        "High Blood Pressure",
        "Low Blood Pressure",
        "Epilepsy/Convulsions",
        "AIDS/HIV infections",
        "Sexually Transmitted Disease",
        "Stomach Troubles/Ulcers",
        "Fainting Spells",
        "Rapid Weight Loss",
        "Radiation Therapy",
        "Joint Replacement/Therapy",
        "Heart Surgery",
        "Heart Attack",
        "Heart Disease",
        "Heart Murmur",
        "Hepatitis/Liver Disease",
        "Rheumatic Fever/Heart Disease", 
        "Hay Fever/Allergies",
        "Hepatitis/Jaundice",
        "Tuberculosis",
        "Swollen Ankles",
        "Kidney Disease",
        "Diabetes",
        "Chest Pain",
        "Stroke",
        "Cancer/Tumors",
        "Anemia",
        "Angina",
        "Asthma",
        "Emphysema",
        "Bleeding Problems",
        "Head Injuries",
        "Arthritis/Rhuematism",
        "Thyroid Problem"
    ];

    const questionTenOptionsArray = [
        "Antibiotics/Sulfa Drugs",
        "Tranquilizers",
        "Aspirin",
        "Nitroglycerin",
        "Antihistamine",
        "Oral Contraceptives",
        "Anticoagulants (blood thinners)",
        "Antihypertensive Drugs",
        "Cortisone (Steroids, Prednisone, etc.)",
        "Insulin, Tolbutamide, or similar drugs",
        "Digitalis or drugs for Heart Trouble",
        "Hormone Replacement Therapy"
    ];

    const questionElevenOptionsArray = [
        "Local Anasthesia",
        "Iodine",
        "Penicilin",
        "Sulfa Drugs",
        "Aspirin"
    ];

    const dentalHistoryQuestionThreeOptionsArray = [
        "local anesthesia",
        "latex gloves",
        "flouride"
    ];

    const [questionSixOptionsIsOpen, setQuestionSixOptionsIsOpen] = useState(false);
    const [questionSixOptionsValue, setQuestionSixOptionsValue] = useState([]);

    const [questionTenOptionsIsOpen, setQuestionTenOptionsIsOpen] = useState(false);
    const [questionTenOptionsValue, setQuestionTenOptionsValue] = useState([]);

    const [questionElevenOptionsIsOpen, setQuestionElevenOptionsIsOpen] = useState(false);
    const [questionElevenOptionsValue, setQuestionElevenOptionsValue] = useState([]);

    const [dentalHistoryQuestionThreeOptionsIsOpen, setDentalHistoryQuestionThreeOptionsIsOpen] = useState(false);
    const [dentalHistoryQuestionThreeOptionsValue, setDentalHistoryQuestionThreeOptionsValue] = useState([]);

    const [isYes] = useState(Array.from({ length : 26 }));
    const [questionValue] = useState(Array.from({ length : 26 }).map(() => Array.from({ length : 9 })))

    const [patientID, setPatientID] = useState('');
    const [patientInformation, setPatientInformation] = useState({});

    const getPatientID = useCallback(async () => {
        let patient = await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`, {
                method: 'GET',
                headers: { 
                    token : localStorage.token,
                    "ngrok-skip-browser-warning": true, 
                    'Content-Type' : 'application/json'
                }
            }
        )
        if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
        patient = await patient.json();
        setPatientID(patient.user)
    }, []);

    const getPatientInformation = useCallback(async () => {
        let patient = await fetch(
            `${GlobalVariables.WEB_ADDRESS_PORT}/?type=information&id=${patientID}`, {
                method: 'GET',
                headers: { 
                    token : localStorage.token,
                    "ngrok-skip-browser-warning": true, 
                    'Content-Type' : 'application/json'
                }
            }
        )
        if(!patient.ok) return console.log({ "message" : "error", "error" : patient });
        patient = await patient.json();
        setPatientInformation(patient[0]);
        console.log(patient[0]);
    }, [patientID]);

    const submit = async () => {
        const medicalHistory = {
            medicalHistoryQuestions: {
                question_1: {
                    month: questionValue[0][0],
                    year: questionValue[0][1]
                },
                question_2: {
                    doctor: questionValue[1][0],
                    clinic_address: questionValue[1][1]
                },
                question_3: {
                    isYes: isYes[3],
                    field: questionValue[2][0]
                },
                question_4: {
                    isYes: isYes[4],
                    field: questionValue[3][0]
                },
                question_5: {
                    isYes: isYes[5],
                    field: questionValue[4][0]
                },
                question_6: {
                    isYes: isYes[6],
                    field: questionSixOptionsValue
                },
                question_7:{
                    field: questionValue[6][0]
                },
                question_8: {
                    isYes: isYes[8]
                },
                question_9: {
                    isYes: isYes[9],
                    field: questionValue[8][0]                 
                },
                question_10: {
                    isYes: isYes[10],
                    field: questionTenOptionsValue
                },
                question_11: {
                    isYes: isYes[11],
                    field: questionElevenOptionsValue
                },
                question_12: {
                    isYes: isYes[12],
                    field: questionValue[11][0]
                },
                question_13: {
                    isYes: isYes[13],
                    field: questionValue[12][0]
                },
                question_14: {
                    isYes: isYes[14],
                    field: questionValue[13][0]
                },
                question_15: {
                    isYes: isYes[15],
                    field: questionValue[14][0]
                },
                question_16: {
                    isYes: isYes[16],
                    field: questionValue[15][0]
                },
                question_17: {
                    field: questionValue[16][0]
                },
                question_18: {
                    isYes: isYes[18]
                },
                question_19: {
                    isYes: isYes[19]
                },
            },
            dentalHistoryQuestions: {
                question_1: {
                    field: questionValue[19][0]
                },
                question_2: {
                    procedure: questionValue[20][0],
                    date_of_procedure: questionValue[20][1]
                },
                question_3: {
                    isYes: isYes[22],
                    field: dentalHistoryQuestionThreeOptionsValue
                },
                question_4: {
                    isYes: isYes[23]
                },
                question_5: {
                    isYes: isYes[24],
                    field: questionValue[23][0]
                },
                question_6: {
                    isYes: isYes[25],
                    field: questionValue[24][0]
                },
                question_7: {
                    coffee: questionValue[25][0],
                    tea: questionValue[25][1],
                    cola: questionValue[25][2],
                    red_wine: questionValue[25][3],
                    tomato_based_foodDrink: questionValue[25][4],
                    colored_juices: questionValue[25][5],
                    chew_on_ice_cubes: questionValue[25][6],
                    others_field: questionValue[25][7],
                    others: questionValue[25][8],
                },
            }
        };

        // post medical history to database 
        let request;
        try {
            request = await fetch(
                `${GlobalVariables.WEB_ADDRESS_PORT}/?type=medicalhistory&patientid=${patientID}`, {
                    method: 'POST',
                    body: JSON.stringify(medicalHistory),
                    headers: { 
                        token : localStorage.token,
                        "ngrok-skip-browser-warning": true, 
                        'Content-Type' : 'application/json'
                    }
                }
            )
        } catch (error) {
            console.log("...");
        }
        if(!request.ok) return console.log({ "message" : "error", "error" : request });

        onSubmit((prev) => !prev);
    };

    useEffect(() => {
        getPatientID();
        if(patientID) {
            getPatientInformation();
        }
    }, [getPatientID, patientID, getPatientInformation]);

    return (
        <div className="medical-history">
            <div className="medical-history-container">
                <div className="medical-history-container-header">
                    <div className="medical-history-container-header-text">Medical History</div>
                    <div className="medical-history-container-header-subtext">Please provide your medical history. Understanding your medical history is essential for providing you with the best possible care. Clinics need this information to ensure accurate diagnoses, effective treatment plans, and to avoid potential complications or adverse reactions to medications.</div>
                </div>
                <Modal
                    className="question-options-modal"
                    isOpen={questionSixOptionsIsOpen}
                >
                    <QuestionModal 
                        setIsOpen={setQuestionSixOptionsIsOpen} 
                        getValue={setQuestionSixOptionsValue}
                        setValue={questionSixOptionsValue && questionSixOptionsValue}
                        options={questionSixOptionsArray}
                        optionsWithFields={["Others"]}
                    />
                </Modal>
                <Modal
                    className="question-options-modal"
                    isOpen={questionElevenOptionsIsOpen}
                >
                    <QuestionModal 
                        setIsOpen={setQuestionElevenOptionsIsOpen} 
                        getValue={setQuestionElevenOptionsValue}
                        setValue={questionElevenOptionsValue && questionElevenOptionsValue}
                        options={questionElevenOptionsArray}
                        optionsWithFields={["Others Drugs", "Other Antibiotics (please indicate)", "Other Analgesics (please indicate)"]}
                    />
                </Modal>
                <Modal
                    className="question-options-modal"
                    isOpen={questionTenOptionsIsOpen}
                >
                    <QuestionModal 
                        setIsOpen={setQuestionTenOptionsIsOpen} 
                        getValue={setQuestionTenOptionsValue}
                        setValue={questionTenOptionsValue && questionTenOptionsValue}
                        options={questionTenOptionsArray}
                        optionsWithFields={["Others maintenance drugs"]}
                    />
                </Modal>
                <Modal
                    className="question-options-modal"
                    isOpen={dentalHistoryQuestionThreeOptionsIsOpen}
                >
                    <QuestionModal 
                        setIsOpen={setDentalHistoryQuestionThreeOptionsIsOpen} 
                        getValue={setDentalHistoryQuestionThreeOptionsValue}
                        setValue={dentalHistoryQuestionThreeOptionsValue && dentalHistoryQuestionThreeOptionsValue}
                        options={dentalHistoryQuestionThreeOptionsArray}
                        optionsWithFields={["others"]}
                    />
                </Modal>
                <div className="medical-history-container-body">
                    <MedicalHistoryItem
                        className=""
                        question="1. My last medical/physical examination was on (month & year):"
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Dropdown
                                    items={months}
                                    title="Month *"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[0][0] = item;
                                    }}
                                />
                                <Textfield
                                    title="Year *"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[0][1] = item;
                                    }}
                                />
                            </div>
                        }
                    />
                    <MedicalHistoryItem
                        question="2. The name and address of my personal physician is:"
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="Doctor"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[1][0] = item;
                                    }}
                                />
                                <Textfield
                                    title="Doctor's Address / Clinic Address"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[1][1] = item;
                                    }}
                                />
                            </div>
                        }
                    />
                    <MedicalHistoryItem
                        question="3. Are you now under the care of any physician?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, what is the condition being treated?"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[2][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[3] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="4. Have you ever been hospitalized?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, when and why?"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[3][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[4] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="5. Have you had any serious illness or operation?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please indicate"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[4][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[5] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="6. Do you have or have had any diseases or problems?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields" onClick={() => {setQuestionSixOptionsIsOpen(true)}}>
                                <Textfield
                                    title="Choose which apply"
                                    style={{
                                        height: "10px"
                                    }}
                                    setValue={questionSixOptionsValue && questionSixOptionsValue.map((value) => {
                                        return ` ${value.value}`;
                                    })}
                                    getValue={(item) => {
                                        questionValue[5][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[6] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="7. What is your blood pressure?"
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="Blood pressure (mmHg)"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[6][0] = item;
                                    }}
                                />
                            </div>
                        }
                    />
                    <MedicalHistoryItem
                        question="8. Have you had abnormal bleeding associated with previous tooth extractions, surgery, or trauma?"
                        withYesOrNo={true}
                        isYes={(value) => {
                            isYes[8] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="9. Have you had surgery or radiation treatment for a tumor, cancer, or other conditions in your head and neck area?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please indicate"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[8][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[9] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="10. Are you taking any drug for maintenance?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields" onClick={() => {setQuestionTenOptionsIsOpen(true)}}>
                                <Textfield
                                    title="Choose which apply"
                                    style={{
                                        height: "10px"
                                    }}
                                    setValue={questionTenOptionsValue && questionTenOptionsValue.map((value) => {
                                        return ` ${value.value}`;
                                    })}
                                    getValue={(item) => {
                                        questionValue[9][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[10] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="11. Are you allergic or have reacted adversely to any drug, antibiotic, or analgesic?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields" onClick={() => {setQuestionElevenOptionsIsOpen(true)}}>
                                <Textfield
                                    title="Choose which apply"
                                    style={{
                                        height: "10px"
                                    }}
                                    setValue={questionElevenOptionsValue && questionElevenOptionsValue.map((value) => {
                                        return ` ${value.value}`;
                                    })}
                                    getValue={(item) => {
                                        questionValue[10][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[11] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="12. Do you have any food allergies?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please indicate"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[11][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[12] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="13. Have you had any serious trouble associated with any previous dental treatment?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please explain"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[12][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[13] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="14. Do you have any disease, condition, or problem not listed above that you think I should know about?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please indicate"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[13][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[14] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="15. Do you smoke?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please indicate amount and frequency"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[14][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[15] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="16. Do you drink alcoholic beverages?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, please indicate amount and frequency"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[15][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[16] = value;
                        }}
                    />
                    {
                        patientInformation.patientgender === "Female" ? (
                            <div>
                                <MedicalHistoryItem
                                    question="17. Are you pregnant?"
                                    fields={
                                        <div className="medical-history-container-question-fields">
                                            <Textfield
                                                title="If so, please indicate how many months"
                                                style={{
                                                    height: "10px"
                                                }}
                                                getValue={(item) => {
                                                    questionValue[16][0] = item;
                                                }}
                                            />
                                        </div>
                                    }                        
                                />
                                <MedicalHistoryItem
                                    question="18. Have you missed your last menstrual period? or Do you suspect you are pregnant?"
                                    withYesOrNo={true}
                                    isYes={(value) => {
                                        isYes[18] = value;
                                    }}
                                />
                                <MedicalHistoryItem
                                    question="19. Are you presently breastfeeding?"
                                    withYesOrNo={true}
                                    isYes={(value) => {
                                        isYes[19] = value;
                                    }}
                                />
                            </div>
                        ) : ''
                    }
                    <div className="medical-history-container-header-text">Dental History</div>
                    <MedicalHistoryItem
                        question="1. Chief Dental Complaint?"
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="Why did you come to the clinic today?"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[19][0] = item;
                                    }}
                                />
                            </div>
                        }
                    />
                    <MedicalHistoryItem
                        question="2. When was your last dental visit?"
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="What was the procedure done?"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[20][0] = item;
                                    }}
                                />
                                <Textfield
                                    title="When was your last oral prophylaxis (cleaning)?"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[20][1] = item;
                                    }}
                                />
                            </div>
                        }
                    />
                    <MedicalHistoryItem
                        question="3. Are you allergic to any dental materials?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields" onClick={() => {setDentalHistoryQuestionThreeOptionsIsOpen(true)}}>
                                <Textfield
                                    title="Choose which apply"
                                    style={{
                                        height: "10px"
                                    }}
                                    setValue={dentalHistoryQuestionThreeOptionsValue && dentalHistoryQuestionThreeOptionsValue.map((value) => {
                                        return ` ${value.value}`;
                                    })}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[22] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="4. Has anyone ever informed you that you grind your teeth at night?"
                        withYesOrNo={true}
                        isYes={(value) => {
                            isYes[23] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="5. Do you grind or clench your teeth at anytime of the day while awake?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="If so, when?"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[23][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[24] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="6. Are you engaged in any type of contact sports?"
                        withYesOrNo={true}
                        fields={
                            <div className="medical-history-container-question-fields">
                                <Textfield
                                    title="Please indicate"
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[24][0] = item;
                                    }}
                                />
                            </div>
                        }
                        isYes={(value) => {
                            isYes[25] = value;
                        }}
                    />
                    <MedicalHistoryItem
                        question="7. Please choose how often you drink or eat any of the following?"
                        fields={
                            <div className="dental-history-question-seven-table">
                                <div className="dental-history-question-seven-item-name">coffee</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][0] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">tea</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][1] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">cola</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][2] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">red wine</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][3] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">tomato based food/drink</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][4] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">colored juices</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][5] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">chew on ice cubes</div>
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][6] = item;
                                    }}
                                />
                                <div className="dental-history-question-seven-item-name">others</div>
                                <div/>
                                <Textfield
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][7] = item;
                                    }}
                                />
                                <Dropdown
                                    items={["Always", "Seldom", "Never"]}
                                    style={{
                                        height: "10px"
                                    }}
                                    getValue={(item) => {
                                        questionValue[25][8] = item;
                                    }}
                                />
                            </div>
                        }
                    />
                </div>
                <div className="medical-history-container-footer">
                    <ColoredButton
                        text="Submit"
                        color="#ffffff"
                        backgroundColor="#5b346b"
                        backgroundColorOnHover="#6d3f80"
                        onClick={submit}
                    />
                </div>
            </div>
        </div>
    );
};

export default MedicalHistory;