import { Button, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import "./AccountPage.css";

import GlobalVariables from "../../../globalVariables";
import dayjs from "dayjs";

import { LuFileEdit, LuSave } from "react-icons/lu";
import { ColoredButton, Textfield } from "../../../components";

const AccountPage = () => {
  const [patientInformation, setPatientInformation] = useState("");
  const [render, setRender] = useState(false);

  const [addressIsEdit, setAddressIsEdit] = useState(false);
  const [address, setAddress] = useState("");

  const [mobileNumberIsEdit, setMobileNumberIsEdit] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const [telephoneNumberIsEdit, setTelephoneNumberIsEdit] = useState(false);
  const [telephoneNumber, setTelephoneNumber] = useState("");

  const [emailAddressIsEdit, setEmailAddressIsEdit] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitImage, setSubmitImage] = useState(false)

  const getPatientInformation = useCallback(async () => {
    // get user
    let patient;
    try {
      patient = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/auth/verification`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    if (!patient.ok) return console.log({ message: "error", error: patient });
    patient = await patient.json();
    const patientID = patient.user;

    // get patient information
    let patientInformation;
    try {
      patientInformation = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=information&id=${patientID}`,
        {
          method: "GET",
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log("...");
    }
    patientInformation = await patientInformation.json();
    patientInformation = patientInformation[0];
    setPatientInformation(patientInformation);
  }, []);

  const saveAddressEdit = async () => {
    setRender(false);
    let request;
    try {
      request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateinformation&id=${patientInformation.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({ patientaddress: `${address}` }),
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
    if (!request.ok) return console.log(request);
    getPatientInformation();
    setRender(true);
    return setAddressIsEdit(false);
  };

  const saveMobileNumberEdit = async () => {
    setRender(false);
    let request;
    try {
      request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateinformation&id=${patientInformation.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({ numbermobile: `${mobileNumber}` }),
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
    if (!request.ok) return console.log(request);
    getPatientInformation();
    setRender(true);
    return setMobileNumberIsEdit(false);
  };

  const saveTelephoneNumberEdit = async () => {
    setRender(false);
    let request;
    try {
      request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateinformation&id=${patientInformation.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({ numbertelephone: `${telephoneNumber}` }),
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
    if (!request.ok) return console.log(request);
    getPatientInformation();
    setRender(true);
    return setTelephoneNumberIsEdit(false);
  };

  const saveEmailAddressEdit = async () => {
    setRender(false);
    let request;
    try {
      request = await fetch(
        `${GlobalVariables.WEB_ADDRESS_PORT}/?type=updateinformation&id=${patientInformation.id}`,
        {
          method: "PATCH",
          body: JSON.stringify({ email: `${emailAddress}` }),
          headers: {
            token: localStorage.token,
            "ngrok-skip-browser-warning": true,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
    if (!request.ok) return console.log(request);
    getPatientInformation();
    setRender(true);
    return setEmailAddressIsEdit(false);
  };

  const handleFileChange = (event) => {
    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      setSelectedFile(reader.result);
    };
  };

  function uploadImage() {
    setSubmitImage(false)
    fetch(
      `${GlobalVariables.WEB_ADDRESS_PORT}/?type=profilepicture&id=${patientInformation.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({ profilepicture: `${selectedFile}` }),
        headers: {
          token: localStorage.token,
          "ngrok-skip-browser-warning": true,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSubmitImage(true)
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  useEffect(() => {
    getPatientInformation();
    setRender(true);
  }, [getPatientInformation]);

  if (render)
    return (
      <div className="account-container">
        <div className="account-header">
          <div className="account-header-text">Account Information</div>
        </div>
        <div className="account-box-container">
          <div className="patient-information">
            <div className="patient-information-title">Name</div>
            <div className="patient-information-value">
              {`${patientInformation.namefirst} ${patientInformation.namemiddle} ${patientInformation.namelast}`}
            </div>
          </div>
          <div className="patient-information">
            <div className="patient-information-title">Birthdate</div>
            <div className="patient-information-value">
              {`${dayjs(patientInformation.dateofbirth).format(
                "MMMM DD, YYYY"
              )}`}
            </div>
          </div>
          <div className="patient-information">
            <div className="patient-information-title-box">
              <div className="patient-information-title">Address</div>
              {!addressIsEdit ? (
                <Tooltip title="Edit Address" placement="right">
                  <div
                    className="patient-information-edit-button"
                    onClick={() => {
                      setAddressIsEdit(true);
                      setAddress(patientInformation.patientaddress);
                    }}
                  >
                    <LuFileEdit />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Save Edit" placement="right">
                  <div
                    className="patient-information-save-button"
                    onClick={saveAddressEdit}
                  >
                    <LuSave />
                  </div>
                </Tooltip>
              )}
            </div>
            {!addressIsEdit ? (
              <div className="patient-information-value">
                {patientInformation.patientaddress}
              </div>
            ) : (
              <Textfield
                style={{
                  height: "10px",
                }}
                setValue={address}
                getValue={setAddress}
              />
            )}
          </div>
          <div className="patient-information">
            <div className="patient-information-title-box">
              <div className="patient-information-title">Mobile Number</div>
              {!mobileNumberIsEdit ? (
                <Tooltip title="Edit Mobile Number" placement="right">
                  <div
                    className="patient-information-edit-button"
                    onClick={() => {
                      setMobileNumberIsEdit(true);
                      setMobileNumber(patientInformation.numbermobile);
                    }}
                  >
                    <LuFileEdit />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Save Edit" placement="right">
                  <div
                    className="patient-information-save-button"
                    onClick={saveMobileNumberEdit}
                  >
                    <LuSave />
                  </div>
                </Tooltip>
              )}
            </div>
            {!mobileNumberIsEdit ? (
              <div className="patient-information-value">
                {`${
                  patientInformation.numbermobile
                    ? patientInformation.numbermobile
                    : "N/A"
                }`}
              </div>
            ) : (
              <Textfield
                style={{
                  height: "10px",
                }}
                setValue={mobileNumber}
                getValue={setMobileNumber}
                minLength={11}
                numberOnly={true}
              />
            )}
          </div>
          <div className="patient-information">
            <div className="patient-information-title-box">
              <div className="patient-information-title">Telephone Number</div>
              {!telephoneNumberIsEdit ? (
                <Tooltip title="Edit Telephone Number" placement="right">
                  <div
                    className="patient-information-edit-button"
                    onClick={() => {
                      setTelephoneNumberIsEdit(true);
                      setTelephoneNumber(patientInformation.numbertelephone);
                    }}
                  >
                    <LuFileEdit />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Save Edit" placement="right">
                  <div
                    className="patient-information-save-button"
                    onClick={saveTelephoneNumberEdit}
                  >
                    <LuSave />
                  </div>
                </Tooltip>
              )}
            </div>
            {!telephoneNumberIsEdit ? (
              <div className="patient-information-value">
                {`${
                  patientInformation.numbertelephone &&
                  String(patientInformation.numbertelephone) != "undefined"
                    ? patientInformation.numbertelephone
                    : "N/A"
                }`}
              </div>
            ) : (
              <Textfield
                style={{
                  height: "10px",
                }}
                setValue={telephoneNumber}
                getValue={setTelephoneNumber}
                minLength={11}
                numberOnly={true}
              />
            )}
          </div>
          <div className="patient-information">
            <div className="patient-information-title-box">
              <div className="patient-information-title">Email Address</div>
              {!emailAddressIsEdit ? (
                <Tooltip title="Edit Email Address" placement="right">
                  <div
                    className="patient-information-edit-button"
                    onClick={() => {
                      setEmailAddressIsEdit(true);
                      setEmailAddress(patientInformation.email);
                    }}
                  >
                    <LuFileEdit />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Save Edit" placement="right">
                  <div
                    className="patient-information-save-button"
                    onClick={saveEmailAddressEdit}
                  >
                    <LuSave />
                  </div>
                </Tooltip>
              )}
            </div>
            {!emailAddressIsEdit ? (
              <div className="patient-information-value">
                {`${patientInformation.email}`}
              </div>
            ) : (
              <Textfield
                style={{
                  height: "10px",
                }}
                setValue={emailAddress}
                getValue={setEmailAddress}
              />
            )}

            <input
              className="mt-5"
              type="file"
              name="file"
              accept="image/*"
              onChange={handleFileChange}
              id="file-upload"
              style={{ display: "none" }}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                sx={{ marginTop: "15px" }}
              >
                Upload Profile Photo
              </Button>
              {selectedFile === null ? "" : <div> File uploaded</div>}
            </label>
            <div>
            <Button
                variant="contained"
                component="span"
                sx={{ marginTop: "15px",  marginBottom: "15px" }}
                onClick={uploadImage}
              >
                Submit Photo
              </Button>
              {submitImage === false ? "" : <div> Profile Changed</div>}
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="account-container">
        <div className="account-header">
          <div className="account-header-text">Account Information</div>
        </div>
        <div className="account-box-container">Loading...</div>
      </div>
    );
};

export default AccountPage;
