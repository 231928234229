import { Route, Routes } from "react-router-dom";
import Consent from "./sub-pages/Consent";
import RTXPage from "./sub-pages/RtxPage";
import Certificates from "./sub-pages/Certificates";
import BillsPage from "./sub-pages/BillsPage";
import ClinicHomePage from "./ClinicHomePage";
import WorkAuthorizationPage from "./sub-pages/WorkAuthPage";
import DosageCalculator from "./sub-pages/PedoDosageCalc";

function DocumentPage() {
  return (
    <div>
      <Routes>
        <Route path="/certificate" element={<Certificates />} />
        <Route path="/workauthorization" element={<WorkAuthorizationPage />} />
        <Route path="/pediatricdosagecalculator" element={<DosageCalculator/>} />
        <Route path="/rx" element={<RTXPage />} />
        <Route path="/consent" element={<Consent />} />
        <Route path="/billsselect" element={<BillsPage />} />
        <Route path="/expenseselect" element={<ClinicHomePage />} />
      </Routes>
    </div>
  );
}

export default DocumentPage;
